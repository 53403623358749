import React from 'react'
import {t} from '../services/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import embed_button_img_en from '../img/embed-button-en.jpg'
import embed_button_img_fr from '../img/embed-button-fr.jpg'
import embed_button_img_es from '../img/embed-button-es.jpg'

export default () => (
  <section id="links-section">
    <div className="container">
      <h2 className="col-12 section-title text-center mt-5">{t('section_embed_button')}</h2>
      <hr></hr>

      <div className="container-fluid">
        <div className="row">
          <div className="row-item">
            <img src={embed_button_img_en} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={embed_button_img_en}>{ t('downloadJPEG') }</a>
            </div>
            <pred>
                &lt;a href=&quot;http://marketanalysis.intracen.org/en&quot; target=&quot;_blank&quot;&gt;&lt;img src=&quot;http://marketanalysis.intracen.org/en/static/media/embed-button-en.3fae765f.jpg&quot; style=&quot;margin-top:10px; margin-bottom:10px;&quot; /&gt;&lt;/a&gt;
            </pred>
          </div>

          <div className="row-item">
            <img src={embed_button_img_fr} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={embed_button_img_fr}>{ t('downloadJPEG') }</a>
            </div>
            <pred>
                &lt;a href=&quot;http://marketanalysis.intracen.org/fr&quot; target=&quot;_blank&quot;&gt;&lt;img src=&quot;http://marketanalysis.intracen.org/en/static/media/embed-button-fr.9a85f526.jpg&quot; style=&quot;margin-top:10px; margin-bottom:10px;&quot; /&gt;&lt;/a&gt;
            </pred>
          </div>

          <div className="row-item">
            <img src={embed_button_img_es} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={embed_button_img_es}>{ t('downloadJPEG') }</a>
            </div>
            <pred>
                &lt;a href=&quot;http://marketanalysis.intracen.org/es&quot; target=&quot;_blank&quot;&gt;&lt;img src=&quot;http://marketanalysis.intracen.org/en/static/media/embed-button-es.14b93dbe.jpg&quot; style=&quot;margin-top:10px; margin-bottom:10px;&quot; /&gt;&lt;/a&gt;
            </pred>
          </div>


        </div>
        <div className="row">
          <div className="col-sm-12 ">
            <div className="embed_lst">
              <p><u>{t('section_how_to')}</u></p>
              <ul>
                <li>1.	{t('section_how_to_step_1')} <LocalizedLink to="/home" className="nav-link">{t('lbl_mat')}</LocalizedLink>.</li>
                <li>2.	{t('section_how_to_step_2')}</li>
                <li>3.	{t('section_how_to_step_3')}</li>
                <li>4.	{t('section_how_to_step_4')} <LocalizedLink to="/home" className="nav-link">{t('lbl_mat')}</LocalizedLink>, {t('section_how_to_step_4_1')} <a className='nav-link' href='mailto:marketanalysis@intracen.org'>marketanalysis@intracen.org</a>. {t('section_how_to_step_4_2')}</li>

              </ul>
              
            </div>
          </div>

        </div>
      </div>


    </div>

  </section>

)