import { t } from '../services/i18n'

const INITIAL_STATE = {
    countries: []
}

const countries = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'GETALL_COUNTRIES': 
            return {
                ...state,
                countries: [...action.countries.map( function(country) {
                    
                        var ctr = { //"value": country.ISO2,
                                    "value": country.code,
                                     "label": country.name,
                                     "code": country.isO2
                                    }
                        return ctr;                    
                   })]
            }  
        case 'GETALL_COUNTRIES_ALL': 
            return {
                ...state,
                countriesAll: [...[{ "value": "ALL","label": t('lbl_all_countries') ,"code": "ALL"}],...action.countries.map( function(country) {
                    
                        var ctr = { //"value": country.ISO2,
                                    "value": country.code,
                                     "label": country.name,
                                     "code": country.isO2
                                    }
                        return ctr;                    
                   })]
            }
        case 'GETALL_REGIONS':
            return {
                ...state,
                regions: action.regions
            };
        
        default:
            return state
    }
}

export default countries