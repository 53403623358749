import _ from 'lodash'

export function prefixPath (path, prefix) {
    return `/${prefix}/${_.trim(path, '/')}`
}

export function loadAsset(fileName, fileType) {
    let fileref = null

    if (fileType === "js") { 
        fileref = document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", fileName)
    }
    else if (fileType === "css") { 
        fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", fileName)
    }
    
    if (fileref) {
        document.getElementsByTagName("head")[0].appendChild(fileref)
    }
}

export function removeAsset(fileName, fileType){
    let element = null,
        attribute = null

    
    switch(fileType) {
        case "js":
            element = "script"
            attribute = "src"
            break
        
        case "css":
            element = "link"
            attribute = "href"
            break
        
        default: 
            element = "none"
            attribute = "none"

    }
    
    let suspects = document.getElementsByTagName(element)

    // we search backwards within nodelist for matching elements to remove
    for (let i = suspects.length; i>=0; i--) { 
        if (suspects[i] &&
            suspects[i].getAttribute(attribute) !== null &&
            suspects[i].getAttribute(attribute).indexOf(fileName) !== -1) {
            suspects[i].parentNode.removeChild(suspects[i])
        }
    }
}



export function formatMacMapData(dataArray) {
    var cate=[], maxv=[], minv=[];
    for (var i = 0; i < dataArray.length; i++) {
            cate.push(dataArray[i]['groupCode']);
    }
    
    for (var i = 0; i < dataArray.length; i++) {
        maxv.push(dataArray[i]['maxValue']);
    }
    for (var i = 0; i < dataArray.length; i++) {
            minv.push(dataArray[i]['minValue']);
    }        
    
    return [
        {
            name: "maxValue",
            data: maxv
        },
        {
            name: "minValue",
            data: minv
        }
    ]
    
}


export function formatTradeMapData(dataArray) {
    var cate=[], maxv=[], minv=[];
    for (var i = 0; i < dataArray.length; i++) {
            cate.push(dataArray[i]['groupCode']);
    }
    
    for (var i = 0; i < dataArray.length; i++) {
        maxv.push(dataArray[i]['maxValue']);
    }
    for (var i = 0; i < dataArray.length; i++) {
            minv.push(dataArray[i]['minValue']);
    }        

    return [{
        name:"maxValue",
        data:maxv
    },
    {
        name:"minValue",
        data:minv
    }];
}


export function getKeys(obj, prefix = '') {
	if (typeof obj === 'undefined' || obj === null) return [];
	return [
		...Object.keys(obj).map(key => `${prefix}${key}`),
		...Object.entries(obj).reduce((acc, [key, value]) => {
			if (typeof value === 'object') return [...acc, ...getKeys(value, `${prefix}${key}.`)];
			return acc;
		}, []),
	];
}
export function flatObject(obj, prefix = '') {
	if (typeof obj === 'undefined' || obj === null) return {};
	return Object.entries(obj).reduce((acc, [key, value]) => {
		if (typeof value === 'object') return { ...acc, ...flatObject(value, `${prefix}${key}.`) };
		return { ...acc, [`${prefix}${key}`]: value };
	}, {});
}

export function escapeCsvValue(cell) {
	if (cell.replace(/ /g, '').match(/[\s,"]/)) {
		return '"' + cell.replace(/"/g, '""') + '"';
	}
	return cell;
}

export function objectsToCsv(arrayOfObjects) {
	// collect all available keys
	const keys = new Set(arrayOfObjects.reduce((acc, item) => [...acc, ...getKeys(item)], []));
	// for each object create all keys
	const values = arrayOfObjects.map(item => {
		const fo = flatObject(item);
		const val = Array.from(keys).map((key: string) => (key in fo ? escapeCsvValue(`${fo[key]}`) : ''));
		return val.join(',');
	});
	return `${Array.from(keys).join(',')}\n${values.join('\n')}`;
}

export function ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function formatEpmData(dataArray) {
    var cate=[], maxv=[], minv=[];
    for (var i = 0; i < dataArray.length; i++) {
            cate.push(dataArray[i]['groupCode']);
    }
    
    for (var i = 0; i < dataArray.length; i++) {
        maxv.push(dataArray[i]['maxValue']);
    }
    for (var i = 0; i < dataArray.length; i++) {
            minv.push(dataArray[i]['minValue']);
    }        

    return [{
        name:"maxValue",
        data:maxv
    },
    {
        name:"minValue",
        data:minv
    }];
}

export function MoneyFormat(labelValue,n) 
{
// Nine Zeroes for Billions
return Math.abs(Number(labelValue)) >= 1.0e+9

     ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+9).toFixed(n) + "bn"
     // Six Zeroes for Millions 
     : Math.abs(Number(labelValue)) >= 1.0e+6

     ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+6).toFixed(n) + "mn"
     // Three Zeroes for Thousands
     : Math.abs(Number(labelValue)) >= 1.0e+3

     ?parseFloat(Math.abs(Number(labelValue)) / 1.0e+3).toFixed(n) + "K"

     : Math.abs(Number(labelValue));

 }

 export function FormatPercentage(num, digits)
 {
     return Number(num).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:digits});
 }

export const treemapGroups = [{ id: '1',name:'Live animals (except poultry)',color:'#E57373'},
{ id: '2',name:'Live animals (poultry)',color:'#F44336'},
{ id: '3',name:'Meat (except poultry)',color:'#D32F2F'},
{ id: '4',name:'Meat (poultry)',color:'#B71C1C'},
{ id: '5',name:'Processed meat',color:'#F44336'},
{ id: '6',name:'Animal fat',color:'#E57373'},
{ id: '7',name:'Dairy products',color:'#B71C1C'},
{ id: '8',name:'Other animal products (edible)',color:'#D32F2F'},
{ id: '9',name:'Animal products (not edible)',color:'#D32F2F'},
{ id: '10',name:'Fish & shellfish',color:'#2196F3'},
{ id: '11',name:'Fish products (processed)',color:'#0D47A1'},
{ id: '12',name:'Wheat',color:'#DCE775'},
{ id: '13',name:'Rice',color:'#AFB42B'},
{ id: '14',name:'Other cereals',color:'#CDDC39'},
{ id: '15',name:'Cereals (processed)',color:'#827717'},
{ id: '16',name:'Vegetables',color:'#4DB6AC'},
{ id: '17',name:'Fruits',color:'#004D40'},
{ id: '18',name:'Pulses',color:'#00796B'},
{ id: '19',name:'Nuts',color:'#81C784'},
{ id: '20',name:'Spices',color:'#388E3C'},
{ id: '21',name:'Crops nec',color:'#4CAF50'},
{ id: '22',name:'Flowers',color:'#006064'},
{ id: '23',name:'Oil seeds',color:'#1B5E20'},
{ id: '24',name:'Vegetable oils & fats',color:'#689F38'},
{ id: '25',name:'Seeds for sowing',color:'#AED581'},
{ id: '26',name:'Sugar',color:'#8BC34A'},
{ id: '27',name:'Cocoa beans & products',color:'#33691E'},
{ id: '28',name:'Other food products',color:'#FF8A65'},
{ id: '29',name:'Vegetal residues & animal feed',color:'#E64A19'},
{ id: '30',name:'Coffee',color:'#7986CB'},
{ id: '31',name:'Tea & mate',color:'#3F51B5'},
{ id: '32',name:'Beverages (not alcoholic)',color:'#303F9F'},
{ id: '33',name:'Beverages (alcoholic)',color:'#1A237E'},
{ id: '34',name:'Chemicals',color:'#BA68C8'},
{ id: '35',name:'Fertilizers',color:'#7B1FA2'},
{ id: '36',name:'Pharmaceutical components',color:'#9C27B0'},
{ id: '37',name:'Beauty products & perfumes',color:'#4A148C'},
{ id: '38',name:'Mineral products',color:'#4DD0E1'},
{ id: '39',name:'Ferrous metals',color:'#00BCD4'},
{ id: '40',name:'Other metals',color:'#009688'},
{ id: '41',name:'Metal products',color:'#00ACC1'},
{ id: '42',name:'Pearls & (semi-)precious stones',color:'blue'},
{ id: '43',name:'Precious metals',color:'gold'},
{ id: '44',name:'Jewellery & precious metal articles',color:'green'},
{ id: '45',name:'Wood & vegetable material',color:'#9E9E9E'},
{ id: '46',name:'Wood products',color:'#212121'},
{ id: '47',name:'Paper products',color:'#90A4AE'},
{ id: '48',name:'Natural latex & rubber',color:'#455A64'},
{ id: '49',name:'Plastics & rubber',color:'#607D8B'},
{ id: '50',name:'Skins, leather & products thereof',color:'#E0E0E0'},
{ id: '51',name:'Footwear',color:'#616161'},
{ id: '52',name:'Silk (fabric)',color:'#FFC107'},
{ id: '53',name:'Wool & animal hair (fabric)',color:'#FFA000'},
{ id: '54',name:'Cotton (fabric)',color:'#FFD54F'},
{ id: '55',name:'Other natural fabric (flax, hemp, etc.)',color:'#FF6F00'},
{ id: '56',name:'Synthetic textile fabric',color:'#FBC02D'},
{ id: '57',name:'Other textile fabric',color:'#F57F17'},
{ id: '58',name:'Apparel',color:'#F57C00'},
{ id: '59',name:'Carpets',color:'#FF9800'},
{ id: '60',name:'Home textiles',color:'#E65100'},
{ id: '61',name:'Other textile products',color:'#FFB74D'},
{ id: '62',name:'Machinery',color:'#795548'},
{ id: '63',name:'Electronic equipment',color:'#3E2723'},
{ id: '64',name:'Aircrafts, spacecrafts & parts',color:'#64B5F6'},
{ id: '65',name:'Motor vehicles & parts',color:'#1976D2'},
{ id: '66',name:'Boats & parts',color:'#03A9F4'},
{ id: '67',name:'Trains & parts',color:'#0288D1'},
{ id: '68',name:'Other vehicles & parts',color:'#01579B'},
{ id: '69',name:'Ceramic articles',color:'#673AB7'},
{ id: '70',name:'Glass articles',color:'#512DA8'},
{ id: '71',name:'Optical products, watches & medical instruments',color:'#A1887F'},
{ id: '72',name:'Musical instruments & parts',color:'#5D4037'},
{ id: '73',name:'Miscellanous manufactured products',color:'#9575CD'}
]

//export const treemapGroupsEmbedded = [{ id: '1', name: 'Live animals (except poultry)', color: '#002b54' },
//{ id: '2', name: 'Live animals (poultry)', color: '#971779' },
//{ id: '3', name: 'Meat (except poultry)', color: '#e03375' },
//{ id: '4', name: 'Meat (poultry)', color: '#009de8' },
//{ id: '5', name: 'Processed meat', color: '#a1dcdf3' },
//{ id: '6', name: 'Animal fat', color: '#fdd69b' },
//{ id: '7', name: 'Dairy products', color: '#fa9805' },
//{ id: '8', name: 'Other animal products (edible)', color: '#ef99ba' },
//{ id: '9', name: 'Animal products (not edible)', color: '#d6de4' },
//{ id: '10', name: 'Fish & shellfish', color: '#c8c8c8' },
//{ id: '11', name: 'Fish products (processed)', color: '#7b7b7b' },
//{ id: '12', name: 'Wheat', color: '#7dd5f0' },
//{ id: '13', name: 'Rice', color: '#798ea4' },
//{ id: '14', name: 'Other cereals', color: '#e1a851' },
//{ id: '15', name: 'Cereals (processed)', color: '#d88b16' },
//{ id: '16', name: 'Vegetables', color: '#fa7b05' },
//{ id: '17', name: 'Fruits', color: '#dc6d06' },
//{ id: '18', name: 'Pulses', color: '#d95784' },
//{ id: '19', name: 'Nuts', color: '#a3174d' },
//{ id: '20', name: 'Spices', color: '#9eaebe' },
//{ id: '21', name: 'Crops nec', color: '#8095a9' },
//{ id: '22', name: 'Flowers', color: '#006064' },
//{ id: '23', name: 'Oil seeds', color: '#1B5E20' },
//{ id: '24', name: 'Vegetable oils & fats', color: '#689F38' },
//{ id: '25', name: 'Seeds for sowing', color: '#AED581' },
//{ id: '26', name: 'Sugar', color: '#8BC34A' },
//{ id: '27', name: 'Cocoa beans & products', color: '#33691E' },
//{ id: '28', name: 'Other food products', color: '#FF8A65' },
//{ id: '29', name: 'Vegetal residues & animal feed', color: '#E64A19' },
//{ id: '30', name: 'Coffee', color: '#7986CB' },
//{ id: '31', name: 'Tea & mate', color: '#3F51B5' },
//{ id: '32', name: 'Beverages (not alcoholic)', color: '#303F9F' },
//{ id: '33', name: 'Beverages (alcoholic)', color: '#1A237E' },
//{ id: '34', name: 'Chemicals', color: '#BA68C8' },
//{ id: '35', name: 'Fertilizers', color: '#7B1FA2' },
//{ id: '36', name: 'Pharmaceutical components', color: '#9C27B0' },
//{ id: '37', name: 'Beauty products & perfumes', color: '#4A148C' },
//{ id: '38', name: 'Mineral products', color: '#4DD0E1' },
//{ id: '39', name: 'Ferrous metals', color: '#00BCD4' },
//{ id: '40', name: 'Other metals', color: '#009688' },
//{ id: '41', name: 'Metal products', color: '#00ACC1' },
//{ id: '42', name: 'Pearls & (semi-)precious stones', color: 'blue' },
//{ id: '43', name: 'Precious metals', color: 'gold' },
//{ id: '44', name: 'Jewellery & precious metal articles', color: 'green' },
//{ id: '45', name: 'Wood & vegetable material', color: '#9E9E9E' },
//{ id: '46', name: 'Wood products', color: '#212121' },
//{ id: '47', name: 'Paper products', color: '#90A4AE' },
//{ id: '48', name: 'Natural latex & rubber', color: '#455A64' },
//{ id: '49', name: 'Plastics & rubber', color: '#607D8B' },
//{ id: '50', name: 'Skins, leather & products thereof', color: '#E0E0E0' },
//{ id: '51', name: 'Footwear', color: '#616161' },
//{ id: '52', name: 'Silk (fabric)', color: '#FFC107' },
//{ id: '53', name: 'Wool & animal hair (fabric)', color: '#FFA000' },
//{ id: '54', name: 'Cotton (fabric)', color: '#FFD54F' },
//{ id: '55', name: 'Other natural fabric (flax, hemp, etc.)', color: '#FF6F00' },
//{ id: '56', name: 'Synthetic textile fabric', color: '#FBC02D' },
//{ id: '57', name: 'Other textile fabric', color: '#F57F17' },
//{ id: '58', name: 'Apparel', color: '#F57C00' },
//{ id: '59', name: 'Carpets', color: '#FF9800' },
//{ id: '60', name: 'Home textiles', color: '#E65100' },
//{ id: '61', name: 'Other textile products', color: '#FFB74D' },
//{ id: '62', name: 'Machinery', color: '#795548' },
//{ id: '63', name: 'Electronic equipment', color: '#3E2723' },
//{ id: '64', name: 'Aircrafts, spacecrafts & parts', color: '#64B5F6' },
//{ id: '65', name: 'Motor vehicles & parts', color: '#1976D2' },
//    { id: '66', name: 'Boats & parts', color: '#002b54' },
//{ id: '67', name: 'Trains & parts', color: '#0288D1' },
//{ id: '68', name: 'Other vehicles & parts', color: '#01579B' },
//{ id: '69', name: 'Ceramic articles', color: '#673AB7' },
//{ id: '70', name: 'Glass articles', color: '#512DA8' },
//{ id: '71', name: 'Optical products, watches & medical instruments', color: '#A1887F' },
//{ id: '72', name: 'Musical instruments & parts', color: '#5D4037' },
//{ id: '73', name: 'Miscellanous manufactured products', color: '#9575CD' }
//]