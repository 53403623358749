import React from 'react'
import YouTube from 'react-youtube';
import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import { Link } from 'react-router-dom'
import {
    Nav,
    Navbar,
    NavItem,
    Collapse,
    DropdownMenu,
    NavbarToggler,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap'
import Collapsible from 'react-collapsible';

export default class VideoCourses extends React.Component {
    constructor() {
        super();

        //this.state = Open_Weather_Interface;
    }

     opts = {
        height: '350',
        width: '500',
        origin: window.location.origin,
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };
      _onReady = (event) => {
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
      }  


      render() {
        return (
            
            <section id="itc-video-content" className="itc-video-content">

					<div className="container-fluid">
						<div className="row">
							<h1 className="col-12 section-title text-center mb-0">{ t('lbl_video_courses')}</h1>
                            <div className="col-12 mb-1"><hr/></div>
							<div className="vid-col col-md-6 col-sm-12 ">
                                <div className="videoWrapper">
                                    <YouTube  className ="embed-responsive-item" videoId="gByqDa2ipzE" opts={this.opts} />                                
                                </div>
                                <div className="vid-title">Trade Map</div>
							</div>
						
							<div className="vid-col col-md-6 col-sm-12">
                                <div className="videoWrapper">
                                    <YouTube className ="embed-responsive-item" videoId="3fxShmArNe8" opts={this.opts} />                                
                                </div>
                                <div className="vid-title">Market Access Map</div>
							</div>
                            </div>
                            <div className="row">
							
                            <div className="vid-col col-md-6 col-sm-12">
                                <div className="videoWrapper"> 
                                    <YouTube className ="embed-responsive-item" videoId="JolsTgJXCWk" opts={this.opts} />  
                                </div>
                                <div className="vid-title">Export Potential Map</div>
                            </div>
                            <div className="vid-col col-md-6 col-sm-12">
                                <div className="videoWrapper">  
                                    <YouTube className ="embed-responsive-item" videoId="DhI5KoKxu5s" opts={this.opts} />                                
                                </div>
                                <div className="vid-title">EuroMed Trade Helpdesk</div>
							</div>
                           
                          
                            {/*<Collapsible className ="CollapsedVideoPanel text-center" openedClassName="CollapsedVideoPanel text-center"  
                            trigger="VIEW MORE VIDEOS" triggerTagName="button" triggerClassName="btnTools w-25 mb-5" triggerOpenedClassName ="btnTools w-25 mb-5"
                            contentOuterClassName="Collapsible__VideoContentOuter mt-2" contentInnerClassName ="w-100 Collapsible__VideoContentInner">
                                <div className="vid-col col-12 col-sm-4 embed-responsive-16by9 mb-3">
                                    <div className="videoWrapper"> 
                                        <YouTube className ="embed-responsive-item" videoId="yUi1Os5nPYg" opts={this.opts} />  
                                    </div>
                                    <div className="vid-title">Investment Map</div>
                                </div>
                                <div className="vid-col col-12 col-sm-4 embed-responsive-16by9 mb-3">
                                    <div className="videoWrapper"> 
                                        <YouTube className ="embed-responsive-item" videoId="3cDUTHZfB7Q" opts={this.opts} />  
                                    </div>
                                    <div className="vid-title">EuroMed Trade</div>
                                </div>
        </Collapsible>*/}
						</div>
                        <div class="col newsletter-download text-center">
                               <a href="https://www.youtube.com/user/MarketAnalysisTools/playlists" target='_blank'>
                                   <button class="btnTools w-25 mb-5">{ t('btn_view_more_videos')}</button></a>
                            </div>
					</div>
				</section>
        );
    }
}