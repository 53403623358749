export const defaultLocale = "en"

export const locales = [
    /*{
        code: "ar",
        name: "عربي",
        dir: "rtl"
    },*/
    {
        code: "en",
        name: "EN",
        dir: "ltr"
    },

    {
        code: "es",
        name: "ES",
        dir: "ltr"
    },
    {
        code: "fr",
        name: "FR",
        dir: "ltr"
    }
]

