import { connect } from 'react-redux'
import React, { Component } from 'react'

import { t } from '../services/i18n'
import {  userActions } from '../actions'
import LocalizedLink from './LocalizedLink'
import Social from '../components/Social';
import AppLeftMenu from '../components/AppLeftMenu'
import WTP_2019_Img from '../img/WTP_2019.jpg';
import WTP_2020_Img from '../img/WTP_2020.png';
import WTP_2021_Img from '../img/WTP_2021.png';
import WTP_2022_Img from '../img/WTP_2022.png';
import WTP_2023_Img from '../img/WTP_2023.png';
import WTP_2024_Img from '../img/WTP_2024.png';
import NewslettersArchive from '../components/NewslettersArchive';

class News extends Component {
    
    componentDidMount() {
        //this.props.dispatch(userActions.getAll());
        this.props.getAllUsers();

    }
    render() {
        return (
            <div>
                <section id="itc-banner" >
                    <h1 className="itc-banner-title mx-auto">{ t('news')}</h1>
                </section>
                <div id="page-wrapper" className="container">
					<div className="row">
						<div id="itc-page-content" className="itc-page-content col-sm-12 col-md-12 mt-5">
							<section id="itc-articles-content">
								<div className="container-fluid">
									<div className="row">
										<div className="">
										<div className="featured-mat p-5">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2024</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-4"><img style={{float:"left", marginRight:"10px"}} src={WTP_2024_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_6')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_5')} { t('lbl_news_wtp_desc_5a')}  <a target='_blank' href='https://www.macmap.org'>Market Access Map database</a> { t('lbl_news_wtp_desc_6b')}
													<br></br>
													<br></br>
													&nbsp;{ t('lbl_news_pub_date_6')}
													</div>
												</div>
												
												<div className="text-left small-center"><a target="_blank" href="https://intracen.org/resources/publications/world-tariff-profiles-2024"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>
										<div className="featured-mat p-5">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2023</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-4"><img style={{float:"left", marginRight:"10px"}} src={WTP_2023_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_5')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_5')} { t('lbl_news_wtp_desc_5a')}  <a target='_blank' href='https://www.macmap.org'>Market Access Map database</a> { t('lbl_news_wtp_desc_5b')}
													<br></br>
													<br></br>
													&nbsp;{ t('lbl_news_pub_date_5')}
													</div>
												</div>

												
												<div className="text-left small-center"><a target="_blank" href="https://intracen.org/resources/publications/world-tariff-profiles-2023"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>
											<div className="featured-mat p-5">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2022</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-4"><img style={{float:"left", marginRight:"10px"}} src={WTP_2022_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_4')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_4')} <a target='_blank' href='https://www.macmap.org'>Market Access Map database</a> { t('lbl_news_wtp_desc_4a')} <a target='_blank' href='https://findrulesoforigin.org'>Rules of Origin database</a> { t('lbl_news_wtp_desc_4b')}
													<br></br>
													<br></br>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ t('lbl_news_pub_date_4')}
													</div>
												</div>

												
												<div className="text-left small-center"><a target="_blank" href="https://intracen.org/resources/publications/world-tariff-profiles-2022"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>

											<div className="featured-mat p-5">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2021</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-4"><img style={{float:"left", marginRight:"10px"}} src={WTP_2021_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_3')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_3')} <a target='_blank' href='https://www.macmap.org'>www.macmap.org</a> { t('lbl_news_wtp_desc_3a')}
													<br></br>
													<br></br>
													{ t('lbl_news_pub_date_3')}
													</div>
												</div>

												
												<div className="text-left small-center"><a target="_blank" href="https://intracen.org/resources/publications/world-tariff-profiles-2021"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>
											<div className="featured-mat p-4">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2020</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-4"><img style={{float:"left", marginRight:"10px"}} src={WTP_2020_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_1')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_1')} <a target='_blank' href='https://www.macmap.org'>www.macmap.org</a> { t('lbl_news_wtp_desc_1a')}
													<br></br>
													<br></br>
													{ t('lbl_news_pub_date')}
													</div>
												</div>
												<div className="text-left small-center"><a target="_blank" href="https://www.wto.org/english/news_e/news20_e/publ_06jul20_e.htm"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>

											<div className="featured-mat p-3">
												
												<div className="featured-article-info col-16 col-sm-16 px-0">
													<h4>{ t('lbl_news_wtp_title')} 2019</h4>
													<div className="article-date"></div>
													<div className="article-excerpt py-3"><img style={{float:"left", marginRight:"10px"}} src={WTP_2019_Img}></img><span style={{color:"rgba(0, 44, 82, 0.8)", fontWeight:"500"}}>{ t('lbl_news_wtp_subtitle_2')}</span>
													<br></br>
													<br></br>
													{ t('lbl_news_wtp_desc_2')}
													<br></br>
													<br></br>
													{ t('lbl_news_pub_date_2')}
													</div>
												</div>
												<div className="text-left small-center"><a target="_blank" href="http://www.intracen.org/publication/World-Tariff-Profiles-2019/"><button className="newsletterBtn mb-0">{ t('lbl_learn_more')}</button></a></div>
											</div>
										</div>
										
									</div>
								</div>
							</section>
						</div>
					</div>	
                </div>
                <NewslettersArchive></NewslettersArchive>
                <Social></Social>
            </div>  
        )
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const mapDispatchToProps = dispatch => ({
    ...userActions
  })

export default connect(mapStateToProps, mapDispatchToProps)(News)
