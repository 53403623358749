import React from 'react'
import { t } from '../services/i18n'
import us2020 from "../user-survey/2020-MAT Survey Results.pdf"
import us2019 from "../user-survey/2019-MAT Survey Results.pdf"
import us2018 from "../user-survey/2018-MAT Survey Results.pdf"
import us2017 from "../user-survey/2017-MAT Survey Results.pdf"

export default () => (
  <section id="survey-section">
  <div className="container">
  <h2 className="col-12 section-title text-center mt-5">{ t('section_usersurvey')}</h2>
  <hr></hr>
        <div className="row no-gutters justify-content-md-center text-center mt-4">
        <div className="col newsletter-download text-center">
              <a target="_blank" href={us2020}>
                  <button className="btnNewsletters">{ t('lbl_report')} - 2020</button>
              </a>
          </div>
          <div className="col newsletter-download text-center">
              <a target="_blank"href={us2019}>
                  <button className="btnNewsletters">{ t('lbl_report')} - 2019</button>
              </a>
          </div>
          <div className="col newsletter-download text-center">
              <a target="_blank" href={us2018}>
                  <button className="btnNewsletters">{ t('lbl_report')} - 2018</button>
              </a>
          </div>
          <div className="col newsletter-download text-center">
              <a target="_blank" href={us2017}>
                  <button className="btnNewsletters">{ t('lbl_report')} - 2017</button>
              </a>
          </div>
 
      </div>
     <div className="row text-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <h2 className="section-title mt-4 subscribe-title"></h2>
                 {/*<h2 className="section-title mt-4 subscribe-title">Subscribe to our newsletter</h2>
                <form className="">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <input className="email-input" type="text" placeholder="EMAIL"></input>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              
                            <button className="newsletterBtn">SUSBSCRIBE</button>
            </div>

            </form>*/}
                </div>
        </div>
  </div>
</section>
)