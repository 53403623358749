import React from 'react'
import YouTube from 'react-youtube';
import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import { Link } from 'react-router-dom'
import {
    Nav,
    Navbar,
    NavItem,
    Collapse,
    DropdownMenu,
    NavbarToggler,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap'
import Collapsible from 'react-collapsible';

export default class VideoCoursesHome extends React.Component {
    constructor() {
        super();

        //this.state = Open_Weather_Interface;
    }

     opts = {
        height: '300',
        width: '400',
        origin: window.location.origin,
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };
      _onReady = (event) => {
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
      }  


      render() {
        return (
            <section id="learning-intro-section" className="content">
            <h2 className="col-12 section-title text-center mb-0">{ t('section_learning_corner')}</h2>
            <hr></hr>
					<div className="container">
						<div className="row text-center">
							<div className="col-12 col-sm-12 col-md-4">
                                <div className="videoCont vid-col banner-right embed-responsive embed-responsive-16by9 text-center">
                                <YouTube  className="embed-responsive-item" videoId="gByqDa2ipzE" opts={this.opts} />                                
                                </div>
                                <div className="video-title"> { t('lbl_video_courses')} { t('lbl_on')} Trade Map </div>
							</div>
							
							<div className="col-12 col-sm-12 col-md-4">
                            <div className="videoCont vid-col banner-right embed-responsive embed-responsive-16by9 text-center">
                            <YouTube className="embed-responsive-item" videoId="3fxShmArNe8" opts={this.opts} />                                
							</div>
                            <div className="video-title">{ t('lbl_video_courses')} { t('lbl_on')} Market Access Map </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-4">
                            <div className="videoCont vid-col banner-right embed-responsive embed-responsive-16by9 text-center">
                            <YouTube  className="embed-responsive-item" videoId="JolsTgJXCWk" opts={this.opts} />                                
							</div>
                            <div className="video-title">{ t('lbl_video_courses')} { t('lbl_on')} Export Potential Map </div>
                            </div>
                            
                            <div className="col-12 col-sm-12 col-md-12">
                            <LocalizedLink to="learning"><button className="button learning-button">{t('learning')}</button></LocalizedLink>
                            </div>						
                            
						</div>
					</div>
				</section>
        );
    }
}