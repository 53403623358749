export const chartConstants = {
    GET_MACMAPCHARTDATA: 'GET_MACMAPCHARTDATA',
    GET_MACMAPYEARCHARTDATA: 'GET_MACMAPYEARCHARTDATA',
    GET_TRADEMAPCHARTDATA: 'GET_TRADEMAPCHARTDATA',
    GET_EPMCHARTDATA: 'GET_EPMCHARTDATA',
    //ADDITIONAL EMBEDDED CHARTS
    GET_TRADEMAPEXPORTS: 'GET_TRADEMAPEXPORTS',
    GET_TRADEMAPPARTNERS: 'GET_TRADEMAPPARTNERS',
    GET_TRADEMAPPRODUCTEXPORTERS: 'GET_TRADEMAPPRODUCTEXPORTERS',
    GET_TRADEMAPPRODUCTSEXPORTED: 'GET_TRADEMAPPRODUCTSEXPORTED'
};
