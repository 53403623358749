import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import reducer from '../reducers'

const logger = createLogger({collapsed:true});

let composeEnhancers = null
const mw =[thunk];
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    mw.push(logger);
} else {
    composeEnhancers = compose
}




//logger always the last one
const store = createStore(reducer, composeEnhancers(applyMiddleware(...mw)))

export default store 
