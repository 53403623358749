import React from 'react'
import { Redirect } from 'react-router-dom'

import Learning from './containers/Learning'
import HomePage from './containers/HomePage'
import { defaultLocale } from './config/i18n'

import { localizeRoutes } from './services/i18n/util'
import  LoginPage  from './containers/LoginPage';
import DataAvailability from './containers/DataAvailability';
import News from './containers/News';
import CountryProfile from './containers/CountryProfile';
import JoinUs from './containers/JoinUs';
import CountryProfileEmbedded from './containers/Embedded/CountryProfile_Embedded';
import RegionProfileEmbedded from './containers/Embedded/RegionProfile_Embedded';
import SectorProfileEmbedded from './containers/Embedded/SectorProfile_Embedded';

const routes = [
    {
        path: "/",
        exact: true,
        localize: false,
        component: () => <Redirect to={`/${defaultLocale}`} />
    },

    {
        path: "/embedded/sector-profile-embedded",
        component: SectorProfileEmbedded
    },
    {
        path: "/embedded/country-profile-embedded",
        component: CountryProfileEmbedded
    },
    {
        path: "/embedded/region-profile-embedded",
        component: RegionProfileEmbedded
    },
    {
        path: "/learning",
        component: Learning
    },
    {
        path: "/country-profile",
        component: CountryProfile
    },
    {
        path: "/news",
        component: News
    },
    {
        path: "/data-availability",
        component: DataAvailability
    },

    {
        path: "/login",
        component: LoginPage
    }, 
    {
        path: "/joinus",
        component: JoinUs

    },
    {
        path: "/",
        component: HomePage
    }
    /*{
        path: "/",
        component: Home
    }*/
]

export default localizeRoutes(routes)
