import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const GoogleAnalytics = () => {
  const {pathname} = useLocation() || "";
  console.log(111, useLocation());
  useEffect(() => {
    if (pathname.length > 0) {
      // const environment = process.env.NODE_ENV || "development";
      // if (environment === "production") {
      // ReactGA.pageview(window.location.pathname + window.location.search);

      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      // }
    }

}, [pathname]
)


return <></>;
}


export default GoogleAnalytics;
