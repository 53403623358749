import { connect } from 'react-redux'
import React, { Component } from 'react'

import { t } from '../services/i18n'
import {  userActions } from '../actions'
import LocalizedLink from './LocalizedLink'
import VideoCourses from '../components/VideoCourses'
import UserGuides from '../components/UserGuides'
import AppLeftMenu from '../components/AppLeftMenu'
import Social from '../components/Social'
import Newsletters from '../components/Newsletters'

class Learning extends Component {
    
    componentDidMount() {
        
        this.props.getAllUsers();
        
    }
    render() {
        return (
            <div> 
                <section id="itc-banner" >
                    <h1 className="itc-banner-title mx-auto">{ t('section_learning_corner')}</h1>
                </section>
                <div id="page-wrapper"  className="container">
                    <div className="row">
                        <div id="itc-page-content" className="itc-page-content col-sm-12 col-md-12">
                            <main id="main" role="main" className="py-4 px-4">
                            <div className="learning">
                                {
                                    this.props.ajaxLoading ?
                                        <p className="text-center alert alert-info">Loading...</p>
                                        :
                                        <div>
                                            <VideoCourses></VideoCourses>
                                            {/*<UserGuides></UserGuides>*/}
                                        </div>
                                }
                            </div>
                            </main>
                        </div>
                    </div>
                </div>
             	{/*<AppLeftMenu></AppLeftMenu>*/}
                <Social></Social>
                <Newsletters></Newsletters>
            </div>  
        )
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
        
    };
}

const mapDispatchToProps = dispatch => ({
    
    ...userActions
  })

export default connect(mapStateToProps, mapDispatchToProps)(Learning)
