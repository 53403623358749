import React from 'react'
import YouTube from 'react-youtube';
import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import { Link } from 'react-router-dom'
import {
    Nav,
    Navbar,
    NavItem,
    Collapse,
    DropdownMenu,
    NavbarToggler,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap'
import Collapsible from 'react-collapsible';

export default class VideoCourses extends React.Component {
    constructor() {
        super();

        //this.state = Open_Weather_Interface;
    }

     opts = {
        height: '350',
        width: '500',
        origin: window.location.origin,
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };
      _onReady = (event) => {
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
      }  


      render() {
        return (
            
            <section id="itc-video-content" className="itc-video-content">

					<div className="container-fluid">
						<div className="row">
							<h1 className="col-12 section-title text-center mb-0">{ t('section_data_videos')}</h1>
                            <div className="col-12 mb-1"><hr/></div>
							<div className="vid-col col-md-6 col-sm-12 ">
                                <div className="videoWrapper">
                                    <YouTube  className ="embed-responsive-item" videoId="9xtcX_b6Uo" opts={this.opts} />                                
                                </div>
                                
							</div>
						
							<div className="vid-col col-md-6 col-sm-12">
                                <div className="videoWrapper">
                                    <YouTube className ="embed-responsive-item" videoId="9xtcX_b6Uo8" opts={this.opts} />                                
                                </div>
                                
							</div>
                            </div>
                            <div className="row">
							
                            <div className="col-12 mb-1"><hr/></div>
							<div className="vid-col col-md-6 col-sm-12 ">
                                <div className="videoWrapper">
                                    <YouTube  className ="embed-responsive-item" videoId="YvF85w-IB9w" opts={this.opts} />                                
                                </div>
                                
							</div>
						
							<div className="vid-col col-md-6 col-sm-12">
                                <div className="videoWrapper">
                                    <YouTube className ="embed-responsive-item" videoId="rHLcjGl61pU" opts={this.opts} />                                
                                </div>
                                
							</div>
                            </div>
                        
					</div>
				</section>
        );
    }
}