import { connect } from 'react-redux'
import React, { Component } from 'react'

import { t } from '../services/i18n'
import { fetchCountriesAll,fetchTariff,filterTariff,filterNTM,filterRemedies,filterStatistics,filterStatisticsYear,fetchNTM,fetchRemedies,fetchStatistics,fetchStatisticsYear, userActions } from '../actions'
import LocalizedLink from './LocalizedLink'

import AppLeftMenu from '../components/AppLeftMenu'
import Select from 'react-select';
import CountryCombo from './CountryCombo';
import Newsletters from '../components/Newsletters'
import Social from '../components/Social'
import {ConvertToCSV,objectsToCsv} from '../services/util'
import { CSVLink, CSVDownload } from "react-csv";
//import { bindActionCreators } from 'redux';


class DataAvailability extends Component {
    
    componentWillMount() {    
		this.props.getAllUsers();	
		this.setState({ loadingTariff: false });
		this.setState({ loadingNTM: false });
		//this.setState({ loadingRemedies: false });
		this.setState({ loadingStatistics: false });
		this.setState({ loadingStatisticsYear: false });
	}
	componentDidMount() {
		
		(async () => {
			this.props.fetchCountriesAll()			
			.then((res) => {
				//console.debug('countries',this.props.countries);
				this.setState({ loadingTariff: true });
				this.setState({ loadingNTM: true });
				//this.setState({ loadingRemedies: true });
				this.setState({ loadingStatistics: true });
				this.setState({ loadingStatisticsYear: true });

				let selectedCountry = this.props.countries.filter(c=>c.code=="ALL");
				this.handleChangeCountry(selectedCountry);
				
				//console.debug('selectedCountry',selectedCountry);
				this.props.fetchTariff(selectedCountry).then(() => {
					this.setState({ loadingTariff: false });
				});
			
			
				this.props.fetchNTM(selectedCountry).then(() => {
					this.setState({ loadingNTM: false });
				});
				/*this.props.fetchRemedies(selectedCountry).then(() => {
					this.setState({ loadingRemedies: false });
				});*/
				
				this.props.fetchStatistics(selectedCountry).then(() => {
					this.setState({ loadingStatistics: false });
				});

				//console.debug("load year");
				this.props.fetchStatisticsYear(selectedCountry).then(() => {
					this.setState({ loadingStatisticsYear: false });
				});
				
				
			})
			.catch(err => {
			})	
		})();

	}
// combo
   state = {

		selectedCountry: null,
		/*loadingTariff:false,
		dataAvailability: {
			tariff:[],
			ntm:[],
			remedies:[],
			statistics:[],
			tariffAll:[],
			ntmAll:[],
			remediesAll:[],
			statisticsAll:[]
		}*/
	  }

	
	/*handleChangeGroup = (selectedGroup) => {
		this.setState({ selectedGroup});
		//console.log(`Option selected:`, selectedOption);
      }*/
     
	  //end combo

	  

		downloadTariff = (selectedCountry) => {

			let csvdata = objectsToCsv(this.props.tariff);
			//console.log('ConvertToCSV',csvdata);

		
			this.setState({ dataToDownload: csvdata }, () => {
					// click the CSVLink component to trigger the CSV download
					this.csvLink.link.click()
			})

			
		}

	  handleChangeCountry = (selectedCountry) => {
        
				
				let o=[];
				if(Array.isArray(selectedCountry) && selectedCountry[0].code==='ALL' && selectedCountry.length > 1)
				{
					o = selectedCountry.filter(item => item.code != 'ALL');
				}
				else if(Array.isArray(selectedCountry) && selectedCountry.length > 1 && selectedCountry[selectedCountry.length - 1].code ==='ALL' )
				{
					o=[{code: "ALL",label: t('lbl_all_countries') ,value: "ALL"}];
				}
				else
				{
					o=selectedCountry;
				}

				this.setState({ selectedCountry : o});
				
				/*this.setState({ loadingTariff: true });
				this.setState({ loadingTariff: true });
				this.setState({ loadingTariff: true });*/
				
			//console.debug("props",this.props);
			//console.log("props",this.filterData(this.props.tariffAll,selectedCountry));

		
			//console.debug("state",this.state);
			this.props.filterTariff(o,this.props.tariffAll);
			this.props.filterNTM(o,this.props.ntmAll);
			//this.props.filterRemedies(o,this.props.remediesAll);
			this.props.filterStatistics(o,this.props.statisticsAll);
			this.props.filterStatisticsYear(o,this.props.statisticsYearAll);
			
		


			
    } 

	        
    render() {

		//const { selectedGroup,selectedCountry  } = this.state;
		
		

        return (
            <div>
                <section id="itc-banner" >
                    <h1 className="itc-banner-title mx-auto">{ t('data_availability')}</h1>
                </section>
                <div id="page-wrapper" className="container">
					<div className="row"> 	
						<div id="itc-page-content" className="itc-page-content col-sm-12 col-md-12 py-4 px-4">
							<section id="data-form">
								<div className="container-fluid">
									<div className="row row justify-content-md-center mb-4">
									
										<div className="col-sm-4 mt-2 text-right"><label htmlFor="" className="form-label">{ t('lbl_choose_one_or_more_countres')}</label></div>
										<div id="select-country-cont"  className="col-sm-3 col-md-6 select-cont">
										
												<Select  className="countrySelect"
											
											isSearchable="true"
											isMulti="true"
													value={this.state.selectedCountry}
													onChange={this.handleChangeCountry}
													options={this.props.countries}											
												/>  
											</div>
									</div>
								</div>
							</section>   
							<section id="itc-data-content" className="multi-country">
								<div className="container-fluid">
									<div className="row">	
									
										<div className="col-12 col-sm-12 col-md-6">
											<div className="featured-mat">
												<div className="header-featured-mat">
													
												{

													/*

	console.log('map',(this.props.tariff?this.props.tariff.map(item => {
		//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
		if(this.props.countries.find(o => o.value === item.countryCode))
	return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
else
	return {"Country" : "Unknown (", "Data": item.dataAvailable}	
	}):[])
	)*/
}
	<div className="csv-btn">

												<CSVLink
												
                        data={(this.props.tariff?objectsToCsv((this.props.tariff?this.props.tariff.map(item => {
													//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
													if(this.props.countries.find(o => o.value === item.countryCode))
												return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
											else
												return {"Country" : "Unknown (", "Data": item.dataAvailable}	
												}):[])):[])}
                        filename="dataTariff.csv"
                        //className="hidden"
                        ref={(r) => this.csvLink = r}
                        target="_blank"><img src="../img/icon.svg" width="20px" height="20px" alt="Download data as CSV"></img></CSVLink>
												</div>
												<div className="csv-title"> <a href="https://www.macmap.org/en/about/data-availability" target="_blank">Market Access Map </a></div>
												</div>
												<div className="data-cont">
													<h3 className="secondary-title text-center mt-0"><strong>{ t('lbl_download_tariff')}</strong></h3>
													{ this.state.loadingTariff && <div className="imgspinner"><img src="../img/preloader.gif" /> </div>}
													<div className="data-results">
														<table>
														<tbody>{(this.props.tariff?this.props.tariff.map(item => (     
															<tr className="data-val" key={item.countryCode}><td>{(this.props.countries.find(o => o.value === item.countryCode) ? this.props.countries.find(o => o.value === item.countryCode).label:"Unknown (" + item.countryCode + ")")}</td><td className="alignRight">{item.dataAvailable}</td></tr>																	
														)):"")}</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-6">
											<div className="featured-mat">
												<div className="header-featured-mat">
												
												<div className="csv-btn">
												<CSVLink
												
                        data={(this.props.ntm?objectsToCsv((this.props.ntm?this.props.ntm.map(item => {
													//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
													if(this.props.countries.find(o => o.value === item.countryCode))
												return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
											else
												return {"Country" : "Unknown (", "Data": item.dataAvailable}	
												}):[])):[])}
                        filename="dataNTM.csv"
                        //className="hidden"
                        ref={(r) => this.csvLink = r}
                        target="_blank"><img src="../img/icon.svg" width="20px" height="20px" alt="Download data as CSV"></img></CSVLink>
												</div>
												<div className="csv-title"> <a href="https://www.macmap.org/en/about/data-availability" target="_blank">Market Access Map </a></div>
												</div>
												<div className="data-cont">
													<h3 className="secondary-title text-center mt-0"><strong>{ t('lbl_download_ntm')}</strong></h3>
													{ this.state.loadingNTM && <div className="imgspinner"><img src="../img/preloader.gif" /> </div>}
													<div className="data-results ">
													<table>
													<tbody>{(this.props.ntm?this.props.ntm.map(item => (     
																<tr className="data-val" key={item.countryCode}><td>{t(this.props.countries.find(o => o.value === item.countryCode) ? this.props.countries.find(o => o.value === item.countryCode).label:"Unknown (" + item.countryCode + ")")}</td><td className="alignRight">{item.dataAvailable}</td></tr>																	
															)):"")}</tbody>
															</table>
													</div>
												</div>
											</div>
										</div>
										{/*
										<div className="col-12 col-sm-12 col-md-6">
											<div className="featured-mat">
												<div className="header-featured-mat">
												<div className="csv-btn">
												<CSVLink
											
                        data={(this.props.remedies?objectsToCsv((this.props.remedies?this.props.remedies.map(item => {
													//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
													if(this.props.countries.find(o => o.value === item.countryCode))
												return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
											else
												return {"Country" : "Unknown (", "Data": item.dataAvailable}	
												}):[])):[])}
                        filename="dataRemedies.csv"
                        //className="hidden"
                        ref={(r) => this.csvLink = r}
                        target="_blank"><img src="../img/icon.svg" width="20px" height="20px" alt="Download data as CSV"></img></CSVLink>
												</div>
												</div>
												<div className="data-cont">
													<h3 className="secondary-title text-center mt-0"><strong>Trade Remedies</strong></h3>
													{ this.state.loadingRemedies && <div className="imgspinner"><img src="../img/preloader.gif" /> </div>}
													<div className="data-results">
													<table>
													<tbody>	
															{(this.props.remedies?this.props.remedies.map(item => (     
																<tr className="data-val" key={item.countryCode}><td>{(this.props.countries.find(o => o.value === item.countryCode) ? this.props.countries.find(o => o.value === item.countryCode).label:"Unknown (" + item.countryCode + ")")}</td><td className="alignRight">{item.dataAvailable}</td></tr>																	
															)):"")}	
														</tbody>
															</table>
													</div>
												</div>
											</div>
										</div>*/}

<div className="col-12 col-sm-12 col-md-6">
											<div className="featured-mat">
												<div className="header-featured-mat">
												
												
												<div className="csv-btn">
												
												<CSVLink												
												
                        data={(this.props.statisticsYear?objectsToCsv((this.props.statisticsYear?this.props.statisticsYear.map(item => {
													//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
													if(this.props.countries.find(o => o.value === item.countryCode))
												return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
											else
												return {"Country" : "Unknown (", "Data": item.dataAvailable}	
												}):[])):[])}
                        filename="dataYearlyTradeStatistics.csv"
                        //className="hidden"
                        ref={(r) => this.csvLink = r}
                        target="_blank"><img src="../img/icon.svg" width="20px" height="20px" alt="Download data as CSV"></img></CSVLink>
												</div>
												<div className="csv-title"> <a href="https://www.trademap.org/stDataAvailability.aspx?nvpm=1%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c2%7c1%7c%7c%7c" target="_blank">Trade Map</a></div>
												</div>
												<div className="data-cont">
													<h3 className="secondary-title text-center mt-0"><strong>{ t('lbl_download_trade_yr')}</strong></h3>
													{ this.state.loadingStatisticsYear && <div className="imgspinner"><img src="../img/preloader.gif" /> </div>}
													<div className="data-results">
													<table>		
														<tbody>{(this.props.statisticsYear?this.props.statisticsYear.map(item => (     
																<tr className="data-val" key={item.countryCode}><td>{(this.props.countries.find(o => o.value === item.countryCode) ? this.props.countries.find(o => o.value === item.countryCode).label:"Unknown (" + item.countryCode + ")")}</td><td className="alignRight">{item.dataAvailable}</td></tr>																	
															)):"")}</tbody>															
															</table>
													</div>
												</div>
											</div>	
										</div>


										<div className="col-12 col-sm-12 col-md-6">
											<div className="featured-mat">
												<div className="header-featured-mat">
												
												<div className="csv-btn">

												<CSVLink												
												
                        data={(this.props.statistics?objectsToCsv((this.props.statistics?this.props.statistics.map(item => {
													//return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
													if(this.props.countries.find(o => o.value === item.countryCode))
												return {"Country" : this.props.countries.find(o => o.value === item.countryCode).label, "Data": item.dataAvailable} 
											else
												return {"Country" : "Unknown (", "Data": item.dataAvailable}	
												}):[])):[])}
                        filename="dataMonthlyTradeStatistics.csv"
                        //className="hidden"
                        ref={(r) => this.csvLink = r}
                        target="_blank"><img src="../img/icon.svg" width="20px" height="20px" alt="Download data as CSV"></img></CSVLink>
												</div>
												<div className="csv-title"> <a href="https://www.trademap.org/stDataAvailability.aspx?nvpm=1%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c%7c2%7c1%7c%7c%7c" target="_blank">Trade Map</a></div>
												</div>
												<div className="data-cont">
													<h3 className="secondary-title text-center mt-0"><strong>{ t('lbl_download_trade_mm')}</strong></h3>
													{ this.state.loadingStatistics && <div className="imgspinner"><img src="../img/preloader.gif" /> </div>}
													<div className="data-results">
													<table>		
														<tbody>{(this.props.statistics?this.props.statistics.map(item => ( 																																
																(item.dataAvailable.toLowerCase().includes("mirror")?"":<tr className="data-val" key={item.countryCode}><td>{(this.props.countries.find(o => o.value === item.countryCode) ? this.props.countries.find(o => o.value === item.countryCode).label:"Unknown (" + item.countryCode + ")")}</td><td className="alignRight">{item.dataAvailable}</td></tr>)																															
															)):"")}</tbody>															
															</table>
													</div>
												</div>
											</div>	
										</div>


									</div>
								</div>
							</section>
						</div>
					</div>	
                </div>
				{/*<div className="container">
				<h2 className="col-12 section-title text-center pt-4">Additional Market Analysis Tools</h2>
				<hr></hr>
					<div className="row text-center mb-3 mt-4">
						
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="http://findrulesoforigin.org/" target="_blank">
								<button className="btnNewsletters">Rules of Origin</button>
							</a>
						</div>	
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="https://mpi.intracen.org/home" target="_blank">
								<button className="btnNewsletters">Market Price Information</button>
							</a>
						</div>	
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="http://helpmetrade.org" target="_blank">
								<button className="btnNewsletters">Global Trade Help Desk</button>
							</a>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="http://www.epingalert.org/en#" target="_blank">
								<button className="btnNewsletters">E-Ping</button>
							</a>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="http://www.tradeobstacles.org/CountrySelection.aspx" target="_blank">
								<button className="btnNewsletters">Trade Obstacle Alert</button>
							</a>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
							<a href="http://euromed.macmap.org/euromed" target="_blank">
								<button className="btnNewsletters">EuroMed MacMap</button>
							</a>
						</div>
					</div>
														</div>*/}
				{/*<AppLeftMenu></AppLeftMenu>*/}
				<Social></Social>
				<Newsletters></Newsletters>
            </div>  
        )
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users,
		countries: state.countries.countriesAll,
		tariff: state.dataAvailability.tariff,
		ntm: state.dataAvailability.ntm,
		remedies: state.dataAvailability.remedies,
		statistics: state.dataAvailability.statistics,
		statisticsYear: state.dataAvailability.statisticsYear,
		tariffAll: state.dataAvailability.tariffAll,
		ntmAll: state.dataAvailability.ntmAll,
		remediesAll: state.dataAvailability.remediesAll,
		statisticsAll: state.dataAvailability.statisticsAll,
		statisticsYearAll: state.dataAvailability.statisticsYearAll
    };
}

const mapDispatchToProps = dispatch => ({
	fetchCountriesAll: () => dispatch(fetchCountriesAll()),
	fetchTariff: (countryIds) => dispatch(fetchTariff(countryIds)),
	filterTariff: (countryIds,data) => dispatch(filterTariff(countryIds,data)),
	fetchNTM: (countryIds) => dispatch(fetchNTM(countryIds)),
	filterNTM: (countryIds,data) => dispatch(filterNTM(countryIds,data)),
	fetchRemedies: (countryIds) => dispatch(fetchRemedies(countryIds)),
	filterRemedies: (countryIds,data) => dispatch(filterRemedies(countryIds,data)),
	fetchStatistics: (countryIds) => dispatch(fetchStatistics(countryIds)),
	fetchStatisticsYear: (countryIds) => dispatch(fetchStatisticsYear(countryIds)),
	filterStatistics: (countryIds,data) => dispatch(filterStatistics(countryIds,data)),
	filterStatisticsYear: (countryIds,data) => dispatch(filterStatisticsYear(countryIds,data)),
	...userActions,
	//...bindActionCreators({ fetchTariff }, dispatch)
  })

export default connect(mapStateToProps, mapDispatchToProps)(DataAvailability)
