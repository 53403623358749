import { combineReducers } from 'redux'

import l10n from './l10n'


import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import countries from './countries.reducer';
import chartData from './chart.data.reducer';
import dataAvailability from './data.availability.reducer';
import countryProfile from './country.profile.reducer';

const matApp = combineReducers({
    l10n,  
    authentication,
    users,
    alert,
    countries,
    chartData,
    dataAvailability,
    countryProfile
})

export default matApp