import React from 'react'
import {
   
    withRouter
  } from 'react-router-dom'
import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'

// Because we pass our component to withRouter
// our component will be passed `history` as a prop.

//const AuthButton = withRouter(({ history }) => (
export const AuthButton = withRouter(({ history }) => (    
    localStorage.getItem('user') ? (
     
        <li className="pointer" onClick={() => {
            
            localStorage.removeItem('user');
            history.push('/');
          }}><LocalizedLink className="login-link"  to="">{t('logout')}</LocalizedLink></li>
        
 
    ) : (
        <li className="pointer" onClick={()=>{
            window.previousLocation =  history.entries[history.index - 1].pathname;
        }}          
          ><LocalizedLink className="login-link"  to="login">{t('login')}</LocalizedLink></li>
     
    )
  ));


  