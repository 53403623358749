import React from 'react'
import { Redirect } from 'react-router-dom'


import Learning from './containers/Learning'

import { defaultLocale } from './config/i18n'
import { localizeRoutes } from './services/i18n/util'

import PrivatePage from './containers/PrivatePage';

const privateroutes = [
    /*{
        path: "/",
        exact: true,
        localize: false,
        component: () => <Redirect to={`/${defaultLocale}`} />
    },  */  
    {
        path: "/intranet",
        component: PrivatePage
    }
]

export default localizeRoutes(privateroutes)
