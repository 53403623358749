import React from 'react'
import { Timeline } from 'react-twitter-widgets'

import { t } from '../services/i18n'



export default () => (
   <section id="social-tabs-section">
   <div className="container">
   <h2 className="col-12 section-title text-center mt-5">{ t('section_connect')}</h2>
           <hr></hr>
       <div className="row">
       <div className="socialCont col-12 col-sm-12 col-md-1"></div>
           <div className="socialCont col-12 col-sm-12 col-md-5">
           <h3><i className="fab fa-twitter pr-1"></i> Twitter</h3>
               <div className="social">
               
<Timeline
dataSource={{
sourceType: 'profile',
screenName: 'ITC_MktAnalysis'
}}
options={{
username: 'ITC_MktAnalysis',
height: '400'
}}
//onLoad={() => console.log('Timeline is loaded!')}
/>
               </div>
           </div>
           <div className="socialCont col-12 col-sm-12 col-md-5">
           <h3> <i className="fab fa-facebook-f pr-1"></i> Facebook</h3>
           <div className="social embed-responsive embed-responsive-16by9">
           <iframe className="embed-responsive-item" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FITCmarketanalysistools&tabs=timeline&width=490&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
width="" height=""  scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>  
           
               </div>
           </div>
           <div className="socialCont col-12 col-sm-12 col-md-1"></div>

       </div>
   </div>
</section>
)