import React, { Component } from 'react'
import { Navbar, Nav } from 'reactstrap'

import { t } from '../services/i18n'

class AppFooter extends Component {

    render() {
        if (window.location.pathname.match("/embedded/")) {
            return null;
        }
        else {
            return (
                <footer>

                    <div className="container-fluid outer-cont">
                        <div className="row text-center">
                            <div className="col-12 col-sm-12">

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <a href="http://twitter.com/ITC_MktAnalysis" target="_blank"><i className="fab fa-twitter pr-1"></i></a>
                                            <a href="https://www.facebook.com/ITCmarketanalysistools" target="_blank"><i className="fab fa-facebook-f pr-1"></i></a>
                                            <a href="https://www.youtube.com/user/MarketAnalysisTools" target="_blank"><i className="fab fa-youtube pr-1"></i></a>
                                            <a href="https://www.linkedin.com/showcase/itc-market-analysis-tools/" target="_blank"><i className="fab fa-linkedin pr-1"></i></a>
                                        </div>


                                        {/*<div className="footerMenu col-12 col-sm-12 col-md-12 col-lg-12">
                                <a className="" href="https://www.trademap.org/Index.aspx">Trade Map</a>
                                <a className="" href="http://www.macmap.org/Default.aspx">Market Access Map</a>
                                <a className="" href="http://exportpotential.intracen.org/#/home">Export Potential Map</a>
                                <a className="" href="https://procurementmap.intracen.org/">Procurement Map</a>
                                <a className="" href="https://www.investmentmap.org/timeout.aspx">Investment Map</a>
                                <a className="" href="https://findrulesoforigin.org">Rules of origin</a>
</div>*/}
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <span>© 2019 INTERNATIONAL TRADE CENTRE - <a href="https://mas-admintools.intracen.org/accounts/TermsConditions.aspx" target="_blank">{t('lbl_tc')}</a></span>
                                            <p>Trade & Market Intelligence Section ● marketanalysis@intracen.org ● Tel.: +41 (0)22 730 05 46 ● Fax: +41 (0)22 730 05 77</p>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </footer>
            )
        }

    }
    
}
export default (AppFooter)