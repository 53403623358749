const ePingCtyCodeDict = new Map();
ePingCtyCodeDict.set("004", "C004"); //Afghanistan
ePingCtyCodeDict.set("008", "C008"); //Albania
ePingCtyCodeDict.set("012", "C012"); //Algeria
ePingCtyCodeDict.set("020", "C020"); //Andorra
ePingCtyCodeDict.set("024", "C024"); //Angola
ePingCtyCodeDict.set("028", "C028"); //Antigua and Barbuda
ePingCtyCodeDict.set("031", "C031"); //Azerbaijan
ePingCtyCodeDict.set("032", "C032"); //Argentina
ePingCtyCodeDict.set("036", "C036"); //Australia
ePingCtyCodeDict.set("040", "C040"); //Austria
ePingCtyCodeDict.set("044", "C044"); //Bahamas
ePingCtyCodeDict.set("048", "C048"); //Bahrain, Kingdom of
ePingCtyCodeDict.set("050", "C050"); //Bangladesh
ePingCtyCodeDict.set("051", "C051"); //Armenia
ePingCtyCodeDict.set("052", "C052"); //Barbados
ePingCtyCodeDict.set("056", "C056"); //Belgium
ePingCtyCodeDict.set("064", "C064"); //Bhutan
ePingCtyCodeDict.set("068", "C068"); //Bolivia, Plurinational State of
ePingCtyCodeDict.set("070", "C070"); //Bosnia and Herzegovina
ePingCtyCodeDict.set("072", "C072"); //Botswana
ePingCtyCodeDict.set("076", "C076"); //Brazil
ePingCtyCodeDict.set("084", "C084"); //Belize
ePingCtyCodeDict.set("090", "C090"); //Solomon Islands
ePingCtyCodeDict.set("096", "C096"); //Brunei Darussalam
ePingCtyCodeDict.set("100", "C100"); //Bulgaria
ePingCtyCodeDict.set("104", "C104"); //Myanmar
ePingCtyCodeDict.set("108", "C108"); //Burundi
ePingCtyCodeDict.set("112", "C112"); //Belarus
ePingCtyCodeDict.set("116", "C116"); //Cambodia
ePingCtyCodeDict.set("120", "C120"); //Cameroon
ePingCtyCodeDict.set("124", "C124"); //Canada
ePingCtyCodeDict.set("132", "C132"); //Cabo Verde
ePingCtyCodeDict.set("140", "C140"); //Central African Republic
ePingCtyCodeDict.set("144", "C144"); //Sri Lanka
ePingCtyCodeDict.set("148", "C148"); //Chad
ePingCtyCodeDict.set("152", "C152"); //Chile
ePingCtyCodeDict.set("156", "C156"); //China
ePingCtyCodeDict.set("170", "C170"); //Colombia
ePingCtyCodeDict.set("174", "C174"); //Comoros
ePingCtyCodeDict.set("178", "C178"); //Congo
ePingCtyCodeDict.set("180", "C180"); //Democratic Republic of the Congo
ePingCtyCodeDict.set("188", "C188"); //Costa Rica
ePingCtyCodeDict.set("191", "C191"); //Croatia
ePingCtyCodeDict.set("192", "C192"); //Cuba
ePingCtyCodeDict.set("196", "C196"); //Cyprus
ePingCtyCodeDict.set("203", "C203"); //Czech Republic
ePingCtyCodeDict.set("204", "C204"); //Benin
ePingCtyCodeDict.set("208", "C208"); //Denmark
ePingCtyCodeDict.set("212", "C212"); //Dominica
ePingCtyCodeDict.set("214", "C214"); //Dominican Republic
ePingCtyCodeDict.set("218", "C218"); //Ecuador
ePingCtyCodeDict.set("222", "C222"); //El Salvador
ePingCtyCodeDict.set("226", "C226"); //Equatorial Guinea
ePingCtyCodeDict.set("231", "C231"); //Ethiopia
ePingCtyCodeDict.set("232", "C232"); //Eritrea
ePingCtyCodeDict.set("233", "C233"); //Estonia
ePingCtyCodeDict.set("242", "C242"); //Fiji
ePingCtyCodeDict.set("246", "C246"); //Finland
ePingCtyCodeDict.set("251", "C250"); //France
ePingCtyCodeDict.set("262", "C262"); //Djibouti
ePingCtyCodeDict.set("266", "C266"); //Gabon
ePingCtyCodeDict.set("268", "C268"); //Georgia
ePingCtyCodeDict.set("270", "C270"); //The Gambia
ePingCtyCodeDict.set("275", "C275"); //Palestine
ePingCtyCodeDict.set("276", "C276"); //Germany
ePingCtyCodeDict.set("288", "C288"); //Ghana
ePingCtyCodeDict.set("296", "C296"); //Kiribati
ePingCtyCodeDict.set("300", "C300"); //Greece
ePingCtyCodeDict.set("308", "C308"); //Grenada
ePingCtyCodeDict.set("320", "C320"); //Guatemala
ePingCtyCodeDict.set("324", "C324"); //Guinea
ePingCtyCodeDict.set("328", "C328"); //Guyana
ePingCtyCodeDict.set("332", "C332"); //Haiti
ePingCtyCodeDict.set("336", "C336"); //Holy See
ePingCtyCodeDict.set("340", "C340"); //Honduras
ePingCtyCodeDict.set("344", "C344"); //Hong Kong, China
ePingCtyCodeDict.set("348", "C348"); //Hungary
ePingCtyCodeDict.set("352", "C352"); //Iceland
ePingCtyCodeDict.set("360", "C360"); //Indonesia
ePingCtyCodeDict.set("364", "C364"); //Iran
ePingCtyCodeDict.set("368", "C368"); //Iraq
ePingCtyCodeDict.set("372", "C372"); //Ireland
ePingCtyCodeDict.set("376", "C376"); //Israel
ePingCtyCodeDict.set("381", "C380"); //Italy
ePingCtyCodeDict.set("384", "C384"); //C�te d'Ivoire
ePingCtyCodeDict.set("388", "C388"); //Jamaica
ePingCtyCodeDict.set("392", "C392"); //Japan
ePingCtyCodeDict.set("398", "C398"); //Kazakhstan
ePingCtyCodeDict.set("400", "C400"); //Jordan
ePingCtyCodeDict.set("404", "C404"); //Kenya
ePingCtyCodeDict.set("408", "C408"); //Korea, Democratic People's Republic of
ePingCtyCodeDict.set("410", "C410"); //Korea, Republic of
ePingCtyCodeDict.set("414", "C414"); //Kuwait, the State of
ePingCtyCodeDict.set("417", "C417"); //Kyrgyz Republic
ePingCtyCodeDict.set("418", "C418"); //Lao People's Democratic Republic
ePingCtyCodeDict.set("422", "C422"); //Lebanese Republic
ePingCtyCodeDict.set("426", "C426"); //Lesotho
ePingCtyCodeDict.set("428", "C428"); //Latvia
ePingCtyCodeDict.set("430", "C430"); //Liberia
ePingCtyCodeDict.set("434", "C434"); //Libya
ePingCtyCodeDict.set("438", "C438"); //Liechtenstein
ePingCtyCodeDict.set("440", "C440"); //Lithuania
ePingCtyCodeDict.set("442", "C442"); //Luxembourg
ePingCtyCodeDict.set("446", "C446"); //Macao, China
ePingCtyCodeDict.set("450", "C450"); //Madagascar
ePingCtyCodeDict.set("454", "C454"); //Malawi
ePingCtyCodeDict.set("458", "C458"); //Malaysia
ePingCtyCodeDict.set("462", "C462"); //Maldives
ePingCtyCodeDict.set("466", "C466"); //Mali
ePingCtyCodeDict.set("470", "C470"); //Malta
ePingCtyCodeDict.set("478", "C478"); //Mauritania
ePingCtyCodeDict.set("480", "C480"); //Mauritius
ePingCtyCodeDict.set("484", "C484"); //Mexico
ePingCtyCodeDict.set("490", "C158"); //Chinese Taipei
ePingCtyCodeDict.set("492", "C492"); //Monaco
ePingCtyCodeDict.set("496", "C496"); //Mongolia
ePingCtyCodeDict.set("498", "C498"); //Moldova, Republic of
ePingCtyCodeDict.set("499", "C893"); //Montenegro
ePingCtyCodeDict.set("504", "C504"); //Morocco
ePingCtyCodeDict.set("508", "C508"); //Mozambique
ePingCtyCodeDict.set("512", "C512"); //Oman
ePingCtyCodeDict.set("516", "C516"); //Namibia
ePingCtyCodeDict.set("520", "C520"); //Nauru
ePingCtyCodeDict.set("524", "C524"); //Nepal
ePingCtyCodeDict.set("528", "C528"); //Netherlands
ePingCtyCodeDict.set("530", "C531"); //Cura�ao
ePingCtyCodeDict.set("548", "C548"); //Vanuatu
ePingCtyCodeDict.set("554", "C554"); //New Zealand
ePingCtyCodeDict.set("558", "C558"); //Nicaragua
ePingCtyCodeDict.set("562", "C562"); //Niger
ePingCtyCodeDict.set("566", "C566"); //Nigeria
ePingCtyCodeDict.set("579", "C578"); //Norway
ePingCtyCodeDict.set("583", "C583"); //Micronesia, Federated States of
ePingCtyCodeDict.set("584", "C584"); //Marshall Islands
ePingCtyCodeDict.set("585", "C585"); //Palau
ePingCtyCodeDict.set("586", "C586"); //Pakistan
ePingCtyCodeDict.set("591", "C591"); //Panama
ePingCtyCodeDict.set("598", "C598"); //Papua New Guinea
ePingCtyCodeDict.set("600", "C600"); //Paraguay
ePingCtyCodeDict.set("604", "C604"); //Peru
ePingCtyCodeDict.set("608", "C608"); //Philippines
ePingCtyCodeDict.set("616", "C616"); //Poland
ePingCtyCodeDict.set("620", "C620"); //Portugal
ePingCtyCodeDict.set("624", "C624"); //Guinea-Bissau
ePingCtyCodeDict.set("626", "C626"); //Timor-Leste
ePingCtyCodeDict.set("634", "C634"); //Qatar
ePingCtyCodeDict.set("642", "C642"); //Romania
ePingCtyCodeDict.set("643", "C643"); //Russian Federation
ePingCtyCodeDict.set("646", "C646"); //Rwanda
ePingCtyCodeDict.set("659", "C659"); //Saint Kitts and Nevis
ePingCtyCodeDict.set("662", "C662"); //Saint Lucia
ePingCtyCodeDict.set("670", "C670"); //Saint Vincent and the Grenadines
ePingCtyCodeDict.set("674", "C674"); //San Marino
ePingCtyCodeDict.set("678", "C678"); //Sao Tome and Principe
ePingCtyCodeDict.set("682", "C682"); //Saudi Arabia, Kingdom of
ePingCtyCodeDict.set("686", "C686"); //Senegal
ePingCtyCodeDict.set("690", "C690"); //Seychelles
ePingCtyCodeDict.set("694", "C694"); //Sierra Leone
ePingCtyCodeDict.set("699", "C356"); //India
ePingCtyCodeDict.set("702", "C702"); //Singapore
ePingCtyCodeDict.set("703", "C703"); //Slovak Republic
ePingCtyCodeDict.set("704", "C704"); //Viet Nam
ePingCtyCodeDict.set("705", "C705"); //Slovenia
ePingCtyCodeDict.set("706", "C706"); //Somalia
ePingCtyCodeDict.set("710", "C710"); //South Africa
ePingCtyCodeDict.set("716", "C716"); //Zimbabwe
ePingCtyCodeDict.set("724", "C724"); //Spain
ePingCtyCodeDict.set("728", "C728"); //South Sudan
ePingCtyCodeDict.set("736", "C736"); //Sudan
ePingCtyCodeDict.set("740", "C740"); //Suriname
ePingCtyCodeDict.set("748", "C748"); //Kingdom of Eswatini
ePingCtyCodeDict.set("752", "C752"); //Sweden
ePingCtyCodeDict.set("757", "C756"); //Switzerland
ePingCtyCodeDict.set("760", "C760"); //Syrian Arab Republic
ePingCtyCodeDict.set("762", "C762"); //Tajikistan
ePingCtyCodeDict.set("764", "C764"); //Thailand
ePingCtyCodeDict.set("768", "C768"); //Togo
ePingCtyCodeDict.set("776", "C776"); //Tonga
ePingCtyCodeDict.set("780", "C780"); //Trinidad and Tobago
ePingCtyCodeDict.set("784", "C784"); //United Arab Emirates
ePingCtyCodeDict.set("788", "C788"); //Tunisia
ePingCtyCodeDict.set("792", "C792"); //Turkey
ePingCtyCodeDict.set("795", "C795"); //Turkmenistan
ePingCtyCodeDict.set("798", "C798"); //Tuvalu
ePingCtyCodeDict.set("800", "C800"); //Uganda
ePingCtyCodeDict.set("804", "C804"); //Ukraine
ePingCtyCodeDict.set("807", "C807"); //North Macedonia, Republic of
ePingCtyCodeDict.set("818", "C818"); //Egypt
ePingCtyCodeDict.set("826", "C826"); //United Kingdom
ePingCtyCodeDict.set("834", "C834"); //Tanzania
ePingCtyCodeDict.set("842", "C840"); //United States of America
ePingCtyCodeDict.set("854", "C854"); //Burkina Faso
ePingCtyCodeDict.set("858", "C858"); //Uruguay
ePingCtyCodeDict.set("860", "C860"); //Uzbekistan
ePingCtyCodeDict.set("862", "C862"); //Venezuela, Bolivarian Republic of
ePingCtyCodeDict.set("882", "C882"); //Samoa
ePingCtyCodeDict.set("887", "C887"); //Yemen
ePingCtyCodeDict.set("894", "C894"); //Zambia
ePingCtyCodeDict.set("918", "U918"); //European Union

export default ePingCtyCodeDict;