const INITIAL_STATE = {
    tariff: null,
    ntm: null,
    remedies: null,
    statistics: null,
    statisticsYear: null,
    tariffAll: null,
    ntmAll: null,
    remediesAll: null,
    statisticsAll: null,
    statisticsYearAll: null,
    epmRefYear: null
}

const toMonth = (data) => {
    let m = data;
    switch(data) {
        case '01':
          m = 'Jan';
          break;
          case '02':
            m = 'Feb';
            break;
            case '03':
          m = 'Mar';
          break;
          case '04':
          m = 'Apr';
          break;
          case '05':
          m = 'May';
          break;
          case '06':
          m = 'Jun';
          break;
          case '07':
          m = 'Jul';
          break;
          case '08':
          m = 'Aug';
          break;
          case '09':
          m = 'Sep';
          break;
          case '10':
          m = 'Oct';
          break;
          case '11':
          m = 'Nov';
          break;
          case '12':
          m = 'Dec';
          break;
        default:
          // code block
      }
return m;
}

const formatDate = (data) => {
    if(data.toLowerCase().includes("mirror"))
        return data;
    else
        return toMonth(data.substring(4,6)) + ' ' + data.substring(0,4)
} 

const dataAvailability = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'GET_TARIFF': 
        //console.debug("reducertariff",action.tariff);
            return {
                ...state,
                tariff: [...action.tariff.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.country,
                                     //"countryName": record.countryName,
                                     "dataAvailable": record.year
                                    }
                        return ctr;                    
                   })],
                tariffAll: [...action.tariff.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.country,
                                 //"countryName": record.countryName,
                                 "dataAvailable": record.year
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDTARIFF':
                //console.debug("reducerFilteredtariff",action.tariff);
        return {
            ...state,
            tariff: [...action.tariff.map( function(record) {
                
                    var ctr = { 
                                "countryCode": record.countryCode,
                                 //"countryName": record.countryName,
                                 "dataAvailable": record.dataAvailable
                                }
                    return ctr;                    
               })]
        }  
        case 'GET_NTM': 
        //console.debug("reducerNTM",action.ntm);
            return {
                ...state,
                ntm: [...action.ntm.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.country,
                                    //"countryName": record.countryName,
                                    "dataAvailable": record.year
                                    }
                        return ctr;                    
                   })],
                ntmAll: [...action.ntm.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.country,
                                //"countryName": record.countryName,
                                "dataAvailable": record.year
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDNTM':
                //console.debug("reducerFilteredNTM",action.ntm);
            return {
                ...state,
                ntm: [...action.ntm.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.countryCode,
                                     //"countryName": record.countryName,
                                     "dataAvailable": record.dataAvailable
                                    }
                        return ctr;                    
                   })]
            }  
        case 'GET_REMEDIES': 
            return {
                ...state,
                remedies: [...action.remedies.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.country,
                                    //"countryName": record.countryName,
                                    "dataAvailable": record.year
                                    }
                        return ctr;                    
                   })],
                remediesAll: [...action.remedies.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.country,
                                //"countryName": record.countryName,
                                "dataAvailable": record.year
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDREMEDIES':
            return {
                ...state,
                remedies: [...action.remedies.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.countryCode,
                                     //"countryName": record.countryName,
                                     "dataAvailable": record.dataAvailable
                                    }
                        return ctr;                    
                   })]
            }  
       /* case 'GET_STATISTICS': 
            return {
                ...state,
                statistics: [...action.statistics.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.country,
                                    //"countryName": record.countryName,
                                    "dataAvailable": record.year
                                    }
                        return ctr;                    
                   })],
                statisticsAll: [...action.statistics.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.country,
                                //"countryName": record.countryName,
                                "dataAvailable": record.year
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDSTATISTICS':
            return {
                ...state,
                statistics: [...action.statistics.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.countryCode,
                                //"countryName": record.countryName,
                                "dataAvailable": record.dataAvailable
                                }
                    return ctr;                    
               })],
            }  */

            case 'GET_STATISTICS': 
            //console.debug("reducerstatistics",action.statistics);
            return {
                ...state,
                statistics: [...action.statistics.listCountryAvailability.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.countryCode,
                                    //"countryName": record.countryName,
                                    "dataAvailable": formatDate(record.latestPeriod)
                                    }
                        return ctr;                    
                   })],
                statisticsAll: [...action.statistics.listCountryAvailability.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.countryCode,
                                //"countryName": record.countryName,
                                "dataAvailable": record.latestPeriod
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDSTATISTICS':
               // console.debug("filterreducerstatistics",action.statistics);
            return {
                ...state,
                statistics: [...action.statistics.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.countryCode,
                                //"countryName": record.countryName,
                                "dataAvailable": formatDate(record.dataAvailable)
                                }
                    return ctr;                    
               })],
            }  
        
            case 'GET_STATISTICS_YEAR': 
            //console.debug("filterreducerstatisticsYear",action.statisticsYear);
            return {
                ...state,
                statisticsYear: [...action.statisticsYear.listCountryAvailability.map( function(record) {
                    
                        var ctr = { 
                                    "countryCode": record.countryCode,
                                    //"countryName": record.countryName,
                                    "dataAvailable": record.latestPeriod
                                    }
                        return ctr;                    
                   })],
                statisticsYearAll: [...action.statisticsYear.listCountryAvailability.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.countryCode,
                                //"countryName": record.countryName,
                                "dataAvailable": record.latestPeriod
                                }
                    return ctr;                    
               })]
            }  
        case 'GET_FILTEREDSTATISTICS_YEAR':
                //console.debug("filterreducerstatisticsYear",action.statisticsYear);
            return {
                ...state,
                statisticsYear: [...action.statisticsYear.map( function(record) {
                    
                    var ctr = { 
                                "countryCode": record.countryCode,
                                //"countryName": record.countryName,
                                "dataAvailable": record.dataAvailable
                                }
                    return ctr;                    
               })],
            }  
        case 'GET_PROCUREMENTMAPAVAILABILITY':
            return {
                ...state,
                procurementMapAvailabilityData: action.procurementMapAvailabilityData
            }
        case 'GET_COUNTRYKEYDATA':
            return {
                ...state,
                countryKeyData: action.countryKeyData
            }
        case 'GET_EPM_YEAR':
            return {
                ...state,
                epmRefYear: new Date(action.epmRefYear[0].value).getFullYear()
            }

        default:
            return state
    }
}

export default dataAvailability