import { dataAvailabilityConstants } from '../constants';


const filterData = (data,countryIds) => {
    let o =[];
    //console.debug('filter data',data);
    //console.debug('filter countryIds',countryIds);
    if(data == null || !Array.isArray(data))
        return o;
    data.filter(country=> 
        countryIds.some(element => {  
            if (element.value == 'ALL')
             {
                 o=data;
             }
         })
       );

    if(o.length === 0)
    {
        data.filter(country=> 
       countryIds.forEach(element => {  
           if (element.value == country.countryCode)
            {
                o.push(country);
            }
        })
      );
    }
    return o;
}

export const fetchTariff = (countryIds) => (dispatch, getState) => {

    return fetch('https://www.macmap.org/api-mat-portal/macmap-data-availability?datatype=CT')
            .then(response => response.json())
            .then(json => {
                console.log('loadingTariff',getState());                
                dispatch(getTariff(json))
            })
          .catch(err => console.error(err))

        /*  //Fake API
        
          

        let o =[];

        fakeData.filter(country=> 
            countryIds.some(element => {  
                if (element.value == 'ALL')
                 {
                     o=fakeData;
                 }
             })
           );

           if(o.length === 0)
            {
                fakeData.filter(country=> 
           countryIds.forEach(element => {  
               if (element.value == country.country)
                {
                    o.push(country);
                }
            })
          );
            }


    return dispatch(getTariff(o));
    */

}

export const getTariff = tariff => ({
    type: dataAvailabilityConstants.GET_TARIFF,
    tariff
})

export const filterTariff = (countryIds, data) => (dispatch, getState) => {
    return dispatch(getFilteredTariff(filterData(data,countryIds)));
}

export const getFilteredTariff = tariff => ({
    type: dataAvailabilityConstants.GET_FILTEREDTARIFF,
    tariff
})



export const fetchNTM = (countryIds) => (dispatch, getState) => {
    return fetch('https://www.macmap.org/api-mat-portal/macmap-data-availability?datatype=NTM')
    .then(response => response.json())
    .then(json => dispatch(getNTM(json)))
  .catch(err => console.error(err))

  /*
                    //Fake API

                    
        let o =[];

        fakeNTM.filter(country=> 
            countryIds.some(element => {  
                if (element.value == 'ALL')
                 {
                     o=fakeNTM;
                 }
             })
           );

           if(o.length === 0)
            {
                fakeNTM.filter(country=> 
           countryIds.forEach(element => {  
               if (element.value == country.country)
                {
                    o.push(country);
                }
            })
          );
            }
    return dispatch(getNTM(o));
    */
        
}

export const getNTM = ntm => ({
    type: dataAvailabilityConstants.GET_NTM,
    ntm,
})

export const filterNTM = (countryIds, data) => (dispatch, getState) => {
    return dispatch(getFilteredNTM(filterData(data,countryIds)));
}

export const getFilteredNTM = ntm => ({
    type: dataAvailabilityConstants.GET_FILTEREDNTM,
    ntm
})




export const fetchRemedies = (countryIds) => (dispatch, getState) => {   
        return fetch('https://www.macmap.org/api-mat-portal/macmap-data-availability?datatype=TRM')
        .then(response => response.json())
        .then(json => dispatch(getRemedies(json)))
      .catch(err => console.error(err))
                    /*//Fake API

                    let o =[];

                    fakeRemedy.filter(country=> 
                        countryIds.some(element => {  
                            if (element.value == 'ALL')
                             {
                                 o=fakeRemedy;
                             }
                         })
                       );
            
                       if(o.length === 0)
                        {
                            fakeRemedy.filter(country=> 
                       countryIds.forEach(element => {  
                           if (element.value == country.country)
                            {
                                o.push(country);
                            }
                        })
                      );
                        }
                return dispatch(getRemedies(o));
    */
    
        
}

export const getRemedies = remedies => ({
    type: dataAvailabilityConstants.GET_REMEDIES,
    remedies,
})

export const filterRemedies = (countryIds, data) => (dispatch, getState) => {
    return dispatch(getFilteredRemedies(filterData(data,countryIds)));
}

export const getFilteredRemedies = remedies => ({
    type: dataAvailabilityConstants.GET_FILTEREDREMEDIES,
    remedies
})


export const fetchStatistics = (countryIds) => (dispatch, getState) => {
       return fetch('https://www.trademap.org/api/TradeMapDataAvailability?period=M&language=en')
        .then(response => 
            {
               // console.debug('fetchStatistics resp',response); 
                return response.json()})
        .then(json => {
            //console.debug('fetchStatistics JSON',json); 
            dispatch(getStatistics(json))})
      .catch(err => console.error(err))
}

export const getStatistics = statistics => ({
    type: dataAvailabilityConstants.GET_STATISTICS,
    statistics,
})

export const filterStatistics = (countryIds, data) => (dispatch, getState) => {
    return dispatch(getFilteredStatistics(filterData(data,countryIds)));
}

export const getFilteredStatistics = statistics => ({
    type: dataAvailabilityConstants.GET_FILTEREDSTATISTICS,
    statistics
})

export const fetchStatisticsYear = (countryIds) => (dispatch, getState) => {
    return fetch('https://www.trademap.org/api/TradeMapDataAvailability?period=Y&language=en')
    .then(response => {
        //console.debug('fetchStatisticsYear resp',response); 
        return response.json()})
    .then(json => {
        //console.debug('fetchStatisticsYear JSON',json); 
        dispatch(getStatisticsYear(json))
    })
  .catch(err => console.error(err))
}

export const getStatisticsYear = statisticsYear => ({
type: dataAvailabilityConstants.GET_STATISTICS_YEAR,
statisticsYear,
})

export const filterStatisticsYear = (countryIds, data) => (dispatch, getState) => {
    return dispatch(getFilteredStatisticsYear(filterData(data,countryIds)));
}
export const getFilteredStatisticsYear = statisticsYear => ({
    type: dataAvailabilityConstants.GET_FILTEREDSTATISTICS_YEAR,
    statisticsYear
})


/*PM ACTIVE TENDERS FIGURE*/
export const fetchProcurementMapAvailability = (countryCd, regionCd, sectorId) => (dispatch, getState) => {//lang from embedded param not used
    const { locale } = getState().l10n;
    const api = 'https://www.trademap.org/api/TendersAwardsAvailability';
    var api_params = null;

    if (countryCd != null) {
        api_params = '?countryCd=' + countryCd + '&lang=' + locale;
    }
    else if (regionCd != null) {
        api_params = '?regionCd=' + regionCd + '&lang=' + locale;
    }
    else if (sectorId != null) {
        api_params = '?sectorId=' + sectorId + '&lang=' + locale;
    }

    return fetch(api + api_params)
        .then(response => response.json())
        .then(json => {
            return dispatch(getProcurementMapAvailabilityData(json));
        })
        .catch(err => {
            console.error(err);
        })

}

export const getProcurementMapAvailabilityData = procurementMapAvailabilityData => ({
    type: dataAvailabilityConstants.GET_PROCUREMENTMAPAVAILABILITY,
    procurementMapAvailabilityData,
})

/*COUNTRY DATA (GDP etc...)*/
export const fetchCountryKeyData = (countryCd) => (dispatch) => {
    const api = 'https://www.trademap.org/api/CountryData/GetData';
    var api_params = null;

    if (countryCd != null) {
        api_params = '?country_cd=' + countryCd;
    }

    return fetch(api + api_params)
        .then(response => response.json())
        .then(json => {
            return dispatch(getCountryKeyData(json));
        })
        .catch(err => {
            console.error(err);
        })

}

export const getCountryKeyData = countryKeyData => ({
    type: dataAvailabilityConstants.GET_COUNTRYKEYDATA,
    countryKeyData,
})

/*EPM REFERENCE YEAR*/
export const fetchEpmRefYear = () => (dispatch) => {
    //const api = 'https://exportpotential.intracen.org/params';
    const api = 'https://exportpotential.intracen.org/params';

    return fetch(api)
        .then(response => response.json())
        .then(json => {
            return dispatch(getEpmRefYear(json));
        })
        .catch(err => {
            console.error(err);
        })

}

export const getEpmRefYear = epmRefYear => ({
    type: dataAvailabilityConstants.GET_EPM_YEAR,
    epmRefYear,
})