import React, { Component, useEffect } from 'react';
import * as d3 from "d3v4";
import $ from 'jquery'
import { t } from '../../services/i18n'
import { getPossessiveName } from '../../services/i18n/util';

class BarChart extends Component {

    constructor(props) {
        super(props);
        this.myBarChart = React.createRef();

    }
    componentWillMount() {

    }

    componentDidMount() {
        const { options } = this.props;
        const { type } = this.props;
        const { region } = this.props;
        const { lang } = this.props;
        const { size } = this.props;
        //const { isload } = this.props;
        this.drawChart(options ? options : options.data, type, region, lang, size)
    }
    componentDidUpdate() {

    }

    drawChart(data, type, region, lang, size) {
        if (data.data != undefined) {
            data = data.data;
        } else {
            data = data.tradeMapPartnersData;
        }
        //GLOBAL  
        var countryList = null;
        var restofWorld = null;
        var entityLabel = null;
        var entityCode = null;
        var totalExportedValue = null;

        //TRADE PARTNERS COUNTRY VARIABLES & //TRADE PARTNERS REGION VARIABLES
        var shareValue = null;
        var maxShare = null;       

        //PRODUCT EXPORTERS VARIABLES
        var maxExportedValue = null;

        if (type == "productExporters") {
            countryList = data.countries;
            entityLabel = data.productLabel;
            totalExportedValue = data.worldExportedValue;
            maxExportedValue = countryList[0].exportedValue;

        } else if (type == "tradePartners") {
            //console.log(data.bilateral);
            countryList = data.bilateral;
            restofWorld = data.bilateral;
            entityLabel = data.countryLabel;
            entityCode = data.countryCode;
            shareValue = data.share;
            totalExportedValue = data.exportedValue;
            maxShare = Math.max(...data.bilateral.map(c => c.share));//countryList[0].share;
        //    console.log(restofWorld);
        }
        else if (type == "tradePartnersRegion") {
            countryList = data.bilateral;
            restofWorld = data.bilateral;
            entityLabel = region.regionLabel;
            entityCode = region.regionCode;
            shareValue = data.share;
            totalExportedValue = data.exportedValue;
            maxShare = Math.max(...countryList.map(c => c.share));
        }
        var formatCommaValue = d3.format(",d");
        var formatShare = d3.format(".3n");
        var margin = { top: 50, right: 30, bottom: 70, left: 90 }, width = $('.barchart').width() - margin.left - margin.right;
        var height = 350 - margin.top - margin.bottom;

        function formatValue(labelValue) {
            if (labelValue) {
                // Nine Zeroes for Billions
                return Math.abs(Number(labelValue)) >= 1.0e+9

                    ? formatCommaValue((Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2)) + " B"
                    // Six Zeroes for Millions 
                    : Math.abs(Number(labelValue)) >= 1.0e+6

                        ? formatCommaValue((Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2)) + " M"
                        // Three Zeroes for Thousands
                        : Math.abs(Number(labelValue)) >= 1.0e+3

                            ? formatCommaValue((Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2)) + " K"

                            : Math.abs(Number(labelValue));
            }

        }

        //if (size.xxs || size.xs) {
        //    height = 350 - margin.top - margin.bottom;
        //} else {
        //    height = 350 - margin.top - margin.bottom;
        //}               

        var box = d3.select("body").append("div").attr("id", "box-tradepartners")
            .attr("class", "box-tradepartners")
            .style("opacity", 0);

        var svg = d3.select(this.myBarChart.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", () => {
                if (size.xxs || size.xs) {
                    return "translate(135,10)";
                } else {
                    return "translate(135,30)";
                }
            });
        
        switch (type) {
            case 'tradePartners':
                svg
                    .append("text")
                    .attr("x", () => {
                        if (size.xxs || size.xs) {
                            return -80;
                        } else {
                            return width / 4;
                        }
                        
                    })
                    .attr("y", 2)
                    .style("font-size", "14px")
                    .style("fill", "#002b54")
                    .style("font-family", "Public Sans")
                    .text(getPossessiveName(entityLabel) + t('figure_title_traderpartners_end'));
                break;
            case 'tradePartnersRegion':
                svg
                    .append("text")
                    .attr("x", () => {
                        if (size.xxs || size.xs) {
                            return -80;
                        } else {
                            return width / 4;
                        }

                    })
                    .attr("y", 2)
                    .style("font-size", "14px")
                    .style("fill", "#002b54")
                    .style("font-family", "Public Sans")
                    .text(getPossessiveName(entityLabel) + t('figure_title_traderpartners_end'));
                break;
            case 'productExporters':
                svg
                    .append("text")
                    .attr("x", () => {
                        if (size.xxs || size.xs) {
                            return -80;
                        } else {
                            return 0 /*width / 4*/;
                        }

                    })
                    .attr("y", 2)
                    .style("font-size", "14px")
                    .style("fill", "#002b54")
                    .style("font-family", "Public Sans")
                    .text(t('figure_title_productexporters_start') + entityLabel.substring(0, 50) + ' (' + t('figure_title_productexporters_end') + ')');
                break;
        }
        

        // Add X axis
        var x = null;
        if (type == "tradePartners" || type == "tradePartnersRegion") {
            x = d3.scaleLinear()
                .domain([0, Math.ceil(maxShare)])
                .range([0, width - margin.right]);

        }
        else if (type == "productExporters") {
            x = d3.scaleLinear()
                .domain([0, Math.ceil(maxExportedValue) / 2, Math.ceil(maxExportedValue)])
                .range([0, (width - margin.right) / 2, width - margin.right]);

        }
       
        svg
            .append("g")
            .attr("transform", "translate(0," + 230 + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .style("text-anchor", "end")
            .text(function (d) {
                switch (type) {
                    case 'tradePartners':
                        return d;
                        break;
                    case 'tradePartnersRegion':
                        return d;
                        break;
                    case 'productExporters':
                        if (d !== 0) {
                            var val = d / 1000000;
                            return val.toString();
                        } else {
                            return d.toString();
                        }
                        break;
                }
                
            });
            
            // Y axis
        var y = d3.scaleBand()
            .range([20, 230])
            .domain(countryList.map(function (d) {
                switch(type){
                    case 'tradePartners':
                        return d.partnerLabel;
                        break;
                    case 'tradePartnersRegion':
                        return d.partnerLabel;
                        break;
                    case 'productExporters':
                        return d.countryLabel;
                        break;
                }
            }))
            .padding(.1);

        svg.append("g").attr("class", "axis")
            .call(d3.axisLeft(y));

            //Bars
        svg.selectAll("myRect")
            .data(countryList)
            .enter()
            .append("rect")
            .attr("x", x(0))
            .attr("y", function (d, i) {
                switch (type) {
                    case 'tradePartners':
                        return y(d.partnerLabel);
                        break;
                    case 'tradePartnersRegion':
                        return y(d.partnerLabel);
                        break;
                    case 'productExporters':
                        return y(d.countryLabel);
                        break;
                }
                
            })
            .attr("width", function (d) {
                switch (type) {
                    case 'tradePartners':
                        return x(d.share);
                        break;
                    case 'tradePartnersRegion':
                        return x(d.share);
                        break;
                    case 'productExporters':
                        return x(d.exportedValue);
                        break;
                }
                
            })
            .attr("height", y.bandwidth())
            .attr("fill", "#002b54")
            .on("mouseover", function (d) {
                d3.select(this).transition().duration('50').style('fill', '#009de8');
                box.transition()
                    .duration(200)
                    .style("opacity", 1);
                if (window.innerWidth >= 380) {
                    box.html(divMouseOverContent(d))
                        .style("top", d3.event.pageY + 30 + "px")
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("display", "inline-block");
                }
            })
            .on("mouseout", function (d) {
                d3.select(this).transition().duration('50').style('fill', '#002b54');
                box.transition()
                    .duration(200)
                    .style("opacity", 0);
            });

        //Figure Exported Value Title
        svg 
            .append("text")
            .attr("x", () => {

                if (size.xxs || size.xs) {
                    return -100;
                } else if (size.sm)
                {
                    return -60;
                } else {
                    return 10;
                }

            })
            .attr("y", () => {
                if (size.xxs || size.xs) {
                    return height + 45;
                } else {
                    return height + 50;
                }
            })
            .style("font-size", "14px")
            .style("fill", "#002b54")
            .style("font-family", "Public Sans")
            .text(() => {
                switch (type) {
                    case 'tradePartners':
                        return t('chart_exported_value') + ' (USD)'
                        //if (lang != 'en') {
                        //    return t('chart_exported_value') 
                        //} else {
                        //    return t('chart_exported_value') + t('chart_thousand') + " USD"
                        //}                       
                        break;
                    case 'tradePartnersRegion':
                        return t('figure_title_traderpartners_region_value') + ' (USD)'
                        break;
                    case 'productExporters':
                        return t('figure_title_traderpartners_value') + ' (USD)'
                        break;
                }
                              
            }
                
            );
        //Figure Exported Value
        svg
            .append("text")
            .attr("x", () => {

                if (size.xxs || size.xs) {
                    return -100;
                } else if (size.sm) {
                    return -60;
                } else {
                    return 10;
                }
            })
            .attr("y", () => {
                if (size.xxs || size.xs) {
                    return height + 65
                } else {
                    return height + 80
                }
                
            })
            .style("font-size", () => {

                if (size.xxs || size.xs) {
                    return "16px";
                } else {
                    return "20px"
                }

            })
            .style("font-weight", "bold")
            .style("fill", "#b8014c")
            .style("font-family", "Public Sans")
            .text(function () {
                switch (type) {
                    case 'tradePartners':
                        return formatValue(totalExportedValue * 1000)
                        break;
                    case 'tradePartnersRegion':
                        return formatValue(totalExportedValue * 1000)
                        break;
                    case 'productExporters':
                        return formatValue(totalExportedValue * 1000)
                        break;
                }
                
            });

        if (type == 'tradePartners') {
            //Figures Share Title
            svg
                .append("text")
                .attr("x", () => {                   

                    if (size.xxs || size.xs) {
                        return -100;
                    } else if (size.sm) {
                        return 200;
                    }else {
                        return 280
                    }

                })
                .attr("y", () => {
                    if (size.xxs || size.xs) {
                        return height + 90
                    } else {
                        return height + 50
                    }

                })
                .style("font-size", "14px")
                .style("fill", "#002b54")
                .style("font-family", "Public Sans")
                .text(t('figure_title_traderpartners_proportion'));

            //Figures Share Value
            svg
                .append("text")
                .attr("x", () => {

                    if (size.xxs || size.xs) {
                        return -100;
                    } else if (size.sm) {
                        return 200;
                    } else {
                        return 280
                    }
                })
                .attr("y", () => {
                    if (size.xxs || size.xs) {
                        return height + 105
                    } else {
                        return height + 80
                    }

                })
                .style("font-size", () => {

                    if (size.xxs || size.xs) {
                        return "16px";
                    } else {
                        return "20px"
                    }
                })
                .style("font-weight", "bold")
                .style("fill", "#b8014c")
                .style("font-family", "Public Sans")
                .text(function () { return shareValue + " %" });

            
        }
        var divMouseOverContent = function (d) {
            var content;
            switch (type) {
                case 'tradePartners':
                    if (lang != 'en') {                        
                        content = "<h6>" + d.partnerLabel + "</h6>" +
                            "<h6>" + t('chart_share_in') + t('chart_exports') + entityLabel + ": " + formatShare(d.share) + "%" + "</h6>"
                    } else {
                        content = "<h6>" + d.partnerLabel + "</h6>" +
                            "<h6>" + t('chart_share_in') + entityLabel + t('chart_exports') + formatShare(d.share) + "%" + "</h6>"
                    }
                    
                    break;
                case 'tradePartnersRegion':
                    if (lang != 'en') {
                        content = "<h6>" + d.partnerLabel + "</h6>" +
                            "<h6>" + t('chart_share_in') + t('chart_exports') + entityLabel + ": " + formatShare(d.share) + "%" + "</h6>"
                    } else {
                        content = "<h6>" + d.partnerLabel + "</h6>" +
                            "<h6>" + t('chart_share_in') + entityLabel + t('chart_exports') + formatShare(d.share) + "%" + "</h6>"
                    }
                    
                    break;
                case 'productExporters':
                    content = "<h6>" + d.countryLabel + "</h6>" +
                        "<h6>" + t('chart_exported_value') + formatValue(d.exportedValue) + " USD </h6>"
                    break;
            }


            return content;

        }
        
            
    }


    render() {
        return <div className="barchart">
            <div id="barchart-chart" ref={this.myBarChart}></div>
        </div>
    }
}

export default BarChart

