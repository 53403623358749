import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Switch,
    //BrowserRouter as Router //Router v4
    Router
    
} from 'react-router-dom'

import {alertConstants} from './constants/alert.constants';
import routes from './routes'
import protectedroutes from './protectedroutes'
import Localizer from './containers/Localizer'

import Sponsors from "./components/Sponsors";
import AppFooter from './components/AppFooter'
import AppHeader from './components/AppHeader'

import { history } from './helpers';

import { alertActions } from './actions';
import { PrivateRoute } from './components/PrivateRoute';
import GoogleAnalytics from './components/GoogleAnalytics';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';


// setup fake backend
//import { configureFakeBackend } from './helpers';
//configureFakeBackend();

//const App = props => (
class App extends React.Component {
    constructor(props) {
        super(props);
    
        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
      
    }


      
    notify = (message,type) => {  
        switch(type) { 
            case 'alert-success': { 
                toast.success(message, {
                    position: toast.POSITION.TOP_LEFT
                  });
               break; 
            } 
            case 'alert-danger': { 
                toast.error(message, {
                    position: toast.POSITION.TOP_LEFT
                  });
               break; 
            } 
            default: { 
                toast.info(message, {
                    position: toast.POSITION.TOP_LEFT
                  });
               break; 
            } 
        }
   
       
    };

render() {
    const { alert } = this.props;

    return (
        
        <div style={{paddingTop: "0px"}}>
    
    
              {/*alert.message &&
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
              */}

            {alert.message && this.notify(alert.message,alert.type)}
            <ToastContainer autoClose={8000} />

            <Router history={history}>
                <GoogleAnalytics />
                <Localizer>
                 
                    {
                        this.props.uiTranslationsLoaded &&
                        <div>
                            <AppHeader locale={this.props}/>
                            
                            {/*<AppNavbar />*/}
                            <Switch>
                                {/*<PrivateRoute key='intranet' exact path="/intranet" component={PrivatePage} />*/}
                                {
                                    protectedroutes.map((protectedroute, index) => (
                                        <PrivateRoute
                                            key={index}
                                            path={protectedroute.path}
                                            exact={protectedroute.exact}
                                            component={protectedroute.component}
                                        />
                                    ))
                                }
                                       
                                {
                                    routes.map((route, index) => (
                                
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))
                                }
                                
                                           
                            </Switch>
                            <Sponsors/>
                            <AppFooter/>
                        </div>
                    }
                </Localizer>
 
            </Router>
        </div>
    );}
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
        uiTranslationsLoaded: state.l10n.uiTranslationsLoaded
    };
}

export default connect(mapStateToProps)(App)
