import { chartConstants } from '../constants';
import decryptData from "../helpers/decryption";
/*let myHeaders = new Headers();
myHeaders.append('x-API-key', 'mat-portal-test-key');
myHeaders.append('Accept-Language', 'en-US');

const datas = JSON.stringify(
    {
        user: "username",
        pwd: "password"
    }
);
headers.append('Content-Type', 'text/plain');


var miInit = { 
               headers: myHeaders,
               };
*/
/*let myHeaders = new Headers({
                'Content-Type': 'text/plain',
                'X-My-Custom-Header': 'CustomValue'
            });*/
            

export const fetchMacMapChart = (countryId) => dispatch => {
    //const currentLocale = getLocaleFromPath(getState().l)
    return fetch('https://www.macmap.org/api-mat-portal/macmap-chart-data?country=' + countryId/*,{
                method: 'GET',
                mode: 'cors',
                cache: 'default' ,
                headers: new Headers({
                    'x-API-key': 'mat-portal-test-key',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en-US',                    
                    'Access-Control-Allow-Methods':'GET'
                })
    }*/)
    .then(function(response) {
        //var contentType = response.headers.get("content-type");
        //if(contentType && contentType.includes("application/json")) {
          return response.json();
        //}
        //throw new TypeError("Oops, we haven't got JSON!");
      })
      .then(function(json) {
          dispatch(getMacMapChartData(json)) 
          dispatch(getMacMapYearChartData(json))
        })
      .catch(function(error) { console.log(error); /* this line can also throw, e.g. when console = {} */ })
      .finally(function() {/*isLoading = false; */});
    
}

export const getMacMapChartData = macMapChartData => ({
    type: chartConstants.GET_MACMAPCHARTDATA,
    macMapChartData,
})
export const getMacMapYearChartData = macMapYearChartData => ({
    type: chartConstants.GET_MACMAPYEARCHARTDATA,
    macMapYearChartData,
})

export const fetchTradeMapChart = (countryId) => (dispatch, getState) => {
    const { locale }  = getState().l10n;
    
    return fetch('https://www.trademap.org/api/TradeDataCountry?country=' + countryId + '&language='+ locale)
            .then(response => response.json())
            .then(json => dispatch(getTradeMapChartData(json)))
          .catch(err => {console.error(err);
            dispatch(getTradeMapChartData('{"bilateral":[]}'));})
            
        }

export const getTradeMapChartData = tradeMapChartData => ({
    type: chartConstants.GET_TRADEMAPCHARTDATA,
    tradeMapChartData,
})

export const fetchEpmChart = (countryId) => (dispatch, getState) => {
    const { locale }  = getState().l10n;
    
    return fetch('https://exportpotential.intracen.org/api/'+ locale +'/epis/products/from/i/' + countryId + '/to/w/all/what/k/all')
        .then(response => response.text())
        .then(json => {
          const key = '<,+-:/;=)./:;,0*(\'-/9,))<-0,;+=)';

            const decryptedData = decryptData(json, key);

          return dispatch(getEpmChartData(decryptedData));
        })
        .catch(err => console.error(err))
}

//export const fetchEpmChart = (countryId) => (dispatch, getState) => {
//  const {locale} = getState().l10n;
//    return fetch('https://exportpotential.intracen.org/api/' + locale + '/epis/products/from/i/' + countryId + '/to/w/all/what/k/all')
//    .then(response => response.text())
//    .then(json => {
//      const key = '<,+-:/;=)./:;,0*(\'-/9,))<-0,;+=)';

//        const decryptedData = decryptData(json, key);

//      return dispatch(getEpmChartData(decryptedData));
//    })
//    .catch(err => console.error(err))
//}

export const getEpmChartData = epmChartData => ({
    type: chartConstants.GET_EPMCHARTDATA,
    epmChartData,
})

/*---ADDITIONAL EMBEDDED CHARTS---*/

/*TM TREEMAP H2*/
export const fetchTradeMapExportsChart = (countryId, regionCd) => (dispatch, getState) => {
    const { locale } = getState().l10n;
    const api = "https://www.trademap.org/api/Dashboard?chart=treemap";
    var api_params = null;

    if (countryId != null) {
        api_params = '&countryCd=' + countryId + '&lang=' + locale;
    }
    else if (regionCd != null) {
        api_params = '&countryGroupCd=' + regionCd + '&lang=' + locale;
    }

    return fetch(api + api_params)
        .then(response => response.text())
        .then(json => {
            return dispatch(getTradeMapExportsChartData(json));
        })
        .catch(err => console.error(err))
}
export const getTradeMapExportsChartData = tradeMapExportsData => ({
    type: chartConstants.GET_TRADEMAPEXPORTS,
    tradeMapExportsData,
})

/*TM TRADE PARTNERS BARCHART*/
export const fetchTradeMapPartnersChart = (countryCd, regionCd) => (dispatch, getState) => {//lang from embedded param not used
    const { locale } = getState().l10n;

    var url = '';

    if (countryCd !== null) {
        url = 'https://www.trademap.org/api/TradeDataCountry?country=' + countryCd + '&language=' + locale;
    } else if (regionCd) {
        url = 'https://www.trademap.org/api/TradeDataRegion?regionCd=' + regionCd + '&lang=' + locale;
        //url = 'http://z2g4-10724:60000/TradeDataRegion?regionCd=' + regionCd + '&lang=' + locale;
    }

    return fetch(url)
        .then(response => response.json())
        .then(json => {
            return dispatch(getTradeMapPartnersChartData(json));
            })
        .catch(err => {
            console.error(err);
            if (countryCd !== null) {
                dispatch(getTradeMapPartnersChartData('{"bilateral":[]}'));
            }
        })

}

export const getTradeMapPartnersChartData = tradeMapPartnersData => ({
    type: chartConstants.GET_TRADEMAPPARTNERS,
    tradeMapPartnersData,
})

/*TM PRODUCT TOP EXPORTERS BARCHART*/
export const fetchTradeMapProductExportersChart = (productCd, lang) => (dispatch, getState) => {//lang from embedded param not used
    console.log(productCd, lang);

    return fetch('https://www.trademap.org/api/TradeMapData/GetProductExporters?productCd=' + productCd + '&lang=' + lang)
        .then(response => response.json())
        .then(json => {
            return dispatch(getTradeMapProductExportersChartData(json));
        })
        .catch(err => {
            console.error(err);
            dispatch(getTradeMapProductExportersChartData('{"bilateral":[]}'));
        })

}

export const getTradeMapProductExportersChartData = tradeMapProductExportersData => ({
    type: chartConstants.GET_TRADEMAPPRODUCTEXPORTERS,
    tradeMapProductExportersData,
})

/*TM PRODUCTS EXPORTED BARCHART*/
export const fetchTradeMapProductsExportedChart = (productCd, lang) => (dispatch, getState) => {//lang from embedded param not used
    console.log(productCd, lang);
    return fetch('https://www.trademap.org/api/TradeMapData/GetProductsExported?productCd=' + productCd + '&lang=' + lang)
        .then(response => response.json())
        .then(json => {
            return dispatch(getTradeMapProductsExportedChartData(json));
        })
        .catch(err => {
            console.error(err);
            dispatch(getTradeMapProductsExportedChartData('{"bilateral":[]}'));
        })

}

export const getTradeMapProductsExportedChartData = tradeMapProductsExportedData => ({
    type: chartConstants.GET_TRADEMAPPRODUCTSEXPORTED,
    tradeMapProductsExportedData,
})


