import 'babel-polyfill';
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import store from './services/store'


import 'bootstrap/dist/css/bootstrap.css'
import './styles/App.css'
import './styles/Collapsible.css'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import 'font-awesome/css/font-awesome.min.css';

import ReactGA from "react-ga4";

ReactGA.initialize("G-GQ4KK2TLGK");

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)
