import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import { t } from '../../services/i18n';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Popup from 'reactjs-popup';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addHighchartsMore from 'highcharts/highcharts-more';
import addExportModule from 'highcharts/modules/exporting';
import addTreemapModule from 'highcharts/modules/treemap';
import { MoneyFormat } from '../../services/util';
import {
  fetchCountries,
  fetchEpmChart,
  fetchMacMapChart,
  fetchTradeMapExportsChart,
  fetchTradeMapPartnersChart,
  fetchROOAggregated,
  fetchInvestmentMapAggregated,
  fetchProcurementMapAvailability,
  fetchCountryKeyData,
  fetchEpmRefYear,
} from '../../actions';
import TreemapChart from '../../constants/Embedded/treemap';
import BarChart from '../../constants/Embedded/barchart';

addTreemapModule(Highcharts);
addHighchartsMore(Highcharts);
addExportModule(Highcharts);

class CountryProfileEmbedded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: null,
      size: { xxs: false, xs: false, sm: false, md: false, lg: false, xl: false },
      width: 0,
      height: 0,
      pageLoaded: false,
      mobileDropDownSelected: t('tab_title_tradepartners'),
      tabSelected: 'tm',
      selectedCountry: null,
      tmChartLoadTrigger: true,
      ceChartLoadTrigger: false,
    };
    this.country = null;

    this.parameters = {
      countryCode: null,
      lang: null,
    };
    this.epmChartIframe = null;
  }
  componentWillMount() {
    this.parameters.countryCode = this.getParamValue('countryCode') ? this.getParamValue('countryCode') : null;
    if (this.parameters.countryCode == '356') {
      this.parameters.countryCode = '699';
    }
    if (this.parameters.countryCode == '711') {
      this.parameters.countryCode = '710';
    }
    this.parameters.lang = this.props.uiTranslationsLoaded.locale;
    //http://epm.tmitest.ovh/ -- DEV
    //https://exportpotential.intracen.org/ --PROD
    this.epmChartIframe =
      'https://exportpotential.intracen.org/' +
      this.parameters.lang +
      '/products/tree-map?fromMarker=i&exporter=' +
      this.parameters.countryCode +
      '&toMarker=w&market=w&whatMarker=k&embed=true&embedmode=chartonly&chartHeight=400';
  }
  componentDidMount(props) {
    const tabs = $('#Tabs');
    window.addEventListener('resize', this.updateDimensions);
    this.setSize(this.state.size);
    this.state.pageLoaded = true;
    this.props.fetchCountries().then(() => {
      this.country = this.props.countries.filter((c) => c.value === this.parameters.countryCode);
      this.loadChartsData(this.country[0]);
    });
  }
  componentDidUpdate() {}

  getChart = (chart) => {
    //	this.chart = chart;
    chart.plotHeight = 732;
    chart.plotSizeY = 732;
    chart.chartHeight = 800;
    chart.plotBox.height = 732;
    //chart.reflow();
  };
  getChartMini = (chart) => {
    //	this.chart = chart;
    chart.plotHeight = 332;
    chart.plotSizeY = 332;
    chart.chartHeight = 400;
    chart.plotBox.height = 332;

    chart.plotWidth = 300;
    chart.plotSizeX = 300;
    chart.chartWidth = 340;
    chart.plotBox.width = 300;

    chart.reflow();
  };
  setHeight() {
    if (this.state.size.xxs) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2055);
            $('#country-profile-embedded-content').height(821);
          } else {
            $('.section').height(3098);
            $('#country-profile-embedded-content').height(1866);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2126);
            $('#country-profile-embedded-content').height(875);
          } else {
            $('.section').height(3454);
            $('#country-profile-embedded-content').height(2206);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(896);
          } else {
            $('.section').height(3465);
            $('#country-profile-embedded-content').height(2217);
          }

          break;
      }
    }
    if (this.state.size.xs) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2055);
            $('#country-profile-embedded-content').height(821);
          } else {
            $('.section').height(2920);
            $('#country-profile-embedded-content').height(1681);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2126);
            $('#country-profile-embedded-content').height(875);
          } else {
            $('.section').height(3454);
            $('#country-profile-embedded-content').height(2206);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(896);
          } else {
            $('.section').height(3439);
            $('#country-profile-embedded-content').height(2189);
          }

          break;
      }
    }
    if (this.state.size.sm) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1608);
            $('#country-profile-embedded-content').height(765);
          } else {
            $('.section').height(2320);
            $('#country-profile-embedded-content').height(1477);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1659);
            $('#country-profile-embedded-content').height(792);
          } else {
            $('.section').height(2507);
            $('#country-profile-embedded-content').height(1640);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1659);
            $('#country-profile-embedded-content').height(792);
          } else {
            $('.section').height(2508);
            $('#country-profile-embedded-content').height(1641);
          }
          break;
      }
    }
    if (this.state.size.md) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1435);
            $('#country-profile-embedded-content').height(740);
          } else {
            $('.section').height(2020);
            $('#country-profile-embedded-content').height(1325);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1461);
            $('#country-profile-embedded-content').height(766);
          } else {
            $('.section').height(2155);
            $('#country-profile-embedded-content').height(1460);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1482);
            $('#country-profile-embedded-content').height(766);
          } else {
            $('.section').height(2212);
            $('#country-profile-embedded-content').height(1486);
          }
          break;
      }
    }
    if (this.state.size.lg) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1186);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1490);
            $('#country-profile-embedded-content').height(786);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1186);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1586);
            $('#country-profile-embedded-content').height(889);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1636);
            $('#country-profile-embedded-content').height(939);
          }
          break;
      }
    }
    if (this.state.size.xl) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1341);
            $('#country-profile-embedded-content').height(635);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1463);
            $('#country-profile-embedded-content').height(764);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(482);
          } else {
            $('.section').height(1500);
            $('#country-profile-embedded-content').height(803);
          }
          break;
      }
    }

    //iPhone 5
    if (window.innerWidth <= 320) {
      switch (this.parameters.lang) {
        case 'en':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2055);
            $('#country-profile-embedded-content').height(821);
          } else {
            $('.section').height(3282);
            $('#country-profile-embedded-content').height(2050);
          }
          break;
        case 'fr':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(2126);
            $('#country-profile-embedded-content').height(875);
          } else {
            $('.section').height(3454);
            $('#country-profile-embedded-content').height(2206);
          }
          break;
        case 'es':
          if (this.state.mobileDropDownSelected != t('tab_title_otherdata') || this.state.tabSelected != 'others') {
            $('.section').height(1181);
            $('#country-profile-embedded-content').height(896);
          } else {
            $('.section').height(3465);
            $('#country-profile-embedded-content').height(2217);
          }

          break;
      }
    }
  }
  setSize(s) {
    var width = window.innerWidth;
    var tabsElement = $('#Tabs');
    var dropDownElement = $('#DropDown');

    if (width <= 425) {
      s.xxs = true;
      this.state.size.xs = false;
      this.state.size.sm = false;
      this.state.size.md = false;
      this.state.size.lg = false;
      this.state.size.xl = false;
      if (!this.state.pageLoaded) {
        tabsElement.hide();
        dropDownElement.show();
      }
      if (tabsElement[0].clientHeight > 70) {
        //console.log(tabsElement[0].clientHeight);
        tabsElement.hide();
        dropDownElement.show();
      }
      this.setHeight();
    }
    if (width >= 426 && width <= 576) {
      s.xs = true;
      this.state.size.xxs = false;
      this.state.size.sm = false;
      this.state.size.md = false;
      this.state.size.lg = false;
      this.state.size.xl = false;
      if (!this.state.pageLoaded) {
        tabsElement.hide();
        dropDownElement.show();
      }
      if (tabsElement[0].clientHeight > 70) {
        //console.log(tabsElement[0].clientHeight);
        tabsElement.hide();
        dropDownElement.show();
      }
      this.setHeight();
    }
    if (width >= 577 && width <= 767) {
      s.sm = true;
      this.state.size.xxs = false;
      this.state.size.xs = false;
      this.state.size.md = false;
      this.state.size.lg = false;
      this.state.size.xl = false;
      if (!this.state.pageLoaded) {
        tabsElement.hide();
        dropDownElement.show();
      }
      if (tabsElement[0].clientHeight > 70) {
        //console.log(tabsElement[0].clientHeight);
        tabsElement.hide();
        dropDownElement.show();
      }
      this.setHeight();
    }
    if (width >= 768 && width <= 991) {
      s.md = true;
      this.state.size.xxs = false;
      this.state.size.xs = false;
      this.state.size.sm = false;
      this.state.size.lg = false;
      this.state.size.xl = false;
      if (!this.state.pageLoaded) {
        tabsElement.show();
        dropDownElement.hide();
      }
      if (tabsElement[0].clientHeight > 70) {
        //console.log(tabsElement[0].clientHeight);
        tabsElement.hide();
        dropDownElement.show();
      }
      this.setHeight();
    }
    if (width >= 992 && width <= 1199) {
      s.lg = true;
      this.state.size.xxs = false;
      this.state.size.xs = false;
      this.state.size.sm = false;
      this.state.size.md = false;
      this.state.size.xl = false;
      if (!this.state.pageLoaded) {
        tabsElement.show();
        dropDownElement.hide();
      }
      if (this.parameters.lang == 'fr' || this.parameters.lang == 'es') {
        if (tabsElement[0].clientHeight > 70) {
          //console.log(tabsElement[0].clientHeight);
          tabsElement.hide();
          dropDownElement.show();
        }
      }
      this.setHeight();
    }
    if (width >= 1200) {
      s.xl = true;
      this.state.size.xxs = false;
      this.state.size.xs = false;
      this.state.size.sm = false;
      this.state.size.md = false;
      this.state.size.lg = false;
      if (!this.state.pageLoaded) {
        tabsElement.show();
        dropDownElement.hide();
      }
      if (this.parameters.lang == 'fr' || this.parameters.lang == 'es') {
        if (tabsElement[0].clientHeight > 70) {
          //console.log(tabsElement[0].clientHeight);
          tabsElement.hide();
          dropDownElement.show();
        }
      }
      this.setHeight();
    }

    return s;
  }
  removeCharts = () => {
    this.setState({ tmChartLoadTrigger: false });
    this.setState({ ceChartLoadTrigger: false });
  };
  loadCharts = () => {
    if (this.state.tabSelected == 'tm' || this.state.mobileDropDownSelected == t('tab_title_tradepartners')) {
      this.setState({ tmChartLoadTrigger: true });
    }
    if (this.state.tabSelected == 'ce' || this.state.mobileDropDownSelected == t('tab_title_currentexports')) {
      this.setState({ ceChartLoadTrigger: true });
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    var tabsElement = $('#Tabs');
    var dropDownElement = $('#DropDown');

    if (tabsElement[0].clientHeight > 70) {
      tabsElement.hide();
      dropDownElement.show();
    }
    if (this.state.width <= 425 && this.state.size.xxs === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.hide();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.show();
      /*console.log(this.state.size);*/
    }
    if (this.state.width >= 426 && this.state.width < 577 && this.state.size.xs === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.hide();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.show();
      /*console.log(this.state.size);*/
    }
    if (this.state.width >= 576 && this.state.width < 768 && this.state.size.sm === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.hide();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.show();
      /*console.log(this.state.size);*/
    }
    if (this.state.width >= 768 && this.state.width < 992 && this.state.size.md === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.show();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.hide();
      /*console.log(this.state.size);*/
    }
    if (this.state.width >= 992 && this.state.width < 1200 && this.state.size.lg === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.show();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.hide();
      /*console.log(this.state.size);*/
    }
    if (this.state.width >= 1200 && this.state.size.xl === false) {
      this.removeCharts();
      this.state.size = this.setSize(this.state.size);
      tabsElement.show();

      this.loadCharts();
      //API CALL to be grouped into dedicated function
      //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
      //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
      dropDownElement.hide();
      /*console.log(this.state.size);*/
    }
  };

  handleTabs = (e) => {
    switch (e) {
      case 'tm':
        /*console.log(this.state.tmChartLoadTrigger)*/

        this.setState({ tmChartLoadTrigger: true });
        this.setState({ ceChartLoadTrigger: false });
        //this.setState({
        //    mobileDropDownSelected: t('tab_title_tradepartners'),
        //    tabSelected: 'tm',
        //});
        this.state.mobileDropDownSelected = t('tab_title_tradepartners');
        this.state.tabSelected = 'tm';
        this.setHeight();
        this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
        /*console.log(this.state.tmChartLoadTrigger)*/

        break;
      case 'ce':
        /*console.log(this.state.ceChartLoadTrigger)*/
        this.setState({ ceChartLoadTrigger: true });
        this.setState({ tmChartLoadTrigger: false });
        //this.setState({
        //    mobileDropDownSelected: t('tab_title_currentexports'),
        //    tabSelected: 'ce',
        //});
        this.state.mobileDropDownSelected = t('tab_title_currentexports');
        this.state.tabSelected = 'ce';
        this.setHeight();
        this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
        /*console.log(this.state.ceChartLoadTrigger)*/
        break;
      case 'epm':
        //this.setState({
        //    mobileDropDownSelected: t('tab_title_exportpotential'),
        //    tabSelected: 'epm',
        //});
        this.state.mobileDropDownSelected = t('tab_title_exportpotential');
        this.state.tabSelected = 'epm';
        this.setHeight();
        break;
      case 'macmap':
        //this.setState({
        //    mobileDropDownSelected: t('tab_title_marketaccess'),
        //    tabSelected: 'macmap',
        //});
        this.state.mobileDropDownSelected = t('tab_title_marketaccess');
        this.state.tabSelected = 'macmap';
        this.setHeight();
        break;
      case 'others':
        //this.setState({
        //    mobileDropDownSelected: t('tab_title_otherdata'),
        //    tabSelected: 'others',
        //});
        this.state.mobileDropDownSelected = t('tab_title_otherdata');
        this.state.tabSelected = 'others';
        this.setHeight();
        break;
    }
  };
  handleSelect = (e) => {
    $('#tm').removeClass('active show');
    $('#ce').removeClass('active show');
    $('#epm').removeClass('active show');
    $('#macmap').removeClass('active show');
    $('#others').removeClass('active show');

    switch (e) {
      case 'tm':
        this.setState({ tmChartLoadTrigger: true });
        this.setState({ ceChartLoadTrigger: false });
        this.setState({
          mobileDropDownSelected: t('tab_title_tradepartners'),
          tabSelected: 'tm',
        });
        this.state.mobileDropDownSelected = t('tab_title_tradepartners');
        this.state.tabSelected = 'tm';
        this.setHeight();
        $('#' + e).removeClass('fade');
        this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
        $('#' + e).addClass('active show');
        break;
      case 'ce':
        this.setState({ ceChartLoadTrigger: true });
        this.setState({ tmChartLoadTrigger: false });
        this.setState({
          mobileDropDownSelected: t('tab_title_currentexports'),
          tabSelected: 'ce',
        });
        this.state.mobileDropDownSelected = t('tab_title_currentexports');
        this.state.tabSelected = 'ce';
        this.setHeight();
        $('#' + e).removeClass('fade');
        this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
        $('#' + e).addClass('active show');
        break;
      case 'epm':
        this.setState({
          mobileDropDownSelected: t('tab_title_exportpotential'),
          tabSelected: 'epm',
        });
        this.state.mobileDropDownSelected = t('tab_title_exportpotential');
        this.state.tabSelected = 'epm';
        this.setHeight();
        $('#' + e).removeClass('fade');
        $('#' + e).addClass('active show');
        break;
      case 'macmap':
        this.setState({
          mobileDropDownSelected: t('tab_title_marketaccess'),
          tabSelected: 'macmap',
        });
        this.state.mobileDropDownSelected = t('tab_title_marketaccess');
        this.state.tabSelected = 'macmap';
        this.setHeight();
        $('#' + e).removeClass('fade');
        $('#' + e).addClass('active show');
        break;
      case 'others':
        this.setState({
          mobileDropDownSelected: t('tab_title_otherdata'),
          tabSelected: 'others',
        });
        this.state.mobileDropDownSelected = t('tab_title_otherdata');
        this.state.tabSelected = 'others';
        this.setHeight();
        $('#' + e).removeClass('fade');
        $('#' + e).addClass('active show');
        break;
    }
  };
  loadChartsData(selectedCountry) {
    if (selectedCountry) {
      this.setState({ selectedCountry: selectedCountry });
      this.props.fetchCountryKeyData(selectedCountry.value);
      this.props.fetchMacMapChart(selectedCountry.value);
      this.props.fetchTradeMapPartnersChart(selectedCountry.value);
      this.props.fetchEpmChart(selectedCountry.value);
      this.props.fetchEpmRefYear();
      this.props.fetchROOAggregated(selectedCountry.value);
      this.props.fetchInvestmentMapAggregated(selectedCountry.value);
      this.props.fetchProcurementMapAvailability(selectedCountry.value);
    }
  }
  getParamValue = (paramName) => {
    var url = window.location.search.substring(1);
    var qArray = url.split('&');
    for (var i = 0; i < qArray.length; i++) {
      var pArr = qArray[i].split('=');
      if (pArr[0] === paramName) return pArr[1];
    }
  };

  formatValueGDP(labelValue) {
    if (labelValue) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e6 + ' B'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e3 + ' M'
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0
        ? Math.abs(Number(labelValue)) / 1.0 + ' K'
        : Math.abs(Number(labelValue));
    }
  }

  formatValue(labelValue) {
    if (labelValue) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + ' B'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + ' M'
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + ' K'
        : Math.abs(Number(labelValue));
    }
  }

  render() {
    const TradeMapExportsChartOptions = {
      ...this.props.tradeMapExportsData,
    };
    const TradeMapPartnersChartOptions = {
      ...this.props.tradeMapPartnersData,
    };
    const MacMapChartOptions = {
      chart: {
        type: 'column',
        height: 400,
        width: 300,
      },
      colors: ['#002b54', '#009de8'],
      legend: {
        layout: 'horizontal',
        backgroundColor: '#FFFFFF',

        labelFormatter: function () {
          return this.name == 'minValue' ? t('mfn') : t('applied_tariff');
        },
      },

      title: {
        text: null,
      },

      yAxis: {
        min: 0,
        title: {
          text: t('tariff') + ' (%)',
        },
      },
      tooltip: {
        /*headerFormat: '<span style="font-size:10px">{point.key}</span><table>',*/
        formatter: function () {
          let pt_series_name = '';

          if (this.point.series.name == 'minValue') pt_series_name = t('mfn');
          else pt_series_name = t('applied_tariff');

          return (
            '<span style="font-size:12px">' +
            this.point.category +
            '</span><table><tr><td style="color:' +
            this.point.series.color +
            ';padding:0">' +
            pt_series_name +
            ': </td><td style="padding:0"><b>' +
            this.point.y +
            '</b></td></tr></table>'
          );
        },

        shared: false,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      xAxis: {
        categories: [t('allproducts'), t('Industrial'), t('Agricultural')],
        crosshair: true,
        title: {
          text: t('Products'),
        },
      },

      series: this.props.macMapChartData,

      exporting: {
        enabled: false,
      },
    };
    const CountryKeyData = {
      ...this.props.countryKeyData,
    };
    const MacMapYearChartData = {
      ...this.props.macMapYearChartData,
    };
    return (
      <div className="embedded">
        <div className="container-fluid">
          <div className="section">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <h3 className="section-title"></h3>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center mb-4">
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="card card-number">
                    <span className="card-title">{t('countrykey_title_pop')}</span>
                    <span className="card-value">{this.formatValue(CountryKeyData.population)}</span>
                    <span className="card-year">({CountryKeyData.year})</span>
                    <span className="card-year"></span>
                    <span className="card-source">Source: {t('countrykey_title_un')}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="card card-number">
                    <span className="card-title">{t('countrykey_title_gdp')}</span>
                    <span className="card-value">{this.formatValueGDP(CountryKeyData.gdp)}</span>
                    <div>
                      <h5 className="card-currency">USD</h5>
                      <h6 className="card-year">({CountryKeyData.year})</h6>
                    </div>
                    <span className="card-source">Source: {t('countrykey_title_imf')}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="card card-number">
                    <span className="card-title">{t('countrykey_title_gdpcapita')}</span>
                    <span className="card-value">{this.formatValue(CountryKeyData.gdpPerCapita)}</span>
                    <div>
                      <h5 className="card-currency">USD</h5>
                      <h6 className="card-year">({CountryKeyData.year})</h6>
                    </div>
                    <span className="card-source">Source: {t('countrykey_title_imf')}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="card card-number">
                    <span className="card-title">{t('countrykey_title_unempl')}</span>
                    <span className="card-value">{this.formatValue(CountryKeyData.unemployment)} %</span>
                    <span className="card-year">({CountryKeyData.year})</span>
                    <span className="card-source">Source: {t('countrykey_title_ilo')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xs-12">
                  <Tab.Container defaultActiveKey="tm">
                    <Row id="country-nav-tabs" className="align-items-center justify-content-center">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                        <Nav id="Tabs" className="mb-4" fill variant="pills" onSelect={this.handleTabs}>
                          <Nav.Item>
                            <Nav.Link eventKey="tm">{t('tab_title_tradepartners')}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ce">{t('tab_title_currentexports')}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="epm">{t('tab_title_exportpotential')}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="macmap">{t('tab_title_marketaccess')}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="others">{t('tab_title_otherdata')}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <div id="DropDown" className="dropdown mb-4">
                          <DropdownButton
                            title={this.state.mobileDropDownSelected}
                            id="dropdown-menu-align-right"
                            onSelect={this.handleSelect}
                          >
                            <Dropdown.Item eventKey="tm" data-toggle="pill" href="#tm" aria-controls="pills-tm">
                              {t('tab_title_tradepartners')}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="ce" data-toggle="pill" href="#ce" aria-controls="pills-ce">
                              {t('tab_title_currentexports')}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="epm" data-toggle="pill" href="#epm" aria-controls="pills-epm">
                              {t('tab_title_exportpotential')}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="macmap" data-toggle="pill" href="#macmap" aria-controls="pills-macmap">
                              {t('tab_title_marketaccess')}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="others" data-toggle="pill" href="#others" aria-controls="pills-others">
                              {t('tab_title_otherdata')}
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tab.Content id="country-profile-embedded-content">
                          <Tab.Pane eventKey="tm">
                            <div className="row justify-content-center align-items-center">
                              <Col id="tm-chart" xs={12} sm={12} md={12} lg={8} className="div-chart">
                                {this.props.tradeMapPartnersData && this.state.tmChartLoadTrigger ? (
                                  <BarChart
                                    options={TradeMapPartnersChartOptions}
                                    type="tradePartners"
                                    lang={this.parameters.lang}
                                    size={this.state.size}
                                    isload={this.state.tmChartLoadTrigger}
                                  />
                                ) : null}
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start text-left">
                                <Row className="justify-content-start align-items-start mb-4">
                                  <div className="content-description-top">
                                    {this.country && this.props.tradeMapPartnersData ? (
                                      <h6 className="content-description-title">
                                        {t('chart_title_tradepartners')} {this.country[0].label} ({TradeMapPartnersChartOptions.data.year}){' '}
                                      </h6>
                                    ) : (
                                      <h6 className="content-description-title">Trade Map - Top trade partners </h6>
                                    )}
                                    <span className="badge badge-pill legend">Market analysis</span>
                                  </div>
                                </Row>

                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <p className="text-left">{t('tab_description_trademap')}</p>
                                  </Col>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <a target="_parent" href="https://intracen.org/resources/tools/trade-map" className="content-link">
                                      {t('chart_link_explore')}
                                    </a>
                                    <span className="vl"></span>
                                    <a target="_parent" href="https://www.trademap.org/Index.aspx" className="content-link">
                                      {t('chart_link_tool')}
                                    </a>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ce">
                            <div className="row justify-content-center align-items-center">
                              <div id="ce-chart" className="col-sm-12 col-md-12 col-lg-8 div-chart">
                                {this.props.tradeMapExportsData && this.state.ceChartLoadTrigger ? (
                                  <TreemapChart options={TradeMapExportsChartOptions} type="currentExports" />
                                ) : null}
                              </div>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start">
                                <Row className="justify-content-start align-items-start mb-4">
                                  <div className="content-description-top">
                                    {this.country && this.props.tradeMapPartnersData ? (
                                      <h6 className="content-description-title">
                                        {t('chart_title_currentexports')} {this.country[0].label} ({TradeMapPartnersChartOptions.data.year}){' '}
                                      </h6>
                                    ) : (
                                      <h6 className="content-description-title">Trade Map - Current exports </h6>
                                    )}
                                    <span className="badge badge-pill legend">Market analysis</span>
                                  </div>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <p className="">{t('tab_description_trademap')}</p>
                                  </Col>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <a target="_parent" href="https://intracen.org/resources/tools/trade-map" className="content-link">
                                      {t('chart_link_explore')}
                                    </a>
                                    <span className="vl"></span>
                                    <a target="_parent" href="https://www.trademap.org/Index.aspx" className="content-link">
                                      {t('chart_link_tool')}
                                    </a>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="epm">
                            <div className="row justify-content-center align-items-center">
                              <div id="epm-chart" className="col-sm-12 col-md-12 col-lg-8 div-chart">
                                <iframe src={this.epmChartIframe} width="100%" height="500px" frameBorder="0" title="description"></iframe>
                              </div>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start">
                                <Row className="justify-content-start align-items-start mb-4">
                                  <div className="content-description-top">
                                    {this.country && this.props.epmRefYear ? (
                                      <h6 className="content-description-title">
                                        {t('chart_title_exportpotential')} {this.country[0].label} ({this.props.epmRefYear}){' '}
                                      </h6>
                                    ) : (
                                      <h6 className="content-description-title">Export Potential Map - Export potential </h6>
                                    )}
                                    <span className="badge badge-pill legend">Market analysis</span>
                                  </div>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <p className="text-left">{t('tab_description_epm')}</p>
                                  </Col>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <a
                                      target="_parent"
                                      href="https://intracen.org/resources/tools/export-potential-map"
                                      className="content-link"
                                    >
                                      {t('chart_link_explore')}
                                    </a>
                                    <span className="vl"></span>
                                    <a target="_parent" href="https://exportpotential.intracen.org/en/" className="content-link">
                                      {t('chart_link_tool')}
                                    </a>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="macmap">
                            <div className="row justify-content-center align-items-center">
                              <div id="macmap-chart" className="col-sm-12 col-md-12 col-lg-8 div-chart">
                                <div id="macmap-chart" className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-center div-chart">
                                  {this.country && this.props.macMapChartData ? (
                                    <HighchartsReact highcharts={Highcharts} options={MacMapChartOptions}></HighchartsReact>
                                  ) : null}
                                </div>
                              </div>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start">
                                <Row className="justify-content-start align-items-start mb-4">
                                  <div className="content-description-top">
                                    {this.country && this.props.macMapYearChartData ? (
                                      <h6 className="content-description-title">
                                        {t('chart_title_marketaccess')} {this.country[0].label} (
                                        {MacMapYearChartData[0] ? MacMapYearChartData[0].refyear : ''}){' '}
                                      </h6>
                                    ) : (
                                      <h6 className="content-description-title">Market Access Map - Tariff averages </h6>
                                    )}
                                    <span className="badge badge-pill legend">Market analysis</span>
                                  </div>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <p className="text-left">{t('tab_description_macmap')}</p>
                                  </Col>
                                </Row>
                                <Row className="align-items-end">
                                  <Col xs={12} sm={12}>
                                    <a
                                      target="_parent"
                                      href="https://intracen.org/resources/tools/market-access-map"
                                      className="content-link"
                                    >
                                      {t('chart_link_explore')}
                                    </a>
                                    <span className="vl"></span>
                                    <a target="_parent" href="https://www.macmap.org/" className="content-link">
                                      {t('chart_link_tool')}
                                    </a>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="others">
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-center">
                                <div className="card card-other mt-4 mb-4">
                                  <div className="card-body">
                                    <Row className="justify-content-center align-items-center">
                                      <h6 className="content-description-title">{t('figure_title_roo')}</h6>
                                      {this.props.rooAgg ? (
                                        <span className="card-value"> {this.props.rooAgg.Value} </span>
                                      ) : (
                                        <span className="card-value"> N/A</span>
                                      )}
                                      <h6 className="content-description-title">{t('tool_agg_roo')}</h6>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs={12} sm={12} md={12} lg={12} className="justify-content-center text-center">
                                        <p className="text-left">{t('tab_description_roo')}</p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="card-footer">
                                    <a
                                      type="button"
                                      target="_parent"
                                      href="https://findrulesoforigin.org/"
                                      className="btn btn-embedded-primary btn-lg"
                                    >
                                      {t('view_more_roo')}
                                    </a>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-center">
                                <div className="card card-other mt-4 mb-4">
                                  <div className="card-body">
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs={12} sm={12} md={12} lg={12} className="justify-content-center text-center">
                                        <h6 className="content-description-title">{t('figure_title_im')}</h6>
                                        {this.props.investmentAgg && this.props.investmentAgg.inFlow ? (
                                          <div>
                                            <h4 className="profile-info text-center">
                                              <span className="card-value">{MoneyFormat(this.props.investmentAgg.inFlow, 2)}</span>
                                              <span className="card-value"> USD</span>
                                            </h4>
                                            <h6 className="content-description-title">
                                              <small> in </small>
                                              <span>{this.props.investmentAgg.year}</span>
                                            </h6>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </Col>
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs={12} sm={12} md={12} lg={12} className="justify-content-center text-center">
                                        <p className="text-left">{t('tab_description_im')}</p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="card-footer">
                                    <a
                                      type="button"
                                      target="_parent"
                                      href="https://www.investmentmap.org/home"
                                      className="btn btn-embedded-primary btn-lg"
                                    >
                                      {t('view_more_im')}
                                    </a>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4} className="justify-content-center">
                                <div className="card card-other mt-4 mb-4">
                                  <div className="card-body">
                                    <Row className="justify-content-center align-items-center">
                                      <h6 className="content-description-title">{t('figure_title_pm')}</h6>
                                      {this.props.procurementMapAvailabilityData ? (
                                        <span className="card-value">
                                          {this.props.procurementMapAvailabilityData.countries[0].nbTenders}
                                        </span>
                                      ) : null}
                                    </Row>
                                    <Row className="justify-content-center align-items-center">
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        className="justify-content-center align-items-center text-center"
                                      >
                                        <p className="text-left">{t('tab_description_pm')}</p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="card-footer">
                                    <a
                                      type="button"
                                      target="_parent"
                                      href="https://procurementmap.intracen.org/"
                                      className="btn btn-embedded-primary btn-lg"
                                    >
                                      {t('view_more_pm')}
                                    </a>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
            <div className="container mt-4">
              <div className="row align-items-center justify-content-center">
                <div className="col-xs-12 text-center">
                  <a
                    id="btn-visit"
                    target="_parent"
                    href="https://marketanalysis.intracen.org"
                    type="button"
                    className="btn btn-embedded-secondary btn-lg"
                  >
                    {t('embedded_visit_bottom_link')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    countries: state.countries.countries,

    countryKeyData: state.dataAvailability.countryKeyData,
    tradeMapExportsData: state.chartData.tradeMapExportsData,
    tradeMapPartnersData: state.chartData.tradeMapPartnersData,
    macMapChartData: state.chartData.macMapChartData,
    macMapYearChartData: state.chartData.macMapYearChartData,
    epmChartData: state.chartData.epmChartData,

    rooAgg: state.countryProfile.rooAgg,
    investmentAgg: state.countryProfile.investmentAgg,
    procurementMapAvailabilityData: state.dataAvailability.procurementMapAvailabilityData,
    epmRefYear: state.dataAvailability.epmRefYear,
    uiTranslationsLoaded: state.l10n,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountries()),
  fetchCountryKeyData: (countryCd) => dispatch(fetchCountryKeyData(countryCd)),
  fetchTradeMapExportsChart: (countryId) => dispatch(fetchTradeMapExportsChart(countryId)),
  fetchTradeMapPartnersChart: (countryId) => dispatch(fetchTradeMapPartnersChart(countryId)),
  fetchMacMapChart: (countryId) => dispatch(fetchMacMapChart(countryId)),
  fetchEpmChart: (countryId) => dispatch(fetchEpmChart(countryId)),

  fetchROOAggregated: (countryIds) => dispatch(fetchROOAggregated(countryIds)),
  fetchInvestmentMapAggregated: (countryIds) => dispatch(fetchInvestmentMapAggregated(countryIds)),
  fetchProcurementMapAvailability: (countryId) => dispatch(fetchProcurementMapAvailability(countryId)),
  fetchEpmRefYear: () => dispatch(fetchEpmRefYear()),
  //...userActions
});
export default connect(mapStateToProps, mapDispatchToProps)(CountryProfileEmbedded);
