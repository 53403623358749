import React, { Component, useEffect } from 'react';
import * as d3 from "d3v4";
import $ from 'jquery'
import { t } from '../../services/i18n'


class TreemapChart extends Component {

    constructor(props) {
        super(props);
        this.myTreemapChart = React.createRef();

    }
    componentWillMount() {

    }

    componentDidMount() {
        const { options } = this.props;
        const { type } = this.props;
        this.drawChart2(options.data, type)
    }
    componentDidUpdate() {

    }

    drawChart(data, type) {

        var sectiondata = null;

        var view = this.myTreemapChart.current;
        var div = $(this.myTreemapChart.current);
        var margin = { top: 10, right: 25, bottom: 65, left: 1 },
            width = $('.treemap').width(),
            height = 500;
        var formatValue = d3.format(",d");

        var box = d3.select("body").append("div").attr("id", "box-treemap")
            .attr("class", "box")
            .style("opacity", 0);

        const map = new Map();
        if (type == "currentExports") {
            map.set(data.children[0].section, "#002b54")
            map.set(data.children[1].section, "#971779")
            map.set(data.children[2].section, "#e03375")
            map.set(data.children[3].section, "#009de8")
            map.set(data.children[4].section, "#a1cdf3")
            map.set(data.children[5].section, "#fdd69b")
            map.set(data.children[6].section, "#fa9805")
            map.set(data.children[7].section, "#ef99ba")
            map.set(data.children[8].section, "#d6dde4")
            map.set(data.children[9].section, "#c8c8c8")
            map.set(data.children[10].section, "#7b7b7b")
            map.set(data.children[11].section, "#7dd5f0")
            map.set(data.children[12].section, "#798ea4")
            map.set(data.children[13].section, "#e1a851")
            map.set(data.children[14].section, "#d88b16")
            map.set(data.children[15].section, "#fa7b05")
            map.set(data.children[16].section, "#dc6d06")
            map.set(data.children[17].section, "#d95784")
            map.set(data.children[18].section, "#a3174d")
            map.set(data.children[19].section, "#9eaebe")
            map.set(data.children[20].section, "#8095a9")

        }
        else if (type == "productsExported") {
            map.set(0, "#002b54")
            map.set(1, "#971779")
            map.set(2, "#e03375")
            map.set(3, "#009de8")
            map.set(4, "#a1cdf3")
            map.set(5, "#fdd69b")
            map.set(6, "#fa9805")
            map.set(7, "#ef99ba")
            map.set(8, "#d6dde4")
            map.set(9, "#c8c8c8")
            map.set(10, "#7b7b7b")
            map.set(11, "#7dd5f0")
            map.set(12, "#798ea4")
            map.set(13, "#e1a851")
            map.set(14, "#d88b16")
            map.set(15, "#fa7b05")
            map.set(16, "#dc6d06")
            map.set(17, "#d95784")
            map.set(18, "#a3174d")
            map.set(19, "#9eaebe")
            map.set(20, "#8095a9")
        }

        var svg = d3.select(view)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + 0 + ")");


        var treemap = null;
        var root = null;
        if (type == "currentExports") {
            root = d3.hierarchy(data).sum(function (d) { return d.value }).sort((a, b) => b.height - a.height || b.value - a.value);
            treemap = d3.treemap().size([width, height]).padding(1);
        } else if (type == "productsExported") {
            root = d3.hierarchy(data).sum(function (d) { return d.exportedValue });
            treemap = d3.treemap().size([width, height]).padding(4);
        }
        const tree = treemap(root);

        const rects = [];
        svg
            .selectAll("rect")
            .data(root.leaves())
            .enter()
            .append("rect")
            .attr('x', function (d) { return d.x0; })
            .attr('y', function (d) { return d.y0; })
            .attr('width', function (d) { return d.x1 - d.x0; })
            .attr('height', function (d) { return d.y1 - d.y0; })
            .attr('margin', '2%')
            //.style("stroke", "black")
            .style("fill", function (d, i) {
                switch (type) {
                    case 'currentExports':
                        return map.get(d.parent.data.section)
                        break;
                    case 'productsExported':
                        //return '#002b54';
                        //console.log(d);
                        return map.get(i)
                        break;
                }
            })
            .on("mouseover", function (d, i) {
                d3.select(this).transition().duration('50').style('opacity', '.50');
                box.transition()
                    .duration(200)
                    .style("opacity", 1);
                if (window.innerWidth >= 380) {
                    box.html(divMouseOverContent(d))
                        .style("top", d3.event.pageY + 30 + "px")
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("display", "inline-block");
                }

            })
            .on("mouseout", function (d, i) {
                d3.select(this).transition().duration('50').style('opacity', '1');
                box.transition()
                    .duration(200)
                    .style("opacity", 0);
            })
            //.on("click", function (d, i) {
            //    console.log(d);
            //})
            .each(function (d) {
                var w = d3.select(this)._groups[0][0].attributes[2].value,
                    h = d3.select(this)._groups[0][0].attributes[3].value;
                rects.push({ w, h, d });
            });
        svg
            .selectAll("text")
            .data(root.leaves())
            .enter()
            .append("text")
            .attr("width", function (d2) {
                switch (type) {
                    case 'currentExports':
                        var rect = rects.find(x => x.d.data.code == d2.data.code)
                        var p_width = parseInt(rect.w)
                        //return rect.d.x1 - rect.d.x0 - 10;
                        return 50;
                        break;
                    case 'productsExported':
                        var rect = rects.find(x => x.d.data.productCode == d2.data.productCode)
                        var p_width = parseInt(rect.w)
                        return rect.d.x1 - rect.d.x0 - 10;
                        break;
                }

            })
            .attr("x", function (d) { return d.x0 + 10 })
            .attr("y", function (d) { return d.y0 + 20 })
            .text(function (d2, i) {
                var label = null;
                var rect = null;

                switch (type) {
                    case 'currentExports':
                        rect = rects.find(x => x.d.data.code == d2.data.code)
                        label = d2.data.name;

                        break;
                    case 'productsExported':
                        rect = rects.find(x => x.d.data.productCode == d2.data.productCode)
                        label = d2.data.productLabel;

                        break;
                }

                var p_width = parseInt(rect.w)
                var p_height = parseInt(rect.h)

                var pt_px = 0.75, // convert font size in pt into pixels
                    font_size = 14,   // or whatever it is set to in the stylesheet
                    averageLetterWidth = 0.58344, // average character width for Arial
                    letterWidth = 5.09;


                var label_space = label.length * (averageLetterWidth * font_size)
                var rect_space = p_width / (averageLetterWidth * font_size)
                var firstWord = label.replace(/ .*/, '');

                if (p_height >= 70) {
                    if (p_width >= 200) {
                        if ($.trim(label).substring(0, (p_width / letterWidth) - 20).split(" ").slice(0, -1).join(" ") + "..." == "...") {
                            if ($.trim(label).substring(0, (p_width / letterWidth) - 20).split(" ").slice(0, -1).join(" ").length < 1) {
                                return label.split(" ")[0];
                            } else {
                                return $.trim(label).substring(0, (p_width / letterWidth) - 10).split(" ").slice(0, -1).join(" ") + "..."
                            }
                        } else {
                            return $.trim(label).substring(0, (p_width / letterWidth) - 20).split(" ").slice(0, -1).join(" ") + "...";
                        }
                    }
                    else if (p_width >= 160 && p_width < 200) {
                        if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "..." == "...") {
                            if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ").length < 1) {
                                return label.split(" ")[0];
                            } else {
                                return $.trim(label).substring(0, (p_width / letterWidth) - 10).split(" ").slice(0, -1).join(" ") + "..."
                            }
                        } else {
                            return $.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "...";
                        }
                    }
                    else if (p_width >= 100 && p_width < 160) {
                        d3.select(this).style("font-size", "12px");

                        if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "..." == "...") {
                            if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ").length < 1) {
                                return label.split(" ")[0];
                            } else {
                                return $.trim(label).substring(0, (p_width / letterWidth) - 10).split(" ").slice(0, -1).join(" ") + "..."
                            }
                        } else {
                            return $.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "...";
                        }
                    }
                    else if (p_width >= 70 && p_width < 100) {
                        d3.select(this).style("font-size", "12px");
                        if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "..." == "...") {
                            if ($.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ").length < 1) {
                                return label.split(" ")[0];
                            } else {
                                return $.trim(label).substring(0, (p_width / letterWidth) - 10).split(" ").slice(0, -1).join(" ") + "..."
                            }
                        } else {
                            return $.trim(label).substring(0, (p_width / letterWidth) - 15).split(" ").slice(0, -1).join(" ") + "...";
                        }

                    }

                }

            })
            .attr("font-size", "14px")
            .attr("font-weight", "bold")
            .attr("fill", "white");

        function wrapText(selection) {
            selection.each(function () {
                const node = d3.select(this);
                const rectWidth = +node.attr('data-width');
                let word;
                const words = node.text().split(' ').reverse();
                let line = [];
                let lineNumber = 0;
                const x = node.attr('x');
                const y = node.attr('y');
                let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
                while (words.length > 1) {
                    word = words.pop();
                    line.push(word);
                    tspan.text(line.join(' '));
                    const tspanLength = tspan.node().getComputedTextLength();
                    if (tspanLength > rectWidth && line.length !== 1) {
                        line.pop();
                        tspan.text(line.join(' '));
                        line = [word];
                        tspan = addTspan(word);
                    }
                }
                addTspan(words.pop());

                function addTspan(text) {
                    lineNumber += 1;
                    return node
                        .append('tspan')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('dy', `${lineNumber * 12}px`)
                        .text(text);
                }
            });
        }




        var divMouseOverContent = function (d) {
            var content;
            var total_value = 0;

            if (type == "currentExports") {
                for (var i in d.parent.data.children) {
                    total_value += parseFloat(d.parent.data.children[i].value);
                }

                content = "<h3 class=\"boxCountries-lbl\">" + d.parent.data.section + "</h3>" +
                    "<h4 class=\"boxCountries-lbl\">" + d.parent.data.name + "</h4>" +
                    "<h4 class=\"boxCountries-lbl\">" + "Total Section: US$ " + formatValue(total_value) + t('chart_thousand') + "</h4>";
                content += "<h4 class=\"boxCountries-lbl-light\">" + d.data.code + "- " + d.data.name + "</h4>" +
                    "<h4 class=\"boxCountries-lbl\">US$ " + formatValue(d.data.value) + t('chart_thousand') + "</h4>";

                sectiondata = d;
            } else if (type == "productsExported") {
                content =
                    "<h4 class=\"boxCountries-lbl\">" + d.data.productLabel + "</h4>" +
                    "<h6> US$ " + formatValue(d.data.exportedValue) + t('chart_thousand') + "</li>";
            }


            return content;

        }




    }

    drawChart2(data, type) {

        var sectiondata = null;

        var view = this.myTreemapChart.current;
        var div = $(this.myTreemapChart.current);
        var margin = { top: 10, right: 25, bottom: 65, left: 1 },
            width = $('.treemap').width(),
            height = 500;
        var formatValue = d3.format(",d");

        var formatUnitValue = function (value) {

            value = value.toString();

            if (value.includes('.')) {
                value = value.substring(0, value.indexOf('.'));
            }

            var valueChanged = null;
            if (value.length < 6) {
                valueChanged = formatValue(value) + "K";
            }
            if (value.length == 6) {
                valueChanged = formatValue(value).substring(0, 3) + "M";
            }
            if (value.length >= 7) {
                valueChanged = formatValue(value).substring(0, formatValue(value).indexOf(',')) + "B";
            }
            return valueChanged;
        }



        const clearColor = ['rgb(161, 205, 243)', 'rgb(253, 214, 155)', 'rgb(214, 221, 228)', 'rgb(200, 200, 200)'];
        const map = new Map();
        if (type == "currentExports") {
            map.set(data.children[0].section, "#002b54")
            map.set(data.children[1].section, "#971779")
            map.set(data.children[2].section, "#e03375")
            map.set(data.children[3].section, "#009de8")
            map.set(data.children[4].section, "#a1cdf3")
            map.set(data.children[5].section, "#fdd69b")
            map.set(data.children[6].section, "#fa9805")
            map.set(data.children[7].section, "#ef99ba")
            map.set(data.children[8].section, "#d6dde4")
            map.set(data.children[9].section, "#c8c8c8")
            map.set(data.children[10].section, "#7b7b7b")
            map.set(data.children[11].section, "#7dd5f0")
            map.set(data.children[12].section, "#798ea4")
            map.set(data.children[13].section, "#e1a851")
            map.set(data.children[14].section, "#d88b16")
            map.set(data.children[15].section, "#fa7b05")
            map.set(data.children[16].section, "#dc6d06")
            map.set(data.children[17].section, "#d95784")
            map.set(data.children[18].section, "#a3174d")
            map.set(data.children[19].section, "#9eaebe")
            map.set(data.children[20].section, "#8095a9")

        }
        else if (type == "productsExported") {
            map.set(0, "#002b54")
            map.set(1, "#971779")
            map.set(2, "#e03375")
            map.set(3, "#009de8")
            map.set(4, "#a1cdf3")
            map.set(5, "#fdd69b")
            map.set(6, "#fa9805")
            map.set(7, "#ef99ba")
            map.set(8, "#d6dde4")
            map.set(9, "#c8c8c8")
            map.set(10, "#7b7b7b")
            map.set(11, "#7dd5f0")
            map.set(12, "#798ea4")
            map.set(13, "#e1a851")
            map.set(14, "#d88b16")
            map.set(15, "#fa7b05")
            map.set(16, "#dc6d06")
            map.set(17, "#d95784")
            map.set(18, "#a3174d")
            map.set(19, "#9eaebe")
            map.set(20, "#8095a9")
        }

        const div2 = d3.select(view).append("div")
            .style("position", "relative")
            .style("width", (width + margin.left + margin.right) + "px")
            .style("height", (height + margin.top + margin.bottom) + "px")
            .style("left", margin.left + "px")
            .style("top", margin.top + "px")
            ;

        var box = d3.select("body").append("div").attr("id", "box-treemap")
            .attr("class", "box")
            .style("opacity", 0)
            ;

        var root = null;
        var treemap = null;
        if (type == "currentExports") {
            root = d3.hierarchy(data).sum(function (d) { return d.value }).sort((a, b) => b.height - a.height || b.value - a.value);
            treemap = d3.treemap().size([width, height]).padding(1);
        } else if (type == "productsExported") {
            root = d3.hierarchy(data).sum(function (d) { return d.exportedValue });
            treemap = d3.treemap().size([width, height]).padding(4);
        }
        const tree = treemap(root);

        const treemap_product = div2.datum(root).selectAll(".treemap-product")
            .data(tree.leaves())
            .enter().append("div")
            .attr("class", "treemap-product")
            .style("left", (d) => d.x0 + "px")
            .style("top", (d) => d.y0 + "px")
            .style("width", (d) => Math.max(0, d.x1 - d.x0 - 1) + "px")
            .style("height", (d) => Math.max(0, d.y1 - d.y0 - 1) + "px")
            .style("background", function (d, i) {
                switch (type) {
                    case 'currentExports':
                        return map.get(d.parent.data.section)
                        break;
                    case 'productsExported':
                        return map.get(i)
                        break;
                }
            })
            .on("mouseover", function (d, i) {
                d3.select(this).transition().duration('50').style('opacity', '.50');
                box.transition()
                    .duration(200)
                    .style("opacity", 1);
                if (window.innerWidth >= 380) {
                    box.html(divMouseOverContent(d))
                        .style("top", d3.event.pageY + 30 + "px")
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("display", "inline-block");
                    //fillHSCodeTooltip(d);
                }
            })
            .on("mouseout", function () {
                d3.select(this).transition().duration('50').style('opacity', '1');
                box.transition()
                    .style("opacity", 0);

            })
            .append("p").text(function (d) {

                var label = null;
                switch (type) {
                    case 'currentExports':
                        label = d.data.name;
                        break;
                    case 'productsExported':
                        label = d.data.productLabel;
                        break;
                }
                var p_width = $(this).width();
                var p_height = $(this).parent().height();

                return trimText(this, label, p_width, p_height);
            }).style("font-family", "Maven Pro").style("font-size", "14px").style("color", "#fff");

        function trimText(this_p, label, width, height) {
            var letterWidth = 7.5,
                letterHeight = 22.00;

            label = $.trim(label);

            if (width * height < 12000) {
                //console.log(this_p, width, height);
                d3.select(this_p).attr("class", "small-p-treemap");
                letterWidth = 6.5;
            }
            if (clearColor.includes($(this_p).closest('div').css("background-color"))) {
                d3.select(this_p).attr("class", "p-treemap-dark");
            }

            var lettersPerLine = Math.floor(width / letterWidth);
            var maxLines = Math.floor(height / letterHeight);

            var firstWord = label.split(" ")[0];
            if (firstWord.length > lettersPerLine) {
                return "";
            }
            else {
                var lines = label.length / (width / letterWidth);
                if (lines > maxLines) {
                    //truncate lines

                    var words = label.split(" ");
                    var i = 0, j = 0;
                    var lines = [];
                    do {
                        var newLine = "";
                        if (lines[j] != null)
                            newLine = lines[j] + " " + words[i];
                        else
                            newLine = words[i];
                        if (newLine.length > lettersPerLine) {
                            j++;
                            newLine = words[i];
                        }
                        lines[j] = newLine;
                        i++;
                    }
                    while (lines.length < maxLines);
                    return lines.join(" ") + " ...";
                }
                else {
                    return label;
                }
            }
        }

        var divMouseOverContent = function (d) {
            var sectiondata = null;
            var content;
            var total_value = 0;

            if (type == "currentExports") {
                for (var i in d.parent.data.children) {
                    total_value += parseFloat(d.parent.data.children[i].value);
                }
                content = "<h4 class=\"boxCountries-lbl-light\">" + d.parent.data.section + ": " + d.parent.data.name + "</h4>" +
                    "<h4 class=\"boxCountries-lbl-light\">" + "Total Section: US$ " + formatUnitValue(total_value) + "</h4>";
                content += "<h4 class=\"boxCountries-lbl\">" + d.data.code + "- " + d.data.name + "</h4>" +
                    "<h4 class=\"boxCountries-lbl\">US$ <span class=\"box-treemap-value\">" + formatUnitValue(d.data.value) + "</span></h4>";

                sectiondata = d;
            } else if (type == "productsExported") {
                content =
                    "<h4 class=\"boxCountries-lbl\">" + d.data.productLabel + "</h4>" +
                    "<h4 class=\"boxCountries-lbl\">US$ <span class=\"box-treemap-value\">" + formatUnitValue(d.data.exportedValue) + "</span></h4>";
            }

            return content;

        }

    }

    render() {
        return <div className="treemap">
            <div id="treemap-chart" ref={this.myTreemapChart}></div>
        </div>
    }
}

export default TreemapChart

