import { countryProfileConstants } from "../constants";
import decryptData from "../helpers/decryption";
import ePingCtyCodeDict from "../helpers/itcEpingCtyCodeMapping";

export const fetchTradeMapAggregated = (countryIds) => (dispatch) => {
  return (
    fetch("https://www.trademap.org/api/TradeFlow?country=" + countryIds)
      //.then(response => {console.log('getTradeMapAggregatedresponse',response); response.json(); })
      .then((response) => response.json())
      .then((json) => {
        //console.log('getTradeMapAggregated',json);
        dispatch(getTradeMapAggregated(json));
      })
      .catch((err) => {
        console.error(err);
        dispatch(getTradeMapAggregated(""));
      })
  );
};

export const getTradeMapAggregated = (tradeMapAgg) => ({
  type: countryProfileConstants.GET_TRADEMAPAGG,
  tradeMapAgg,
});

export const fetchMacMapAggregated = (countryIds) => (dispatch) => {
  return fetch(
    "https://www.macmap.org/api-mat-portal/macmap-data?country=" + countryIds
  )
    .then((response) => response.json())
    .then((json) => dispatch(getMacMapAggregated(json)))
    .catch((err) => console.error(err));
};

export const getMacMapAggregated = (macMapAgg) => ({
  type: countryProfileConstants.GET_MACMAPAGG,
  macMapAgg,
});

/*export const fetchEPMAggregated = (countryIds) => (dispatch, getState) => {
        const { locale }  = getState().l10n;
        return fetch('https://exportpotential.intracen.org/api/'+ locale +'/epis/from/i/' + countryIds + '/to/w/all/what/a/all')        
        .then(response => response.json())
        .then(json => dispatch(getEPMAggregated(json)))
      .catch(err => console.error(err))
}*/

export const fetchEPMAggregated = (countryIds) => (dispatch, getState) => {
  const { locale } = getState().l10n;
  return fetch(
    "https://exportpotential.intracen.org/api/" +
      locale +
      "/epis/from/i/" +
      countryIds +
      "/to/w/all/what/a/all"
  )
    .then((response) => response.text())
    .then((json) => {
      const key = "<,+-:/;=)./:;,0*('-/9,))<-0,;+=)";

      const decryptedData = decryptData(json, key);

      dispatch(getEPMAggregated(decryptedData));
    })
    .catch((err) => console.error(err));
};

export const getEPMAggregated = (epmAgg) => ({
  type: countryProfileConstants.GET_EPMAGG,
  epmAgg,
});

export const fetchROOAggregated = (countryIds) => (dispatch) => {
  return fetch(
    "https://www.macmap.org/api-mat-portal/roo-data?country=" + countryIds
  )
    .then((response) => response.json())
    .then((json) => dispatch(getROOAggregated(json)))
    .catch((err) => console.error(err));
};

export const getROOAggregated = (rooAgg) => ({
  type: countryProfileConstants.GET_ROOAGG,
  rooAgg,
});

export const fetchInvestmentMapAggregated = (countryIds) => (dispatch) => {
  return fetch("https://www.trademap.org/api/InwardFlow?country=" + countryIds)
    .then((response) => response.json())
    .then((json) => dispatch(getInvestmentMapAggregated(json)))
    .catch((err) => {
      console.error(err);
      dispatch(getInvestmentMapAggregated(""));
    });
};

export const getInvestmentMapAggregated = (investmentAgg) => ({
  type: countryProfileConstants.GET_INVESTMENTMAPAGG,
  investmentAgg,
});

export const fetchProcurementMapAggregated = (countryIds) => (dispatch) => {
  return fetch("https://www.trademap.org/api/Tenders?country=" + countryIds)
    .then((response) => response.json())
    .then((json) => dispatch(getProcurementMapAggregated(json)))
    .catch((err) => console.error(err));
};

export const getProcurementMapAggregated = (procurementAgg) => ({
  type: countryProfileConstants.GET_PROCUREMENTMAPAGG,
  procurementAgg,
});

let myHeaders = new Headers();

//myHeaders.append('Content-Type', 'text/plain');
//myHeaders.append('Access-Control-Allow-Origin', '*');

//var miInit = {
//  headers: myHeaders,
//};

export const getEPing = (ePing) => ({
  type: countryProfileConstants.GET_EPING,
  ePing,
});

export const convertToCountryEpingCode = (countryId) => {
  return ePingCtyCodeDict.get(countryId.toString());
}

export const fetchEPing = (countryId) => (dispatch) => {
  // console.log(11, convertedCountryId(countryId));
  //https://epingalert.org/api/v1/azureSearch/getAll?page=1&pageSize=100&sortBy=distributionDate&sortDirection=desc&language=1&distributionDateFrom=2022-01-01&countryIds=C076

  return fetch(
    "https://epingalert.org/api/v1/azureSearch/getAll?page=1&pageSize=100&sortBy=distributionDate&sortDirection=desc&language=1&distributionDateFrom=2022-01-01&countryIds=" +
      convertToCountryEpingCode(countryId)
  )
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      dispatch(getEPing(json));
    })
    .catch((err) => console.error(err));
};
