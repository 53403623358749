import React from 'react'
import {t} from '../services/i18n'
import postcard_img_en from '../img/postcard-en.png'
import postcard_pdf_en from '../static/ITC-TMI-2021-MarketAnalysisTools-PostCard-English.pdf'
import postcard_img_fr from '../img/postcard-fr.png'
import postcard_pdf_fr from '../static/ITC-TMI-2021-MarketAnalysisTools-PostCard-French.pdf'
import postcard_img_es from '../img/postcard-es.png'
import postcard_pdf_es from '../static/ITC-TMI-2021-MarketAnalysisTools-PostCard-Spanish.pdf'

export default () => (
  <section id="links-section">
    <div className="container">
      <h2 className="col-12 section-title text-center mt-5">{t('section_post_cards')}</h2>
      <hr></hr>

      <div className="container-fluid">
        <div className="row">
          <div className="row-item ">
            <img src={postcard_img_en} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={postcard_img_en}>{ t('downloadPNG') }</a>
              <a target="_blank" rel="noopener noreferrer" href={postcard_pdf_en}>{ t('downloadPDF') }</a>
            </div>
          </div>

          <div className="row-item ">
            <img src={postcard_img_en} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={postcard_img_en}>{ t('downloadPNG') }</a>
              <a target="_blank" rel="noopener noreferrer" href={postcard_pdf_en}>{ t('downloadPDF') }</a>
            </div>
          </div>

          <div className="row-item">
            <img src={postcard_img_en} height={150}></img>
            <div className="desc">
              <a target="_blank" rel="noopener noreferrer" href={postcard_img_en}>{ t('downloadPNG') }</a>
              <a target="_blank" rel="noopener noreferrer" href={postcard_pdf_en}>{ t('downloadPDF') }</a>
            </div>
          </div>


        </div>
      </div>


    </div>

  </section>

)