import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from "jquery";
import { t } from '../../services/i18n'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Popup from "reactjs-popup";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addHighchartsMore from 'highcharts/highcharts-more';
import addExportModule from 'highcharts/modules/exporting';
import addTreemapModule from 'highcharts/modules/treemap';
import { MoneyFormat } from '../../services/util'
import { fetchRegions, fetchMacMapChart, fetchTradeMapExportsChart, fetchTradeMapPartnersChart, fetchProcurementMapAvailability } from '../../actions'
import TreemapChart from '../../constants/Embedded/treemap';
import BarChart from '../../constants/Embedded/barchart';


addTreemapModule(Highcharts);
addHighchartsMore(Highcharts);
addExportModule(Highcharts);

class RegionProfileEmbedded extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedRegion: null,
            size: { xxs: false, xs: false, sm: false, md: false, lg: false, xl: false },
            width: 0, height: 0,
            pageLoaded: false,
            mobileDropDownSelected: t('tab_title_tradepartners'),
            tabSelected: 'tm',
            tmChartLoadTrigger: true,
            ceChartLoadTrigger: false,
            data: null
        };
        this.region = null;
        this.regionLabel = null;
        this.parameters = {
            regionCode: null,
            lang: null
        }
        this.data = null;
        
    }

    componentWillMount() {
        this.parameters.regionCode = this.getParamValue('regionCode') ? this.getParamValue('regionCode') : null;
        this.parameters.lang = this.props.uiTranslationsLoaded.locale;

        /*console.log(this.parameters);*/
    }
    componentDidMount() {

        const tabs = $('#Tabs');
        window.addEventListener('resize', this.updateDimensions);
        this.setSize(this.state.size);
        this.state.pageLoaded = true;
        /*console.log(this.state.size);*/

        this.props.fetchRegions().then(() => {
            this.region = this.props.regions.filter(r => r.GroupCode == this.parameters.regionCode)
            switch (this.parameters.lang) {
                case 'en':
                    this.regionLabel = this.region[0].GroupNameEn;
                    break;
                case 'fr':
                    this.regionLabel = this.region[0].GroupNameFr;
                    break;
                case 'es':
                    this.regionLabel = this.region[0].GroupNameSp;
                    break;
            }
            this.loadChartsData(this.region[0]);
        });

    }
    componentDidUpdate() {
        
    }

    getChart = chart => {
        //	this.chart = chart;
        chart.plotHeight = 732;
        chart.plotSizeY = 732;
        chart.chartHeight = 800;
        chart.plotBox.height = 732;
        //chart.reflow();
    }
    getChartMini = chart => {
        //	this.chart = chart;
        chart.plotHeight = 332;
        chart.plotSizeY = 332;
        chart.chartHeight = 400;
        chart.plotBox.height = 332;

        chart.plotWidth = 300;
        chart.plotSizeX = 300;
        chart.chartWidth = 340;
        chart.plotBox.width = 300;
        //chart.reflow();
    }

    setSize(s) {
        var width = window.innerWidth;
        var tabsElement = $("#Tabs");
        var dropDownElement = $("#DropDown");

        if (width <= 425) {
            s.xxs = true;
            this.state.size.xs = false; this.state.size.sm = false; this.state.size.md = false; this.state.size.lg = false; this.state.size.xl = false;
            if (!this.state.pageLoaded) {
                tabsElement.hide();
                dropDownElement.show();
            }
            if (tabsElement[0].clientHeight > 70) {
                //console.log(tabsElement[0].clientHeight);
                tabsElement.hide();
                dropDownElement.show();
            }
            this.setHeight();
        }
        if (width >= 426 && width <= 576) {
            s.xs = true;
            this.state.size.xxs = false; this.state.size.sm = false; this.state.size.md = false; this.state.size.lg = false; this.state.size.xl = false;
            if (!this.state.pageLoaded) {
                tabsElement.hide();
                dropDownElement.show();
            }
            if (tabsElement[0].clientHeight > 70) {
                //console.log(tabsElement[0].clientHeight);
                tabsElement.hide();
                dropDownElement.show();
            }
            this.setHeight();
        }
        if (width >= 577 && width <= 767) {
            s.sm = true;
            this.state.size.xxs = false; this.state.size.xs = false; this.state.size.md = false; this.state.size.lg = false; this.state.size.xl = false;
            if (!this.state.pageLoaded) {
                tabsElement.hide();
                dropDownElement.show();
            }
            if (tabsElement[0].clientHeight > 70) {
                //console.log(tabsElement[0].clientHeight);
                tabsElement.hide();
                dropDownElement.show();
            }
            this.setHeight();
        }
        if (width >= 768 && width <= 991) {
            s.md = true;
            this.state.size.xxs = false; this.state.size.xs = false; this.state.size.sm = false; this.state.size.lg = false; this.state.size.xl = false;
            if (!this.state.pageLoaded) {
                tabsElement.show();
                dropDownElement.hide();
            }
            if (tabsElement[0].clientHeight > 70) {
                //console.log(tabsElement[0].clientHeight);
                tabsElement.hide();
                dropDownElement.show();
            }
            this.setHeight();
        }
        if (width >= 992 && width <= 1199) {
            s.lg = true;
            this.state.size.xxs = false; this.state.size.xs = false; this.state.size.sm = false; this.state.size.md = false; this.state.size.xl = false;
            if (!this.state.pageLoaded) {
                tabsElement.show();
                dropDownElement.hide();
            }
            if (this.parameters.lang == 'fr' || this.parameters.lang == 'es') {
                if (tabsElement[0].clientHeight > 70) {
                    //console.log(tabsElement[0].clientHeight);
                    tabsElement.hide();
                    dropDownElement.show();
                }
            }
            this.setHeight();
        }
        if (width >= 1200) {
            s.xl = true;
            this.state.size.xxs = false; this.state.size.xs = false; this.state.size.sm = false; this.state.size.md = false; this.state.size.lg = false;
            if (!this.state.pageLoaded) {
                tabsElement.show();
                dropDownElement.hide();
            }
            if (this.parameters.lang == 'fr' || this.parameters.lang == 'es') {
                if (tabsElement[0].clientHeight > 70) {
                    //console.log(tabsElement[0].clientHeight);
                    tabsElement.hide();
                    dropDownElement.show();
                }

            }
            this.setHeight();
        }

        return s;
    }
    setHeight() {

        if (this.state.size.xxs) {
            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1231);
                        $('#region-profile-embedded-content').height(821);
                    } else {
                        $('.section-region').height(913);
                        $('#region-profile-embedded-content').height(486);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(2126);
                        $('#region-profile-embedded-content').height(875);
                    } else {
                        $('.section-region').height(983);
                        $('#region-profile-embedded-content').height(540);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1181);
                        $('#region-profile-embedded-content').height(896);
                    } else {
                        $('.section-region').height(983);
                        $('#region-profile-embedded-content').height(540);
                    }

                    break;
            }
        }
        if (this.state.size.xs) {
            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1248);
                        $('#region-profile-embedded-content').height(821);
                    } else {
                        $('.section-region').height(860);
                        $('#region-profile-embedded-content').height(433);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1318);
                        $('#region-profile-embedded-content').height(875);
                    } else {
                        $('.section-region').height(930);
                        $('#region-profile-embedded-content').height(487);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1318);
                        $('#region-profile-embedded-content').height(896);
                    } else {
                        $('.section-region').height(930);
                        $('#region-profile-embedded-content').height(487);
                    }

                    break;
            }
        }
        if (this.state.size.sm) {

            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1200);
                        $('#region-profile-embedded-content').height(765);
                    } else {
                        $('.section-region').height(827);
                        $('#region-profile-embedded-content').height(393);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1250);
                        $('#region-profile-embedded-content').height(792);
                    } else {
                        $('.section-region').height(878);
                        $('#region-profile-embedded-content').height(420);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1250);
                        $('#region-profile-embedded-content').height(792);
                    } else {
                        $('.section-region').height(878);
                        $('#region-profile-embedded-content').height(420);
                    }
                    break;
            }
        }
        if (this.state.size.md) {
            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1229);
                        $('#region-profile-embedded-content').height(740);
                    } else {
                        $('.section-region').height(892);
                        $('#region-profile-embedded-content').height(356);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1279);
                        $('#region-profile-embedded-content').height(766);
                    } else {
                        $('.section-region').height(892);
                        $('#region-profile-embedded-content').height(410);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1279);
                        $('#region-profile-embedded-content').height(766);
                    } else {
                        $('.section-region').height(898);
                        $('#region-profile-embedded-content').height(385);
                    }
                    break;
            }
        }
        if (this.state.size.lg) {

            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(845);
                        $('#region-profile-embedded-content').height(339);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(855);
                        $('#region-profile-embedded-content').height(366);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(855);
                        $('#region-profile-embedded-content').height(366);
                    }
                    break;
            }

        }
        if (this.state.size.xl) {
            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(835);
                        $('#region-profile-embedded-content').height(346);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(862);
                        $('#region-profile-embedded-content').height(373);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(972);
                        $('#region-profile-embedded-content').height(482);
                    } else {
                        $('.section-region').height(862);
                        $('#region-profile-embedded-content').height(373);
                    }
                    break;
            }
        }

        //iPhone 5
        if (window.innerWidth <= 320) {
            switch (this.parameters.lang) {
                case 'en':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1298);
                        $('#region-profile-embedded-content').height(872);
                    } else {
                        $('.section-region').height(3282);
                        $('#region-profile-embedded-content').height(2050);
                    }
                    break;
                case 'fr':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1422);
                        $('#region-profile-embedded-content').height(979);
                    } else {
                        $('.section-region').height(1062);
                        $('#region-profile-embedded-content').height(619);
                    }
                    break;
                case 'es':
                    if (this.state.mobileDropDownSelected != t('tab_title_activetenders') || this.state.tabSelected != 'at') {
                        $('.section-region').height(1422);
                        $('#region-profile-embedded-content').height(979);
                    } else {
                        $('.section-region').height(1062);
                        $('#region-profile-embedded-content').height(619);
                    }

                    break;
            }
        }
    }
    removeCharts = () => {
        this.setState({ tmChartLoadTrigger: false })
        this.setState({ ceChartLoadTrigger: false })
    }
    loadCharts = () => {
        if (this.state.tabSelected == "tm" || this.state.mobileDropDownSelected == t('tab_title_tradepartners')) {
            this.setState({ tmChartLoadTrigger: true })
        }
        if (this.state.tabSelected == "ce" || this.state.mobileDropDownSelected == t('tab_title_currentexports')) {
            this.setState({ ceChartLoadTrigger: true })
        }
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });

        var tabsElement = $("#Tabs");
        var dropDownElement = $("#DropDown");

        if (tabsElement[0].clientHeight > 70) {
            tabsElement.hide();
            dropDownElement.show();
        }
        if ((this.state.width <= 425) && this.state.size.xxs === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.hide();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.show();
        //    console.log(this.state.size);
        }
        if ((this.state.width >= 426 && this.state.width < 577) && this.state.size.xs === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.hide();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.show();
            //console.log(this.state.size);

        }
        if ((this.state.width >= 576 && this.state.width < 768) && this.state.size.sm === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.hide();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.show();
            //console.log(this.state.size);

        }
        if ((this.state.width >= 768 && this.state.width < 992) && this.state.size.md === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.show();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.hide();
            //console.log(this.state.size);

        }
        if ((this.state.width >= 992 && this.state.width < 1200) && this.state.size.lg === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.show();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.hide();
            //console.log(this.state.size);

        }
        if ((this.state.width >= 1200) && this.state.size.xl === false) {
            this.removeCharts();
            this.state.size = this.setSize(this.state.size);
            tabsElement.show();

            this.loadCharts();
            //API CALL to be grouped into dedicated function
            //this.props.fetchTradeMapPartnersChart(this.state.selectedCountry.value);
            //this.props.fetchTradeMapExportsChart(this.state.selectedCountry.value);
            dropDownElement.hide();
            //console.log(this.state.size);

        }
    };

    handleTabs = (e) => {

        switch (e) {
            case 'tm':
                //console.log(this.state.tmChartLoadTrigger)

                this.setState({ tmChartLoadTrigger: true })
                this.setState({ ceChartLoadTrigger: false })
                //this.setState({
                //    mobileDropDownSelected: t('tab_title_tradepartners'),
                //    tabSelected: 'tm',
                //});
                this.state.mobileDropDownSelected = t('tab_title_tradepartners');
                this.state.tabSelected = 'tm'
                this.setHeight();
                this.props.fetchTradeMapPartnersChart(this.state.selectedRegion.GroupCode);
                //console.log(this.state.tmChartLoadTrigger)


                break;
            case 'ce':
                //console.log(this.state.ceChartLoadTrigger)
                this.setState({ ceChartLoadTrigger: true })
                this.setState({ tmChartLoadTrigger: false })
                //this.setState({
                //    mobileDropDownSelected: t('tab_title_currentexports'),
                //    tabSelected: 'ce',
                //});
                this.state.mobileDropDownSelected = t('tab_title_currentexports');
                this.state.tabSelected = 'ce'
                this.setHeight();
                this.props.fetchTradeMapExportsChart(this.state.selectedRegion.GroupCode);
                //console.log(this.state.ceChartLoadTrigger)
                break;
                break;
            case 'at':
                //this.setState({
                //    mobileDropDownSelected: t('tab_title_otherdata'),
                //    tabSelected: 'others',
                //});
                this.state.mobileDropDownSelected = t('tab_title_activetenders');
                this.state.tabSelected = 'at'
                this.setHeight();
                break;

        }
    }
    handleSelect = (e) => {

        $('#tm').removeClass('active show');
        $('#ce').removeClass('active show');
        $('#at').removeClass('active show');

        switch (e) {
            case 'tm':
                this.setState({ tmChartLoadTrigger: true })
                this.setState({ ceChartLoadTrigger: false })
                this.setState({
                    mobileDropDownSelected: t('tab_title_tradepartners'),
                    tabSelected: 'tm',
                });
                this.state.mobileDropDownSelected = t('tab_title_tradepartners');
                this.state.tabSelected = 'tm';
                this.setHeight();
                $('#' + e).removeClass('fade');
                this.props.fetchTradeMapPartnersChart(this.state.selectedRegion.GroupCode);
                $('#' + e).addClass('active show');
                break;
            case 'ce':
                this.setState({ ceChartLoadTrigger: true })
                this.setState({ tmChartLoadTrigger: false })
                this.setState({
                    mobileDropDownSelected: t('tab_title_currentexports'),
                    tabSelected: 'ce',
                });
                this.state.mobileDropDownSelected = t('tab_title_currentexports');
                this.state.tabSelected = 'ce';
                this.setHeight();
                $('#' + e).removeClass('fade');
                this.props.fetchTradeMapExportsChart(this.state.selectedRegion.GroupCode);
                $('#' + e).addClass('active show');
                break;
            case 'at':
                this.setState({
                    mobileDropDownSelected: t('tab_title_activetenders'),
                    tabSelected: 'at',
                });
                this.state.mobileDropDownSelected = t('tab_title_activetenders');
                this.state.tabSelected = 'at';
                this.setHeight();
                $('#' + e).removeClass('fade');
                $('#' + e).addClass('active show');
                break;
        }
    }

    loadChartsData(selectedRegion) {
        if (selectedRegion) {
            this.setState({ selectedRegion: selectedRegion });
            this.props.fetchTradeMapPartnersChart(selectedRegion.GroupCode);
            this.props.fetchProcurementMapAvailability(selectedRegion.GroupCode);

        }
    }
    getParamValue = (paramName) => {
        var url = window.location.search.substring(1);
        var qArray = url.split('&');
        for (var i = 0; i < qArray.length; i++) {
            var pArr = qArray[i].split('=');
            if (pArr[0] === paramName)
                return pArr[1];

        }
    }

    render() {


        const TradeMapExportsChartOptions = {

            ...this.props.tradeMapExportsData
        }
        const TradeMapPartnersChartOptions = {
            ...this.props.tradeMapPartnersData
        }

        const RegionData = {
            regionCode: this.parameters.regionCode,
            regionLabel: this.regionLabel
        }

        return (
            <div className="embedded">
                <div className="container-fluid">
                    <div className="section-region">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <h3 className="section-title"></h3>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xs-12">
                                    <Tab.Container defaultActiveKey="tm">
                                        <Row id="country-nav-tabs" className="align-items-center justify-content-center" >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                                <Nav id="Tabs" className="mb-4" fill variant="pills" onSelect={this.handleTabs}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="tm">{t('tab_title_tradepartners')}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="ce">{t('tab_title_currentexports')}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="at">{t('tab_title_activetenders')}</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <div id="DropDown" className="dropdown mb-4">
                                                    <DropdownButton
                                                        id="dropdown-menu-align-right"
                                                        title={this.state.mobileDropDownSelected}
                                                        onSelect={this.handleSelect}>
                                                        <Dropdown.Item eventKey="tm" data-toggle="pill" href="#tm" aria-controls="pills-tm">{t('tab_title_tradepartners')}</Dropdown.Item>
                                                        <Dropdown.Item eventKey="ce" data-toggle="pill" href="#ce" aria-controls="pills-ce">{t('tab_title_currentexports')}</Dropdown.Item>
                                                        <Dropdown.Item eventKey="at" data-toggle="pill" href="#at" aria-controls="pills-at">{t('tab_title_activetenders')}</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center justify-content-center">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Tab.Content id="region-profile-embedded-content">
                                                    <Tab.Pane eventKey="tm">
                                                        <div className="row justify-content-center align-items-center">
                                                            <Col id="tm-chart" xs={12} sm={12} md={12} lg={8} className="div-chart">
                                                                {this.props.tradeMapPartnersData && this.state.tmChartLoadTrigger ? <BarChart options={TradeMapPartnersChartOptions} type="tradePartnersRegion" region={RegionData} lang={this.parameters.lang} size={this.state.size} isload={this.state.chartsLoadTrigger} /> : null}
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start text-left">
                                                                <Row className="justify-content-start align-items-start mb-4">
                                                                    <div className="content-description-top">
                                                                        {this.region ? <h6 className="content-description-title">Trade Map - {t('chart_title_tradepartners')} {this.regionLabel} </h6> : <h6 className="content-description-title">Trade Map - Top trade partners </h6>}
                                                                        <span className="badge badge-pill legend">Market analysis</span>
                                                                    </div>
                                                                </Row>

                                                                <Row className="align-items-end">
                                                                    <Col xs={12} sm={12}>
                                                                        <p className="text-left">{t('tab_description_trademap')}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-end">
                                                                    <Col xs={12} sm={12}>
                                                                        <a target="_parent" href="https://intracen.org/resources/tools/trade-map" className="content-link">{t('chart_link_explore')}</a><span className="vl"></span><a target="_parent" href="https://www.trademap.org/Index.aspx" className="content-link">{t('chart_link_tool')}</a>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="ce">
                                                        <div className="row justify-content-center align-items-center">
                                                            <div id="ce-chart" className="col-sm-12 col-md-12 col-lg-8 div-chart" >
                                                                {this.props.tradeMapExportsData && this.state.ceChartLoadTrigger ? <TreemapChart options={TradeMapExportsChartOptions} type="currentExports" /> : null}
                                                            </div>
                                                            <Col xs={12} sm={12} md={12} lg={4} className="justify-content-start text-left">
                                                                <Row className="justify-content-start align-items-start mb-4">
                                                                    <div className="content-description-top">
                                                                        {this.region ? <h6 className="content-description-title">Trade Map - {t('chart_title_currentexports')} {this.regionLabel} </h6> : <h6 className="content-description-title">Trade Map - Current exports </h6>}
                                                                        <span className="badge badge-pill legend">Market analysis</span>
                                                                    </div>
                                                                </Row>
                                                                <Row className="align-items-end">
                                                                    <Col xs={12} sm={12}>
                                                                        <p className="text-left">{t('tab_description_trademap')}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-end">
                                                                    <Col xs={12} sm={12}>
                                                                        <a target="_parent" href="https://intracen.org/resources/tools/export-potential-map" className="content-link">{t('chart_link_explore')}</a><span className="vl"></span><a target="_parent" href="https://exportpotential.intracen.org/en/" className="content-link">{t('chart_link_tool')}</a>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="at">
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="card card-other mt-4 mb-4">
                                                                <div className="card-body">
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h6 className="content-description-title">{t('figure_title_pm')}</h6>
                                                                        {(this.props.procurementMapAvailabilityData ? <span className="card-value">{this.props.procurementMapAvailabilityData.totalTenders}</span> : null)}
                                                                    </Row>
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <Col sm={12} md={12} lg={12} className="justify-content-center align-items-center text-center">
                                                                            <p className="text-left">{t('tab_description_pm')}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <a type="button" target="_parent" href="https://procurementmap.intracen.org/" className="btn btn-embedded-primary btn-lg">{t('view_more_pm')}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                            
                        </div>
                        

                        <div className="container mt-4">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-xs-12 text-center">
                                    <a id="btn-visit" target="_parent" href="https://marketanalysis.intracen.org/en" type="button" className="btn btn-embedded-secondary btn-lg">{t('embedded_visit_bottom_link')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        regions: state.countries.regions,

        tradeMapExportsData: state.chartData.tradeMapExportsData,
        tradeMapPartnersData: state.chartData.tradeMapPartnersData,
        //macMapChartData: state.chartData.macMapChartData,

        procurementMapAvailabilityData: state.dataAvailability.procurementMapAvailabilityData,
        uiTranslationsLoaded: state.l10n
    };
}


const mapDispatchToProps = dispatch => ({
    fetchRegions: () => dispatch(fetchRegions()),
    fetchTradeMapExportsChart: (countryGroupCd) => dispatch(fetchTradeMapExportsChart(null, countryGroupCd)),
    fetchTradeMapPartnersChart: (regionCd) => dispatch(fetchTradeMapPartnersChart(null, regionCd)),
    //fetchMacMapChart: (countryId) => dispatch(fetchMacMapChart(countryId)),

    fetchProcurementMapAvailability: (regionCd) => dispatch(fetchProcurementMapAvailability(null, regionCd, null)),
    //...userActions
})
export default connect(mapStateToProps, mapDispatchToProps)(RegionProfileEmbedded)