import {MoneyFormat} from '../services/util'

const INITIAL_STATE = {
  tradeMapAgg: null,
  macMapAgg: null,
  epmAgg: null,
  rooAgg: null,
  investmentAgg: null,
  procurementAgg: null,
  ePing: {
    totalCount: "",
  }
}

const countryProfile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_TRADEMAPAGG':
      // console.log("reducertradeMapAgg",action.tradeMapAgg);
      let oTrademap = {
        "directMirror": action.tradeMapAgg.directMirror,
        "importedValue": action.tradeMapAgg.importedValue,
        "year": action.tradeMapAgg.year
      }

      return {
        ...state,
        tradeMapAgg: oTrademap
      }
    case 'GET_MACMAPAGG':
      return {
        ...state,
        macMapAgg: [...action.macMapAgg.map(function (record) {
          return {
            "Agreement": record.tariffRegime,
            "Tariff": record.tariffValue,
            "Year": record.tariffYear
          }
        })]
      }
    case 'GET_EPMAGG':

      // console.log("empagg reducer",action.epmAgg.length);

      if (action.epmAgg.length > 0) {
        let ctr = {
          "Value": MoneyFormat((action.epmAgg[0].value * (1 - action.epmAgg[0].realizedPotential)), 2)
        }

        // console.log('ctr',ctr);
        return {
          ...state,
          epmAgg: ctr
        }
      } else
        return {
          ...state,
          epmAgg: {"Value": "N/A"}
        }

    case 'GET_ROOAGG':
      let obj = {
        "Value": action.rooAgg
      }

      //console.log('rooagg',obj);
      return {
        ...state,
        rooAgg: obj
      }


    case 'GET_INVESTMENTMAPAGG':
      let objInv = {
        "inFlow": action.investmentAgg.inFlow,
        "year": action.investmentAgg.year
      }
      //console.log('objInv',objInv);
      return {
        ...state,
        investmentAgg: objInv
      }
    case 'GET_PROCUREMENTMAPAGG':
      let objProc = {
        "tenders": action.procurementAgg.tenders,
        "awards": action.procurementAgg.awards
      }
      //console.log('objProc',objProc);
      return {
        ...state,
        procurementAgg: objProc
      }


    case 'GET_EPING':
      return {
        ...state,
        ePing: action.ePing
      }

    default:
      return state
  }
}

export default countryProfile