import React from 'react'
import {
   
    withRouter
  } from 'react-router-dom'
import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import Popup from "reactjs-popup";
import LoginPage from "../containers/LoginPage"

// Because we pass our component to withRouter
// our component will be passed `history` as a prop.

//const AuthButton = withRouter(({ history }) => (
export const AuthButtonModal = withRouter(({ history }) => (    
    localStorage.getItem('user') ? (
     
        <li  className="pointer" onClick={() => {            
            localStorage.removeItem('user');
            history.push('/');
          }}>{t('logout')}</li>
        
 
    ) : (
        <Popup 
        contentStyle={{ width: '400px' }}
        trigger={<span><li  className="pointer">{t('login')}</li></span>} 
        modal>
                        <LoginPage></LoginPage>
		</Popup>     
     
    )
  ));


  