import {connect} from 'react-redux'
import React, {Component} from 'react'

import {t} from '../services/i18n'
import {
  fetchCountries,
  fetchEPing,
  fetchEPMAggregated,
  fetchInvestmentMapAggregated,
  fetchMacMapAggregated,
  fetchProcurementMapAggregated,
  fetchROOAggregated,
  fetchTradeMapAggregated,
  userActions
} from '../actions'
import {FormatPercentage, MoneyFormat} from '../services/util'
import Newsletters from '../components/Newsletters'
import Select from 'react-select';
import publicIp from "public-ip";
import iplocation from "iplocation";

class CountryProfile extends Component {

  state = {
    selectedCountry: null,
  }

  componentWillMount() {
    this.props.getAllUsers();
    this.props.fetchCountries();
 
  }

  componentDidMount() {
    let userCountry = null;
    (async () => {

      iplocation(await publicIp.v4())
        .then((res) => {
          userCountry = res.countryCode;
          let tempctrs = ctr.filter(c => +c.code === +userCountry);
          //console.log(tempctrs);
          if (tempctrs.length === 1)
            this.handleChangeCountry(tempctrs[0]);
            this.props.fetchEPing();
        })
        .catch(() => {
          console.log("error in getting Iplocation");
          let defCtryCode = this.props.countries.filter(c => c.code === 'CH');
          console.log(defCtryCode);
          this.handleChangeCountry(defCtryCode[0]);
        });
      let ctr = this.props.countries;
    })();


  }

  handleChangeCountry = (selectedCountry) => {

    this.setState({selectedCountry: selectedCountry});
    this._countrySelectCP.blur();

    let aggCountries = selectedCountry.value;
    this.props.fetchEPing(aggCountries);
    this.props.fetchTradeMapAggregated(aggCountries);
    this.props.fetchMacMapAggregated(aggCountries);
    this.props.fetchEPMAggregated(aggCountries);
    this.props.fetchROOAggregated(aggCountries);
    this.props.fetchInvestmentMapAggregated(aggCountries);
    this.props.fetchProcurementMapAggregated(aggCountries);
  }

  render() {
    return (
      <div>
        <section id="itc-banner">
          <h1 className="itc-banner-title mx-auto">{t('countryprofile')} </h1>
        </section>
        <div id="page-wrapper" className="container">
          <div className="row">
            <div id="itc-page-content" className="itc-page-content col-sm-12 col-md-12 py-4 px-4">
              <section id="itc-profile-content" className="country-profile-page">
                <div className="container">
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-4 col-lg-3 mt-2 text-right small-center"><label htmlFor=""
                                                                                                    className="form-label">{t('lbl_choose_country')}</label>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <Select ref={c => (this._countrySelectCP = c)} className="countrySelect"
                              isSearchable="true"
                              value={this.state.selectedCountry}
                              onChange={this.handleChangeCountry}
                              options={this.props.countries}
                              placeholder={t('lbl_select')}
                      />
                    </div>
                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat" />
                      <h3 className="col-sm-12 secondary-title text-center"><strong>Trade Map</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_tm')}
                            </div>
                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_tm')}</h4>

                            {(this.props.tradeMapAgg && this.props.tradeMapAgg.importedValue ? (
                              <h4 className="profile-info text-center">
                                <span className="data icon usd">&nbsp; USD </span>
                                <span
                                  className="data">{MoneyFormat(this.props.tradeMapAgg.importedValue, 2)}</span><small>{t('tool_data_tm_of')}</small>
                                <span
                                  className="data"> {t('tool_data_tm_imports')}</span><small> {t('tool_data_tm_in')}</small>
                                <span
                                  className="data icon year"> {this.props.tradeMapAgg.year} {(this.props.tradeMapAgg.directMirror === 'MIRROR' ? '(mirror)' : '')}</span>
                              </h4>) : '')}


                          </div>
                          <div className="col-12 col-sm-4 mx-auto">
                            <a href="https://www.trademap.org/Index.aspx" className="d-block text-center"
                               target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_tm')}</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat"/>
                      <h3 className="col-sm-12 secondary-title text-center"><strong>Market Access Map</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_mm')}
                            </div>
                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_mm')}</h4>

                            {(this.props.macMapAgg ? this.props.macMapAgg.map(item => (
                              <h4 className="profile-info text-center" key={item.ID}>

                                <span
                                  className="data">{FormatPercentage(item.Tariff, 2)}</span><small> {t('tool_of_mm')} </small>
                                <span className="data"> {item.Agreement} </span><small> {t('tool_in_mm')} </small>
                                <span className="data icon year"> {item.Year} </span>
                              </h4>
                            )) : "")}


                          </div>
                          <div className="col-12 col-sm-5 mx-auto">
                            <a href="https://www.macmap.org/" className="d-block text-center" target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_mm')}</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat"/>
                      <h3 className="col-sm-12 secondary-title text-center"><strong>Export Potential Map</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_epm')}
                              <a className="contentLink"
                                 href="https://umbraco.exportpotential.intracen.org/media/1089/epa-methodology_141216.pdf"
                                 target="_blank"> {t('tool_desc_epm_link')}  </a>
                            </div>

                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_epm')}</h4>
                            <h4 className="profile-info text-center"><span
                              className="data">${(this.props.epmAgg ? this.props.epmAgg.Value : "N/A")}</span></h4>
                          </div>
                          <div className="col-12 col-sm-5 mx-auto">
                            <a href="http://exportpotential.intracen.org/#/home" className="d-block text-center"
                               target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_epm')}</button>
                            </a>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat"/>
                      <h3 className="col-sm-12 secondary-title text-center"><strong>Rules of Origin</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_roo')}
                            </div>
                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_roo')}</h4>

                            <h4 className="profile-info text-center">

                              <span className="data">{(this.props.rooAgg ? this.props.rooAgg.Value : "N/A")}</span>
                              <span className="data"> {t('tool_agg_roo')}  </span>
                              <span className="data icon gavel"/>
                            </h4>
                          </div>
                          <div className="col-12 col-md-5 mx-auto">
                            <a href="http://findrulesoforigin.org/" className="d-block text-center" target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_roo')}</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat"/>
                      <h3 className="col-sm-12 secondary-title text-center"><strong>Investment Map</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_im')}
                            </div>
                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_im')}</h4>
                            {(this.props.investmentAgg && this.props.investmentAgg.inFlow ? (
                              <h4 className="profile-info text-center">
                                <span className="data icon usd">USD </span>
                                <span
                                  className="data">{MoneyFormat(this.props.investmentAgg.inFlow, 2)}</span><small> in </small>
                                <span className="data icon year">{this.props.investmentAgg.year}</span>
                              </h4>
                            ) : '')}


                          </div>
                          <div className="col-12 col-sm-5 mx-auto">
                            <a href="https://www.investmentmap.org/home" className="d-block text-center"
                               target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_im')}</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="featured-mat col-12 col-sm-12 mt-4">
                      <div className="header-featured-mat"/>
                      <h3 className="col-sm-12 secondary-title text-center"><strong>E-PING</strong></h3>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="profile-text text-center">
                              {t('tool_desc_eping')}
                            </div>
                            <h4 className="country-profile-subtitle text-center">{t('tool_data_type_eping')}</h4>


                            <h4 className="profile-info text-center">
                              <span
                                className="data icon calc">{(this.props.ePing ? this.props.ePing.totalCount : "N/A")}</span>
                            </h4>

                            
                          </div>
                          <div className="col-12 col-sm-5 mx-auto">
                            <a href="http://www.epingalert.org/en" className="d-block text-center" target="_blank">
                              <button className="btnTools">{t('tool_view_all_btn_eping')}</button>
                            </a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <Newsletters/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {users, authentication} = state;
  const {user} = authentication;

  return {
    user,
    users,
    countries: state.countries.countries,
    tradeMapAgg: state.countryProfile.tradeMapAgg,
    macMapAgg: state.countryProfile.macMapAgg,
    epmAgg: state.countryProfile.epmAgg,
    rooAgg: state.countryProfile.rooAgg,
    investmentAgg: state.countryProfile.investmentAgg,
    procurementAgg: state.countryProfile.procurementAgg,
    ePing: state.countryProfile.ePing
  };
}

const mapDispatchToProps = dispatch => ({
  fetchCountries: () => dispatch(fetchCountries()),
  fetchTradeMapAggregated: (countryIds) => dispatch(fetchTradeMapAggregated(countryIds)),
  fetchMacMapAggregated: (countryIds) => dispatch(fetchMacMapAggregated(countryIds)),
  fetchEPMAggregated: (countryIds) => dispatch(fetchEPMAggregated(countryIds)),
  fetchROOAggregated: (countryIds) => dispatch(fetchROOAggregated(countryIds)),
  fetchInvestmentMapAggregated: (countryIds) => dispatch(fetchInvestmentMapAggregated(countryIds)),
  fetchProcurementMapAggregated: (countryIds) => dispatch(fetchProcurementMapAggregated(countryIds)),
  fetchEPing: (countryIds) => dispatch(fetchEPing(countryIds)),
  ...userActions
})

export default connect(mapStateToProps, mapDispatchToProps)(CountryProfile)
