import React from 'react'
import {t} from '../services/i18n'
import itc_comp_bklt_img from '../img/itc-2021-Comprehensive-Booklet.png';
import itc_comp_bklt_pdf from '../static/ITC-TMI-2021-Comprehensive-Booklet.pdf';



export default () => (
  <section id="links-section">
    <div className="container">
      <h2 className="col-12 section-title text-center mt-5">{t('section_booklet')}</h2>
      <hr></hr>

      <div className="container-fluid">
        <div className="row">
          <div className="vid-col col-md-7 col-sm-12 ">
            <div className="videoWrapper booklet">
              <div className="page">
                <img src={itc_comp_bklt_img} />
              </div>
              
            </div>

          </div>
          <div className="vid-col col-md-5 col-sm-12 ">
            <div className="desc">
              <div className=" row no-gutters justify-content-md-center text-center mt-4">
                {t('section_booklet_desc')}
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href={itc_comp_bklt_pdf}> {t('downloadPDF')} </a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>

)