export * from './alert.actions';
export * from './user.actions';
export * from './country.actions';
export * from './chart.actions';
export * from './data.availability.actions';
export * from './country.profile.actions';

export const changeLocale = (locale) => ({
    type: 'CHANGE_LOCALE',
    locale
})

export const setUiTranslationsLoading = () => ({
    type: 'SET_UI_TRANSLATIONS_LOADING'
})

export const setUiTranslationsLoaded = isLoaded => ({
    type: 'SET_UI_TRANSLATIONS_LOADED',
    isLoaded
})






