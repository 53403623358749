import { formatMacMapData, MoneyFormat, treemapGroups, treemapGroupsEmbedded} from '../services/util'
import { t } from '../services/i18n'
import $ from "jquery"

const INITIAL_STATE = {
    macMapChartData: null,
    macMapYearChartData: null,
    epmChartData: null,
    tradeMapChartData: null,
    tradeMapExportsData: null,
    tradeMapPartnersData: null
}

const chartData = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'GET_MACMAPCHARTDATA':
            return {
                ...state,
                macMapChartData: formatMacMapData([...action.macMapChartData.map(function (data) {
                        return {
                            "groupCode": data.groupCode,
                            "maxValue": data.maxValue,
                            "minValue": data.minValue,
                        }

                })
                ])

                    
            }
        case 'GET_MACMAPYEARCHARTDATA':
            return {
                ...state,
                macMapYearChartData: action.macMapYearChartData
            }

        case 'GET_TRADEMAPCHARTDATA':
            if (window.location.pathname.match("/embedded/country-profile-embedded")) {
                return {
                    ...state,
                    tradeMapChartData: {
                        title: {
                            text: (action.tradeMapChartData.exportedValue ? '<b>$' + MoneyFormat((action.tradeMapChartData.exportedValue ? action.tradeMapChartData.exportedValue : 0), 2) + ' </b><b> ' + action.tradeMapChartData.share + '%</b>' + t('chart_tm_subtitle') + ':' : ''),
                            align: 'center',
                            verticalAlign: 'bottom',
                            y: -260
                        },
                        series: [{
                            type: 'pie',
                            name: 'Share in ' + action.tradeMapChartData.countryLabel + ' exports',
                            innerSize: '50%',
                            data: (action.tradeMapChartData.bilateral ? action.tradeMapChartData.bilateral.map(function (data) {
                                return [data.partnerLabel, data.share];
                            }) : [])

                        }]
                    }
                }
            }
            else {
                return {
                    ...state,
                    /*tradeMapChartData:  formatTradeMapData([...action.tradeMapChartData.map( function(data) {
                        var ctr = { "groupCode": data.groupCode,
                                    "maxValue": data.maxValue,
                                    "minValue": data.minValue
                                }
                        return ctr;
                })])*/
                    tradeMapChartData: {

                        title: {
                            //text: '<b>$' + MoneyFormat(action.tradeMapChartData.ExportedValue,2) + ' </b><b> ' +  action.tradeMapChartData.Share + '%</b> of world export',
                            text: (action.tradeMapChartData.exportedValue ? '<b>$' + MoneyFormat((action.tradeMapChartData.exportedValue ? action.tradeMapChartData.exportedValue : 0), 2) + ' </b><b> ' + action.tradeMapChartData.share + '%</b>' + t('chart_tm_subtitle') + ':' : ''),
                            align: 'center',
                            verticalAlign: 'middle',
                            y: -25
                        },
                        series: [{
                            type: 'pie',
                            /*name: 'Share in ' + action.tradeMapChartData.CountryLabel + ' exports',
                            innerSize: '50%',
                            data: action.tradeMapChartData.bilateral.map(function(data){
                  
                                return [data.partnerLabel, data.share];
                  
                                })*/

                            name: 'Share in ' + action.tradeMapChartData.countryLabel + ' exports',
                            innerSize: '50%',
                            data: (action.tradeMapChartData.bilateral ? action.tradeMapChartData.bilateral.map(function (data) {
                                return [data.partnerLabel, data.share];
                            }) : [])


                            /*data: [
                            ['Germany', 14.8],
                            ['Spain', 7.7],
                            ['Italy', 7.6],
                            ['United States of America', 7.3],
                            ['Belgium', 7],
                            ['Untied Kingdom', 6.7],
                            ['China', 4.1],
                            ['Netherlands', 3.7],
                            ['Switzerland', 3.4],
                            ['Rest of the wrold', 37.7]
                    ]*/
                        }]
                    }
                }

            }
        case 'GET_EPMCHARTDATA':
            let obj = null;

            //EMBEDDED ENVIRONMENT
            if (window.location.pathname.match("/embedded/")) {

                obj = [...action.epmChartData.map(function (data) {
                    return {
                        parent: data.item.pCode,
                        name: data.item.name,
                        value: data.value,
                        itemCode: data.itemCode,
                        exportValue: data.exportValue,
                        realizedPotential: data.realizedPotential
                    }
                })];

                obj = obj.sort(function (a, b) {
                    return b.value - a.value
                });

                obj = obj.slice(0, 50);

                obj = [{
                    type: "treemap",
                    height: "500px",
                    layoutAlgorithm: 'sliceAndDice',
                    alternateStartingDirection: true,
                    levels: [{
                        level: 1,
                        layoutAlgorithm: 'sliceAndDice',
                        dataLabels: {
                            enabled: true,
                            align: 'left',
                            verticalAlign: 'top',
                            style: {
                                fontSize: '15px',
                            }
                        }
                    }],

                    data: [...treemapGroups, ...obj],

                }];

                var colors = ["#002b54", "#971779", "#e03375", "#009de8", "#a1cdf3", "#fdd69b", "#fa9805", "#ef99ba", "#d6dde4", "#c8c8c8", "#7b7b7b", "#7dd5f0", "#798ea4", "#e1a851", "#d88b16", "#fa7b05", "#dc6d06", "#d95784", "#a3174d", "#9eaebe", "#8095a9"];
                var data = obj[0].data;

                for (let i = 0; i < data.length; i++) {
                    const d = data[i];
                    d.color = colors[i];
                }
            }
            //MAT PORTAL REGULAR ENVIRONMENT
            else {
                obj = [...action.epmChartData.map(function (data) {
                    return {
                        parent: data.item.pCode,
                        name: data.item.name,
                        value: data.value,
                        itemCode: data.itemCode,
                        exportValue: data.exportValue,
                        realizedPotential: data.realizedPotential
                    }
                })];

                obj = obj.sort(function (a, b) {
                    return b.value - a.value
                });

                obj = obj.slice(0, 50);

                obj = [{
                    type: "treemap",
                    height: "500px",
                    layoutAlgorithm: 'sliceAndDice',
                    alternateStartingDirection: true,
                    levels: [{
                        level: 1,
                        layoutAlgorithm: 'sliceAndDice',
                        dataLabels: {
                            enabled: true,
                            align: 'left',
                            verticalAlign: 'top',
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold'
                            }
                        }
                    }],
                    data: [...treemapGroups, ...obj]
                }];
            }
            
      //console.log('objredux',obj);
      return {
        ...state,
        epmChartData: obj

          }
      case 'GET_TRADEMAPEXPORTS':
          return {
              ...state,
              tradeMapExportsData: {
                  data: JSON.parse(action.tradeMapExportsData)
              }
          }
      case 'GET_TRADEMAPPARTNERS':
          return {
              ...state,
              tradeMapPartnersData: {
                  data: action.tradeMapPartnersData
              }
          }
      case 'GET_TRADEMAPPRODUCTEXPORTERS':
          console.log(action.tradeMapProductExportersData);
          return {
              ...state, 
              tradeMapProductExportersData: {
                  data: action.tradeMapProductExportersData
              }
          }
      case 'GET_TRADEMAPPRODUCTSEXPORTED':
          console.log(action.tradeMapProductsExportedData)
          return {
              ...state,
              tradeMapProductsExportedData: {
                  data: action.tradeMapProductsExportedData
              }
          }

    default:
      return state
  }
}

export default chartData