import React, { Component } from 'react'
import itc from "../img/sponsors/itc.png";
import eu from "../img/sponsors/eu.png";
import { t } from '../services/i18n'


class Sponsors extends Component {
    render() {
        if (window.location.pathname.match("/embedded/")) {
            return null;
        } else {
            return (
                <div className="sponsors">
                    <div className="container-fluid outer-cont">
                        <h2 className="col-12 section-title text-center mt-5">{t('lbl_sponsors')}</h2>
                        <hr />
                        <ul>
                            <li>
                                <a href="http://www.intracen.org/" target="_blank" rel="noopener noreferrer">
                                    <p className="note">{t('lbl_sponsors_develop')}<br />{t('lbl_sponsors_ITC')}</p>
                                    <img src={itc} alt="ITC" width={133} height={62} />
                                </a>
                            </li>
                            <li>
                                <a href="https://europa.eu/" target="_blank" rel="noopener noreferrer">
                                    <p className="note">{t('lbl_sponsors_fund')}<br /> {t('lbl_sponsors_EU')}</p>
                                    <img src={eu} alt="EU" width="93" height="62" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        }
    }
}

export default (Sponsors)