import React, { Component }  from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { fetchCountries, userActions } from '../actions'
import { t } from '../services/i18n'


//export default ({ countries, selectedCountry, handleChangeCountry }) => (


class CountryCombo extends Component {
    constructor(props){
        super(props);
        

        //console.log(props);
    }
    state = {        
        selectedCountry: null
    }
    
    componentDidMount() {
        //this.props.dispatch(userActions.getAll());
        //this.props.getAllUsers();
        if(this.props.showAllCountries){
            this.props.countries.splice(0,0,{ "value": "ALL","label":  "All countries" ,"code": "ALL"});
        }
        //this.props.fetchCountries();
       
        
    }
    
    
  
    render () {
        //const { selectedCountry  } = this.state;
        return (
        // <Select 
        //     noOptionsMessage="Select a country"
        // 	isMulti={this.props.isMulti}
        //     isSearchable={this.props.isSearchable}
        //     value={this.props.selectedCountry}
        //     onChange={this.props.handleChangeCountry}
        //      options={this.props.countries}
        // />  
        null
        )
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users,
        countries: state.countries.countries
    };
}

const mapDispatchToProps = dispatch => ({
    fetchCountries: () => dispatch(fetchCountries()),
    ...userActions
  })

export default connect(mapStateToProps, mapDispatchToProps)(CountryCombo)
          


