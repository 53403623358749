import { countriesConstants } from '../constants';

//const { locale }  = getState().l10n;

export const fetchCountries = () => (dispatch, getState) => {
    const { locale }  = getState().l10n;
    //console.log()

    return fetch('https://www.macmap.org/api/country-list?lang=' + locale)
    //return fetch('http://macmap-dev.itc-cci.net/api/country-list?lang=' + locale)
            .then(response => response.json())
            .then(json => dispatch(getAllCountries(json)))
          .catch(err => console.error(err))
        
}

export const getAllCountries = countries => ({
    type: countriesConstants.GETALL_COUNTRIES,
    countries,
})

export const fetchCountriesAll = () => (dispatch, getState) => {
    const { locale }  = getState().l10n

    return fetch('https://www.macmap.org/api/country-list?lang=' + locale)
    //return fetch('http://macmap-dev.itc-cci.net/api/country-list?lang=' + locale)
            .then(response => response.json())
            .then(json => dispatch(getAllCountriesAll(json)))
          .catch(err => console.error(err))
        
}

export const getAllCountriesAll = countries => ({
    type: countriesConstants.GETALL_COUNTRIES_ALL,
    countries,
})

/* fetch region */
export const fetchRegions = (regionCd) => (dispatch) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI2IiwibmJmIjoxNjI3MDIzMzIzLCJleHAiOjIwMjc2MjgxMjMsImlhdCI6MTYyNzAyMzMyMywiaXNzIjoiaHR0cHM6Ly9hcGkuaW50cmFjZW4ub3JnLyIsImF1ZCI6Imh0dHA6Ly9teWF1ZGllbmNlLmNvbSJ9.bMxTctI8W3Wd7qvmMXT2Ky5aTD3QK2vKCmdhBVXn8ek";
    return fetch('https://api.intracen.org/b2b/v1/rest/country_groups', {
        methode: "GET",
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": `application/json`}
    })
        .then(response => response.json())
        .then(json => dispatch(getAllRegions(json)))
        .catch(err => console.error(err))

}

export const getAllRegions = regions => ({
    type: countriesConstants.GETALL_REGIONS,
    regions,
})

