import React from 'react'
import { t } from '../services/i18n'

export default () => (
<section id="links-section">
  <div className="container">
    <div className="row no-gutters justify-content-md-center text-center mt-4">
         { t('section_join_us_desc')} 
 
      </div>
    </div>
</section>

)