export const dataAvailabilityConstants = {
    GET_TARIFF: 'GET_TARIFF',
    GET_NTM: 'GET_NTM',
    GET_REMEDIES: 'GET_REMEDIES',
    GET_STATISTICS: 'GET_STATISTICS',
    GET_STATISTICS_YEAR: 'GET_STATISTICS_YEAR',
    GET_FILTEREDTARIFF:'GET_FILTEREDTARIFF',
    GET_FILTEREDNTM:'GET_FILTEREDNTM',
    GET_FILTEREDREMEDIES:'GET_FILTEREDREMEDIES',
    GET_FILTEREDSTATISTICS:'GET_FILTEREDSTATISTICS',
    GET_FILTEREDSTATISTICS_YEAR: 'GET_FILTEREDSTATISTICS_YEAR',
    GET_PROCUREMENTMAPAVAILABILITY: 'GET_PROCUREMENTMAPAVAILABILITY',
    GET_COUNTRYKEYDATA: 'GET_COUNTRYKEYDATA',
    GET_EPM_YEAR: 'GET_EPM_YEAR'
};