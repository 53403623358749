import { connect } from 'react-redux'
import React, { Component } from 'react'

import { Link } from 'react-router-dom';


import {  userActions } from '../actions'
import LocalizedLink from './LocalizedLink'
import { bindActionCreators } from 'redux';




class PrivatePage extends Component {
    
    componentDidMount() {
        //this.props.dispatch(userActions.getAll());
        this.props.getAllUsers();
      
    }

    render() {
        const { user, users } = this.props;
        return (
            <div className="col-md-6 col-md-offset-3">
                <h1>Hi {user.firstName}!</h1>
                <p>You're logged in with React & JWT!!</p>
                <h3>Merchants from secure api end point:</h3>
                {users.loading && <em>Loading users...</em>}
                {users.error && <span className="text-danger">ERROR: {users.error}</span>}
                {users.items &&
                    <ul>
                        {users.items.map((user, index) =>
                            <li key={user.id}>
                                {user.firstName + ' ' + user.lastName}
                            </li>
                        )}
                    </ul>
                }
               


                <ul>
<li>lol</li>

</ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

/*const mapDispatchToProps = (dispatch) => {
    return{
        fetchMerchants: bindActionCreators(userActions,dispatch)
    }
}

const mapDispatchToProps = {
    ...fetchMerchants,
    ...userActions
  };*/

  const mapDispatchToProps = dispatch => ({
    
    ...userActions
  })

export default connect(mapStateToProps, mapDispatchToProps)(PrivatePage)