const CryptoJS = require("crypto-js");

export default (data, key) => {

  const cipher = (str, num) => {
    let result = '';
    let charCode = 0;

    for (let i = 0; i < str.length; i++) {
      charCode = (str[i].charCodeAt()) + num;
      result += String.fromCharCode(charCode);
    }
    return result;
  }

  let decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(data)
    },
    CryptoJS.enc.Utf8.parse(cipher(key, 9)),
    {
      iv: CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  ).toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted)
}